import classes from "./EditText.module.css";
import { useState, useEffect, useContext } from "react";
import {  useActiveFabricObject, useFabricCanvas, useFabricEditor } from "react-fabric-page";
import {  ActiveTextObject, addTextboxObject } from "../../../../objectUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAlignCenter, faAlignLeft, faAlignRight, faAngleDown, faAngleUp, faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import useScreenSize from "../../../../Hooks/UseScreenSize";
import EditTextSmall from "./EditTextSmall/EditTextSmall";
import ToolTip from "../../../../Components/ToolTip/ToolTip";
import { CustomizationContext } from "../../Customization";
import ColorSelector from "../../Components/ColorSelector/ColorSelector";
import { useSelector } from "react-redux";
import PrimaryInput from "../../../../Components/Inputs/PrimaryInput/PrimaryInput";
import PrimaryButton from "../../../../Components/Buttons/PrimaryButton/PrimaryButton";

const dropdownIcon = "/Assets/Icons/caret-down-solid.svg"


const EditText = ({ onClick, toggleDropdown, dropdownOpen, currentDropdown }) => {
  const {frontCoverTextColor,setFrontCoverTextColor,frontCoverFontFamily,setFrontCoverFontFamily,fontData,frontCoverFontStyle,setFrontCoverFontStyle,allTextColors,selectedDesigns,frontCoverTextAlign,setFrontCoverTextAlign,frontCoverFontSize,setFrontCoverFontSize, frontCoverText,setFrontCoverText} = useContext(CustomizationContext)
  
  const [activeObject] = useActiveFabricObject(ActiveTextObject);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const [colors,setColors] = useState([])

  const {isMediumScreen} = useScreenSize()

  const customizationPricing = useSelector((state) => state.customizationPricing.prices?.[0]);

  const [tempFontSize, setTempFontSize] = useState(frontCoverFontSize);

  const canvas = useFabricCanvas();
  const editor = useFabricEditor();

  useEffect(() => {
    setColors(allTextColors);
  }, [allTextColors]);


  const handleColorChange = (color) => {
    setFrontCoverTextColor(color);
  };

  const handleClickAndSelect = () => {
    onClick();
    if (dropdownOpen) {
      toggleDropdown(false)
    }
    else {
      toggleDropdown(true)
    }
  };

  const handleFontChange = (font) => {
    setFrontCoverFontFamily(font);
    setIsDropdownOpen(false);
  };

  const handleAddTextBox = () => {
    addTextboxObject(canvas);
  };

  const handleTextStyleChange = (style) => {
    setFrontCoverFontStyle((prevStyle) => {
      const newStyle = { ...prevStyle };
  
      switch (style) {
        case 'italic':
          newStyle.fontStyle = newStyle.fontStyle === 'italic' ? 'normal' : 'italic';
          break;
        case 'underline':
          newStyle.underline = !newStyle.underline;
          break;
        default:
          break;
      }
  
      return newStyle;
    });
  };

  useEffect(() => {
    if(selectedDesigns?.customization?.frontCoverTexts?.[0]?.color) {
      const selectedColor = colors?.find((color) => color?.id === selectedDesigns?.customization?.frontCoverTexts?.[0]?.color);
      if(selectedColor) {
        setFrontCoverTextColor(selectedColor)
      }
    }

    if(selectedDesigns?.customization?.frontCoverTexts?.[0]?.textStyle?.isItalics) {
      setFrontCoverFontStyle((prevStyle) => {
        const newStyle = { ...prevStyle };
        newStyle.fontStyle = 'italic';
        return newStyle;
      });
    }

    if(selectedDesigns?.customization?.frontCoverTexts?.[0]?.textStyle?.isUnderline) {
      setFrontCoverFontStyle((prevStyle) => {
        const newStyle = { ...prevStyle };
        newStyle.underline = true;
        return newStyle;
      });
    }
  },[selectedDesigns,allTextColors])

  const handleDecrease = () => {
    setTempFontSize(prevFontSize => Math.max(6, prevFontSize - 1));
    setFrontCoverFontSize(prevFontSize => Math.max(6, prevFontSize - 1));
  };
  
  const handleIncrease = () => {
    setTempFontSize(prevFontSize => Math.min(26, prevFontSize + 1));
    setFrontCoverFontSize(prevFontSize => Math.min(26, prevFontSize + 1));
  };
  
  const handleFontSizeChange = (e) => {
    const value = Number(e.target.value);
    if(value <= 26){
      setTempFontSize(value);
    }  else if(value >= 6 ){
      setTempFontSize(26)
    }
    // handleInputDebouncedChange(maxValue, false)
  };

  const handleClick = (e) => {
    if(e.key === "Enter"){
      if(tempFontSize >= 6 && tempFontSize <= 26) {
          setFrontCoverFontSize(tempFontSize)
      } else {
          setTempFontSize(6);
          setFrontCoverFontSize(6)
      }
   }
  }

  const deleteText = () => {
    if(activeObject?._activeObject?.type === "textArea") {
      const selectedTextBox = frontCoverText?.find((text) => activeObject?._activeObject?.text === text?.text);
      
      if (selectedTextBox) {
        const updatedFrontCoverText = frontCoverText.filter(
          (text) => text?.text !== selectedTextBox?.text
        );
    
        setFrontCoverText(updatedFrontCoverText);
      }
  
      editor?.deleteObjects([activeObject?._activeObject])

    }
  }
  

  return (
    <div>
      {isMediumScreen ? (
        <EditTextSmall colors={colors} fontData={fontData} />
      ) : (
        <div
          className={classes.ColorOption}
          onClick={() => handleClickAndSelect()}
        >
          <div className={classes.colorHeader}>
            <div className={classes.headerText}>
              <h2>Text Boxes</h2>
              <ToolTip
                tooltipText={"Click and select the text you want to edit"}
              />
            </div>
            <div className={classes.dropDownImage} onClick={toggleDropdown}>
              {dropdownOpen && currentDropdown === "Text Boxes" ? (
                <FontAwesomeIcon icon={faAngleUp} />
              ) : (
                <FontAwesomeIcon icon={faAngleDown} />
              )}
            </div>
          </div>

          {dropdownOpen && currentDropdown === "Text Boxes" ? (
            <div
              className={classes.colorPickerDropdown}
              onClick={(e) => e.stopPropagation()}
            >
              <h3>Select font for front cover</h3>

              <div className={classes.selectColor}>
                <h2>Font family: {frontCoverFontFamily?.fontFamily}</h2>
                <div className={classes.customDropdown}>
                  <div
                    className={classes.selectedOption}
                    onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                  >
                    <div className={classes.dropdownBar}>
                      <div className={classes.dropdownText}>
                        <span>{frontCoverFontFamily?.fontFamily}</span>
                        <span>
                          {frontCoverFontFamily?.fontPrice > 0 &&
                            `+$${frontCoverFontFamily?.fontPrice}`}
                        </span>
                      </div>
                      <div className={classes.dropdownIcon}>
                        <img src={dropdownIcon} alt="" />
                      </div>
                    </div>
                  </div>
                  {isDropdownOpen && (
                    <div className={classes.dropdownOptions}>
                      {fontData.map((font) => (
                        <div
                          key={font.fontFamily}
                          className={classes.option}
                          onClick={() => handleFontChange(font)}
                        >
                          <span>{font?.fontFamily}</span>
                          <span>
                            {font?.fontPrice > 0 && `+$${font?.fontPrice}`}
                          </span>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>

              <div className={classes.textStylingOptions}>
                <h3>Select Text Styling</h3>
                <div className={classes.fontStyleDiv}>
                  <div className={classes.stylingButtons}>
                    <button
                      className={`${classes.stylingButton} ${
                        frontCoverFontStyle?.fontStyle === "italic"
                          ? classes.activeStylingButton
                          : ""
                      }`}
                      onClick={() => handleTextStyleChange("italic")}
                    >
                      <i>I</i>
                    </button>
                    <button
                      className={`${classes.stylingButton} ${
                        frontCoverFontStyle?.underline === true
                          ? classes.activeStylingButton
                          : ""
                      }`}
                      onClick={() => handleTextStyleChange("underline")}
                    >
                      <u>U</u>
                    </button>
                  </div>

                  <div className={classes.fontSizeOptions}>
                    <FontAwesomeIcon icon={faMinus} style={{cursor:"pointer"}} onClick={handleDecrease}/>
                    <input type="number" max={26} min={6} name="" id="" value={tempFontSize} onKeyDown={handleClick} onChange={handleFontSizeChange} />
                    <FontAwesomeIcon icon={faPlus} style={{cursor:"pointer"}} onClick={handleIncrease}/>
                  </div>
                </div>
              </div>

              <div className={classes.textStylingOptions}>
                <h3>Text Align</h3>
                <div className={classes.textOptions}>
                  <div className={classes.stylingButtons}>
                    <button
                      className={`${classes.stylingButton} ${
                        frontCoverTextAlign === "left"
                          ? classes.activeStylingButton
                          : ""
                      }`}
                      onClick={() => setFrontCoverTextAlign("left")}
                    >
                      <FontAwesomeIcon icon={faAlignLeft} />
                    </button>
                    <button
                      className={`${classes.stylingButton} ${
                        frontCoverTextAlign === "center"
                          ? classes.activeStylingButton
                          : ""
                      }`}
                      onClick={() => setFrontCoverTextAlign("center")}
                    >
                      <FontAwesomeIcon icon={faAlignCenter} />
                    </button>
                    <button
                      className={`${classes.stylingButton} ${
                        frontCoverTextAlign === "right"
                          ? classes.activeStylingButton
                          : ""
                      }`}
                      onClick={() => setFrontCoverTextAlign("right")}
                    >
                      <FontAwesomeIcon icon={faAlignRight} />
                    </button>
                  </div>

                  <PrimaryButton disabled={activeObject?._activeObject?._objects?.length > 1 || !activeObject?._activeObject} onClick={deleteText}>Delete Text</PrimaryButton>

                </div>
              </div>

              <div className={classes.selectColor}>
                <h2>Color: {frontCoverTextColor?.colorName}</h2>
                <ColorSelector
                  availableColors={colors}
                  selectedColor={frontCoverTextColor}
                  handleColorChange={handleColorChange}
                />
              </div>

              <div className={classes.addTextBox} onClick={handleAddTextBox}>
                <div className={classes.addText}>
                  <h3>Add Textbox</h3>
                </div>
                <p>+${customizationPricing?.textBoxPriceFront}</p>
              </div>
            </div>
          ) : (
            <p>{frontCoverTextColor?.colorName}</p>
          )}
        </div>
      )}
    </div>
  );
};

export default EditText;
