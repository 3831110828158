import classes from "./AboutForAuthors.module.css"

const image1 = "/Assets/Images/FeatureIcons/authorF1.png"
const image2 = "/Assets/Images/FeatureIcons/authorF2.png"
const image3 = "/Assets/Images/FeatureIcons/authorF3.png"
const image4 = "/Assets/Images/FeatureIcons/authorF4.png"
const image5 = "/Assets/Images/FeatureIcons/authorF5.png"
const image6 = "/Assets/Images/FeatureIcons/authorF6.png"
const image7 = "/Assets/Images/FeatureIcons/authorF7.png"
const image8 = "/Assets/Images/FeatureIcons/authorF8.png"

const cardData = [
    {
        title:"Preserve Your Legacy",
        desc:" A leather-bound book is not just a literary work—it’s a lasting legacy. Ensure your book endures for generations to come with a beautifully crafted leather-bound edition, designed to withstand the test of time and significantly extend the shelf life of your book.",
        image:image2
    },
    {
        title:"Higher Royalties",
        desc:"Benefit from higher royalties based on the deal and demand for premium editions. Leather-bound books command a higher price point, translating to increased earnings for you as the author.",
        image:image3
    },
    {
        title:"Expanded Market Reach",
        desc:" To increase awareness and reach a wider audience, list your leather-bound edition on multiple marketplaces. Make a splash in the overcrowded market with a high-quality, distinctive product.",
        image:image4
    },
    {
        title:"Customized Edition",
        desc:" A customized edition of your book allows readers to add a personal touch, whether through a dedicated message, unique cover design, or special inscriptions. \n\n This level of personalization creates a deeper connection with your audience, making each edition a cherished keepsake.",
        image:image5
    },
    {
        title:"Exclusive Collectors Edition",
        desc:" Create special editions of your work that are only available to collectors and die-hard readers. \n \n These special editions can include signed copies, beautifully crafted custom covers, and unique design elements that enhance their value and appeal.",
        image:image6
    },
    {
        title:"Marketing Support",
        desc:"Rare Biblio’s global distribution channels ensure that the author's leather-bound editions can reach readers worldwide, expanding the author’s international presence.",
        image:image7
    },
]

const AboutForPublishers = () => {
    return(
        <div className={classes.mainContainer}>
            <div className={classes.headerSection}>
                <h2>
                Why Partner with Us?
                </h2>
                {/* <p>Our mission is to encapsulate your academic journey in the timeless beauty of leather, ensuring that your thesis remains a testament to your dedication and intellect.</p> */}
            </div>

            <div className={classes.cardSection}>
                {cardData.map((card,index) => (
                    <div key={index} className={classes.card}>
                        <div className={classes.image}>
                            <img src={card.image} alt="" />
                        </div>
                        <div className={classes.title}>
                            <h2>{card.title}</h2>
                            <p>{card.desc?.split("\n").map((line,index) => (
                                <span key={index}>
                                    {line}
                                    <br/>
                                </span>
                            ))}</p>
                        </div>
                    </div>
                ))}
            </div>


        </div>
    )
}

export default AboutForPublishers