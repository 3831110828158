import { useSelector } from "react-redux";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-regular-svg-icons";

import { urls } from "../../../Routes/Urls";
import { getFormattedDateWithFullMonth, getFormattedTimeLowerCase, getRandomImageForBestSellerBook, getRandomImageForNormalBook } from "../../../Util";
import BreadCrumb from "../../../Components/BreadCrumb/BreadCrumb";
import PrimaryNav from "../../Header/PrimaryNav/PrimaryNav";
import classes from "./ThankYou.module.css";
import ButtonLight from "../../../Components/Buttons/ButtonLight/ButtonLight";
import PrimaryButton from "../../../Components/Buttons/PrimaryButton/PrimaryButton";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const ThankYou = () => {
  const history = useHistory()
  const allColorsData = useSelector((state) => state.bookColor.colors);
  const orderData = window.history.state
    ? JSON.parse(window.history.state)?.data
    : {};

  const crumbs = [
    { title: "Home", path: "/" },
    { title: "My Cart", path: urls.CART },
  ];

  const getLeatherType = (type) => {
    if(type === "realLeather") {
      return "Genuine Leather"
    } else {
      return "Vegan Leather"
    }
  }

  return (
    <div>
      <PrimaryNav />
      <div className={classes.mainContainer}>
        <BreadCrumb className={classes.crumbs} crumbs={crumbs} />

        {orderData.paymentStatus === "success" ? (
          <>
            <div className={classes.headerSection}>
              <div className={classes.header}>
                <FontAwesomeIcon
                  className={classes.tickIcon}
                  icon={faCircleCheck}
                />
                <h2>Thank You!</h2>
              </div>
              <p>Your order was placed successfully</p>
            </div>
            <div
              className={classes.mainSection}
              style={{ marginTop: "1.5rem", display: "flex", gap: "2.5rem",flexDirection:"column" }}
            >
              <div className={classes.orderDetails} style={{ flex: 1 }}>
                <div className={classes.order}>
                  <p>Your Order Number : {orderData.orderNo}</p>
                  <p>
                    Order Date :{" "}
                    {getFormattedDateWithFullMonth(orderData.createdAt)} at{" "}
                    {getFormattedTimeLowerCase(orderData.createdAt)}
                  </p>
                  <div className={classes.btnContainer}>
                    <PrimaryButton onClick={() => history.push('/my-account/my-orders')}>Track Your Order</PrimaryButton>
                    <ButtonLight onClick={() => history.push(urls.LISTING)}>Continue Shopping</ButtonLight>
                  </div>
                </div>

                <div
                  className={classes.shippingDetails}
                  style={{
                    padding: "1rem 0",
                  }}
                >
                  <div className={classes.address}>
                    <div>
                      <h2>Billing Address</h2>
                      <div className={classes.billingAddress}>
                        <h4>{orderData?.billingAddress?.fullName}</h4>
                        <p>Phone No:{orderData?.billingAddress?.phoneNo}</p>
                        <p>{`${orderData?.billingAddress?.addressLine1}, ${orderData?.billingAddress?.city}, ${orderData?.billingAddress?.state}, ${orderData?.billingAddress?.country}, ${orderData?.billingAddress?.zipCode}`}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={classes.sidebar}>
                <h2>Order Summary</h2>

                <div className={classes.orderSummary}>
                  {orderData?.cartItems?.map((data, idx) => (
                    <div className={classes.orderItem} key={idx}>
                      <div className={classes.product}>
                        <div className={classes.productImage}>
                          <img src={data?.book ? data?.customization?.bookThumbnail ? data?.customization?.bookThumbnail : getRandomImageForNormalBook(data.book, allColorsData, data.color, data.leatherType, data.variantType)?.image : getRandomImageForBestSellerBook(data.bestSellerBook, allColorsData, data.color, data.leatherType, data.variantType)?.image} alt="" />
                        </div>
                        <div className={classes.productDetails}>
                          <h3>{data?.book?.title || data?.bestSellerBook?.title}</h3>
                          <p>Color : {data?.color}</p>
                          <p>Quantity : {data?.quantity}</p>
                          <p>{getLeatherType(data?.leatherType)} | {data?.variantType}</p>
                          <p>Shipping Address: {`${data?.shippingAddress?.addressLine1}, ${data?.shippingAddress?.city}, ${data?.shippingAddress?.state}, ${data?.shippingAddress?.country}, ${data?.shippingAddress?.zipCode}`}</p>
                        </div>
                      </div>
                      {/* <span>${data.totalAmount.toFixed(2)}</span> */}
                      <div className={classes.otherDetails}>
                        <div className={classes.detail}>
                          <h3>Book Price</h3>
                          <p>${data?.itemPrice?.toFixed(2)}</p>
                        </div>
                        <div className={classes.detail}>
                          <h3>Customization Price</h3>
                          <p>${data?.customizationCharges?.toFixed(2)}</p>
                        </div>
                       <div className={classes.detail}>
                       <h3>Shipping Method</h3>
                       <p>
                         {`${data?.shippingSpeed} - `}
                         {data?.shippingCharges?.find((item) => item?.shippingSpeed === data?.shippingSpeed)?.totalAmount === 0
                           ? "free"
                           : `$${data?.shippingCharges?.find((item) => item?.shippingSpeed === data?.shippingSpeed)?.totalAmount}`}
                       </p>
                     </div>
                     
                        <div className={classes.detail}>
                          <h3>Total Price</h3>
                          <p>${(data?.totalAmount)?.toFixed(2)}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <div className={classes.paymentSummary}>
                  <div className={classes.total}>
                      <h3>Order Total</h3>
                      <p>${orderData?.orderTotal?.toFixed(2)}</p>
                  </div>
                  <div className={classes.total}>
                      <h3>Discount</h3>
                      <p>${orderData?.discountAmount?.toFixed(2)}</p>
                  </div>
                  <div className={classes.total}>
                      <h3>Amount Paid</h3>
                      <p>${orderData?.payableAmount.toFixed(2)}</p>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className={classes.headerSection}>
            <div className={classes.header}>
              <FontAwesomeIcon
                className={classes.crossIcon}
                icon={faCircleXmark}
              />
              <h2>Failed!</h2>
            </div>
            <p>Sorry, payment for your order failed.</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default ThankYou;
