import classes from "./DedicatedPage.module.css";

import Accordion from "../../Components/Accordion/Accordion";

import { useState, useContext, useEffect } from "react";
import AddText from "./AddText/AddText";
import AddImage from "./AddImage/AddImage";
import useScreenSize from "../../../../Hooks/UseScreenSize";
import OptionCapsules from "../../../../Components/OptionCapsules/OptionCapsules";
import { CustomizationContext } from "../../Customization";
import { useSelector } from "react-redux";
import { ActiveTextboxObject, addTextboxObject } from "../../../../objectUtils";
import { useActiveFabricObject, useFabricCanvas } from "react-fabric-page";
import InputModal from "../../Components/InputModal/InputModal";
// import { useFabricCanvas } from "react-fabric-page";

const dedicationPageTabs = [
  {
    name: "Yes",
    value: true,
  },
  {
    name: "No",
    value: false,
  },
];

const DedicatedPage = ({
  onClick,
  toggleDropdown,
  dropdownOpen,
  currentDropdown,
}) => {
  const { wantDedicationPage, setWantDedicationPage } =
    useContext(CustomizationContext);
    const customizationPricing = useSelector((state) => state.customizationPricing.prices?.[0]);
  const [addOption, setAddOption] = useState("text");
  const { isMediumScreen } = useScreenSize();
  // const [isCanvasReady,setIsCanvasReady] = useState(false);

  const canvas = useFabricCanvas()

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalText, setModalText] = useState("");
  const [selectedTextbox, setSelectedTextbox] = useState(null);

  const [activeObject] = useActiveFabricObject(ActiveTextboxObject);

      const handleAddTextBox = () => {
        addTextboxObject(canvas);
      };

      useEffect(() => {
        const handleObjectSelected = (event) => {
          if(isMediumScreen) {
            const activeObject = canvas.getActiveObject();
      
            if (activeObject?.type === "textArea") {
         
              if (activeObject === selectedTextbox) {
                setModalText(activeObject.text || "");
                setIsModalOpen(true);
                activeObject.exitEditing();
              } else {
                setSelectedTextbox(activeObject);
              }
            }
          }
        };
    
        canvas?.on("mouse:down", handleObjectSelected);
    
        return () => {
          canvas?.off("mouse:down", handleObjectSelected);
        };
      }, [canvas, selectedTextbox,isMediumScreen]);
    
      
      const handleInputCancel = () => {
        setIsModalOpen(false);          // Close the modal
        setSelectedTextbox(null);       // Deselect the selected textbox
        canvas.discardActiveObject();   // Deselect the object on the canvas
        canvas.renderAll();             // Re-render the canvas to reflect the deselection
    };
    
        const handleModalSubmit = () => {
          if (selectedTextbox) {
            selectedTextbox.text = modalText; // Update the textbox text
            canvas.renderAll(); // Re-render the canvas
          }
          setIsModalOpen(false); // Close the modal
        };
    
        useEffect(() => {
          if(!activeObject?._activeObject) {
            setSelectedTextbox(null)
          }
        },[activeObject])
        

  return (
    <div>
         {isModalOpen && isMediumScreen && (<InputModal
          title="Dedication Page"
          label="Edit Dedication Page Text"
          value={modalText}
          onChange={(e) => setModalText(e.target.value)}
          onCancel={handleInputCancel}
          onSubmit={handleModalSubmit}
        />)}
      {isMediumScreen ? (
        <>
         <div className={classes.dedicationPageOptions}>
         {dedicationPageTabs.map((option) => (
           <OptionCapsules
             option={option.name}
             active={wantDedicationPage === option.value}
             onClick={() => setWantDedicationPage(option.value)}
             key={option.name}
           />
         ))}
       </div>

       {wantDedicationPage === true ? (
        <div
          className={classes.selectColor}
          onClick={(e) => e.stopPropagation()}
        >
          <h2>Select Endpaper for your book</h2>

          <div className={classes.tabSection}>
            <div
              className={addOption === "text" ? classes.tabActive : classes.tab}
              onClick={() => {setAddOption("text"); handleAddTextBox()}}
            >
              <h2>Add Text</h2>
            </div>
            <div
              className={
                addOption === "image" ? classes.tabActive : classes.tab
              }
              onClick={() => setAddOption("image")}
            >
              <h2>Add Image</h2>
            </div>
          </div>

          {addOption === "text" ? <AddText /> : <AddImage />}
        </div>

       ) : (
        <div className={classes.noPaper}>
        <p>No Dedication Page Added</p>
      </div>
       )}
        </>
      ) : (
        <div className={classes.mainContainer}>
          <Accordion
            dropdownName={"Dedication Page"}
            onClick={onClick}
            toggleDropdown={toggleDropdown}
            dropdownOpen={dropdownOpen}
            currentDropdown={currentDropdown}
            toolText={"Add Custom text/image to endpaper"}
            title={"Dedication Page"}
          />

          {dropdownOpen && currentDropdown === "Dedication Page" && (
            <div
              className={classes.selectColor}
              onClick={(e) => e.stopPropagation()}
            >
              <div className={classes.dedicationPageOptions}>
                {dedicationPageTabs.map((option) => (
                  <OptionCapsules
                    option={option.name}
                    active={wantDedicationPage === option.value}
                    onClick={() => setWantDedicationPage(option.value)}
                    key={option.name}
                  />
                ))}
              </div>

              {wantDedicationPage === true ? (
                <div>
                  <div className={classes.price}>
                    <p>Any customization will cost additional</p>
                    <h2>+${customizationPricing?.dedicationPagePrice}</h2>
                  </div>

                  <div className={classes.tabSection}>
                    <div
                      className={
                        addOption === "text" ? classes.tabActive : classes.tab
                      }
                      onClick={() => {setAddOption("text");handleAddTextBox();}}
                    >
                      <h2>Add Text</h2>
                    </div>
                    <div
                      className={
                        addOption === "image" ? classes.tabActive : classes.tab
                      }
                      onClick={() => setAddOption("image")}
                    >
                      <h2>Add Image</h2>
                    </div>
                  </div>

                  {addOption === "text" ? <AddText /> : <AddImage />}
                </div>
              ) : (
                <div className={classes.noPaper}>
                  <p>No Dedication Page Added</p>
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default DedicatedPage;
