import React from 'react';
import classes from './RestorationSteps.module.css';

import Timeline from '../../Components/Timeline/Timeline';

const step1 = "/Assets/Images/Restoration/Examination-Step-1-Restoration.png"
const step2 = "/Assets/Images/Restoration/Maintaining-The-Originality-Restoration.png"
const step3 = "/Assets/Images/Restoration/Refurbishing-Restoration.png"
const step4 = "/Assets/Images/Restoration/Embellishments-Restoration.png"
const step5 = "/Assets/Images/Restoration/Finishing-Touches-Restoration.png"

const RestorationSteps = () => {
  const steps = [
    {
      number: '01',
      title: 'Examination',
      description:
        'Our book restoration team will examine your worn-out book thoroughly and identify everything that requires attention. ',
      image: step1, 
      align: 'left',
    },
    {
      number: '02',
      title: 'Maintaining The Originality',
      description:
        "Our team makes sure that the authenticity of your antique book is preserved by safeguarding its historic pages ensuring their essence is not hindered.",
      image: step2, 
      align: 'right',
    },
    {
      number: '03',
      title: 'Refurbishing',
      description:
        "We’ve got vibrant leather color options for you to choose from for your antique book and make it lively again. With the finest precision and perfection, your book gets binded and refurbished. While making sure the legitimacy of your book is unaffected.",
      image: step3, 
      align: 'left',
    },
    {
      number: '04',
      title: 'Embellishments',
      description:
        'Of course, you want your book to stand out even more, isn’t it? For that, you add exquisite features such as gold leaf stamping, embossing, and tooling to create a one-of-a-kind work of art.',
      image: step4, 
      align: 'right',
    },
    {
      number: '05',
      title: 'Finishing Touches:',
      description:
        "Last but not least comes the finish touches, where our craftsmen make sure that no string is left untouched and display our commitment to quality, from smoothing the edges to improving the cover.",
      image: step5, 
      align: 'left',
    },
  ];

  return (
    <div className={classes.RestorationSteps}>
          <div className={classes.headerSection}>
              <h1 className={classes.title}>Restoration Process</h1>
          </div>

        <Timeline steps={steps} />
    </div>
  );
};

export default RestorationSteps;
