import React, { useEffect, useState } from "react";
import classes from "./SearchComponent.module.css";

import {
  fetchBook,
  getKeywords,
  getSuggestionsForNormalBooks,
} from "../../../Store/actions/BookAction";
import { useDispatch, useSelector } from "react-redux";
import Input from "../../../Components/Inputs/Input/Input";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearchengin } from "@fortawesome/free-brands-svg-icons";
const SeachIcon = "/Assets/Icons/icon-search.svg";

const SearchComponent = ({
  query,
  setQuery,
  onSearch,
  minYear,maxYear,
  selectedGenres
}) => {

  const [allRecommendations,setAllRecommendations] = useState([]);

  const [searchRecommendations,setSearchRecommedations] = useState([]);
  const [recommendationClicked,setRecommendationClicked] = useState(false);
  const [focus,setFocus] = useState(false);
  const [blurTimeout, setBlurTimeout] = useState(null);

  const handleRecommendationClick = (recommendation) => {
    const words = query.trim().split(" ");
    
    if (words.length > 1) {
      words[words.length - 1] = recommendation;
      setQuery(words.join(" "));
    } else {
      setQuery(recommendation);
    }
    setSearchRecommedations([]);
    setRecommendationClicked(true);
  };

  useEffect(() => {
    if(recommendationClicked) {
      onSearch();
      setRecommendationClicked(false);
    }
  },[recommendationClicked])
  
  
  useEffect(() => {
    if (query) {
      const timeout = setTimeout(() => {
        fetchPageData();
      },500);
    
       return () => clearTimeout(timeout);
    }
  }, [query]);

  
  const handleInputChange = (e) => {
    setQuery(e.target.value);
  };

  useEffect(() => {
    if (query) {
      const words = query.trim().split(/\s+/);
      const searchWord = words.length === 1 ? query.toLowerCase() : words[words.length - 1].toLowerCase();
      setSearchRecommedations(
        allRecommendations
      );
    } else {
      setSearchRecommedations([]);
    }
  }, [query, allRecommendations]);
  

  const dispatch = useDispatch();
  
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      onSearch();
    } else {
      const disallowedCharacters = ["(", "{", "[","]","}",")"];
      if (disallowedCharacters.includes(e.key)) {
        e.preventDefault();
      }
    }
  };

  const handleInputPaste = (e) => {
    const pastedText = e.clipboardData.getData("text");
    const sanitizedText = pastedText.replace(/[\(\)\{\}\[\]]/g, ""); // Remove disallowed characters
    e.preventDefault(); // Prevent the default paste action
    setQuery(query + sanitizedText); // Append sanitized text to the current query
  };

  useEffect(() => {
    getKeywords(dispatch);
  }, []);

  const handleFocus = () => {
    if (blurTimeout) {
      clearTimeout(blurTimeout); 
    }
    setFocus(true);
  };

  const handleBlur = () => {
    const timeout = setTimeout(() => setFocus(false), 1000);
    setBlurTimeout(timeout);
  };

  const fetchPageData = async () => {
    if (query !== "") {
        const data = await fetchBook(
          "all",
          query,
          null,
          10,
          1,
          null,
          minYear,
          maxYear,
          selectedGenres
        );

        const filteredRecommendations = data?.data?.map((book) => {
          if (book?.author?.toLowerCase().includes(query.toLowerCase())) {
            return book.author;
          } else if (book?.title?.toLowerCase().includes(query.toLowerCase())) {
            return book.title
          }
          return null;
        }).filter((item) => item !== null); // Remove null values

        const uniqueRecommendations = Array.from(
          new Map(filteredRecommendations.map((item) => [item.title, item])).values()
        );
    
        setAllRecommendations(uniqueRecommendations);
      }
  };



  return (
    <div className={classes.search}>
      <div className={classes.searchContainer}>
        <img className={classes.searchIcon} src={SeachIcon} alt="" />

        <Input
          type="text"
          className={classes.searchInput}
          placeholder="Search via Book title, Author name"
          value={query}
          onChange={handleInputChange}
          onKeyPress={handleKeyPress}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onPaste={handleInputPaste}
        />
        <button className={classes.searchButton} onClick={(e) => onSearch(e)}>
          Search Now
        </button>
      </div>

      {/* Dropdown with Recommendations */}
      {searchRecommendations.length > 0 && focus && (
        <div className={classes.dropdown}>
          {searchRecommendations.slice(0, 10).map((recommendation, index) => (
            <div
              key={index}
              className={classes.dropdownItem}
              onClick={() => handleRecommendationClick(recommendation)}
            >
              <FontAwesomeIcon icon={faSearchengin} />
              {recommendation.length > 70
                ? `${recommendation.slice(0, 70)}...`
                : recommendation}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SearchComponent;
