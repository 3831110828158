import React, { useRef, useState } from "react";
import classes from "./NavDropdown.module.css";
import Each from "../../../Components/Each/Each";
import useClickOutSide from "../../../Hooks/useClickOutSide";

import { logout } from "../../../Store/actions/UsersActions";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { urls } from "../../../Routes/Urls";



function NavDropdown({ children, isActive: initialIsActive, options, onOptionClick }) {
  const dropRef = useRef();
  const [isActive, setIsActive] = useState(initialIsActive);

  const dispatch = useDispatch();
  const history = useHistory()

  const handleClickOutside = () => {
    setIsActive(false);
  };

  useClickOutSide({ myRef: dropRef, isActive, onClick: handleClickOutside });

  const optionClickHandler = (option) => {
    
  if(option === "Logout"){
    logout(history,dispatch)
  }
  if(option === 'My Account'){
    history.push(history.location?.state?.from || urls.USERPROFILE)
  }
  if(option === 'Product List'){
    history.push(urls.LISTING)
  }

  if(option === 'Book Customization'){
    history.push(urls.SERVICES)
  }
  if(option === "Leather Binding"){
    history.push(urls.LEATHERBINDING)
  }
  if(option === "For Publishers"){
    history.push(urls.FORPUBLISHERS)
  }
  if(option === "PDF Printing"){
    history.push(urls.PDFPRINTING)
  }
  if(option === "Thesis Binding"){
    history.push(urls.THESISBINDING)
  }
  if(option === "Book Restoration"){
    history.push(urls.BOOKRESTORATION)
  }
  if(option === "Blogs"){
    history.push(urls.BLOG)
  }
  if(option === "Contact us"){
    history.push(urls.CONTACTUS)
  }
  if(option === "FAQs"){
    history.push(urls.FAQ)
  } 
  if(option === "Our Company"){
    history.push(urls.OURCOMPANY)
  }
  if(option === "Become Our Partner"){
    history.push(urls.BECOMEPARTNER)
  }
  if(option === "For Authors"){
    history.push(urls.FORAUTHOR)
  }
  if(option === "Print On Demand"){
    history.push(urls.PRINTONDEMAND)
  }
  if(option === "Corporate Gifts"){
    history.push(urls.CORPORATEGIFTS)
  }
  }

  const handleListClick = (e) => {
    const option = e.target.textContent;
    optionClickHandler(option)
  }

  return (
    <div className={classes.main} ref={dropRef}>
      <div className={classes.nav} onClick={() => setIsActive(!isActive)}>
        {children}
      </div>
      {isActive && (
        <ul className={options?.length < 5 ? `${classes.dropdown}` : `${classes.dropdown} ${classes.dropdownService}`}>
          <Each
            of={options}
            render={(option) => (
              <li key={option.value} onClick={e => handleListClick(e)}>
                {option.label}
              </li>
            )}
          />
        </ul>
      )}
    </div>
  );
}

export default NavDropdown;
