import React, { useEffect, useState } from 'react';
import classes from "./SearchComponent.module.css"
import { fetchBook,fetchBookCount, getKeywords } from '../../../../Store/actions/BookAction';
import { fetchBook as fetchBestSellerBook } from '../../../../Store/actions/BestSellerBookAction';
import { useDispatch, useSelector } from 'react-redux';
import useScreenSize from '../../../../Hooks/UseScreenSize';
import SearchBarSmall from '../SearchBarSmall/SearchBarSmall';
import { urls } from '../../../../Routes/Urls';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import PrimaryButton from '../../../../Components/Buttons/PrimaryButton/PrimaryButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearchengin } from '@fortawesome/free-brands-svg-icons';

const SearchComponent = () => {
    const keywords = useSelector((state) => state.keywords.keywords);
    const [query, setQuery] = useState("");
    const [searchCriteria, setSearchCriteria] = useState("all");
    const [isBestSellerRecommendation,setIsBestSellerRecommendation] = useState(false);

    const history = useHistory()
    const {isSmallScreen} = useScreenSize()

    const handleInputChange = (e) => {
        setQuery(e.target.value);
    };

    const dispatch = useDispatch();

    const handleSearch = async () => {
        if (query === "") return;
        const searchResults = await fetchBook(searchCriteria, query, dispatch);
        const searchCount = await fetchBookCount(searchCriteria,query);
    
        // Redirect to LISTING page with search results as state
        history.push({
            pathname: urls.LISTING,
            state: { 
                searchResults, 
                searchCount,
                searchCriteria,
                query
            }
        });
    };

    const handleKeyWordSearch = async (keyword) => {
        if(keyword){
            const searchCriteria = "genres";
            const searchResults = await fetchBook(searchCriteria, keyword, dispatch);
            const searchCount = await fetchBookCount("","","","",[keyword]);
            
            // Redirect to LISTING page with search results as state
            history.push({
                pathname: urls.LISTING,
                state: { 
                    searchResults, 
                    searchCount,
                    searchCriteria,
                    keyword
                }
            });
        }
    }

    const handleKeyPress = (e) => {
        if(e.key === "Enter"){
            handleSearch()
        } else {
          const disallowedCharacters = ["(", "{", "[","]","}",")"];
          if (disallowedCharacters.includes(e.key)) {
            e.preventDefault();
          }
        }
    } 
  
    const handleInputPaste = (e) => {
      const pastedText = e.clipboardData.getData("text");
      const sanitizedText = pastedText.replace(/[\(\)\{\}\[\]]/g, ""); // Remove disallowed characters
      e.preventDefault(); // Prevent the default paste action
      setQuery(query + sanitizedText); // Append sanitized text to the current query
    };

    useEffect(() => {
        getKeywords(dispatch);
    }, []);

    const [allRecommendations,setAllRecommendations] = useState([])

    const [searchRecommendations,setSearchRecommedations] = useState([]);
    const [recommendationClicked,setRecommendationClicked] = useState(false);
    const [focus,setFocus] = useState(false);
    const [blurTimeout, setBlurTimeout] = useState(null);
  
    const handleRecommendationClick = (recommendation) => {
      const words = query.trim().split(" ");
      if (words.length > 1) {
        const sanitizedText = recommendation?.title?.replace(/[\(\)\{\}\[\]]/g, ""); // Remove disallowed characters
        words[words.length - 1] = sanitizedText; // Replace the last word with sanitized recommendation title
        setQuery(words.join(" "));
      } else {
        const sanitizedText = recommendation?.title?.replace(/[\(\)\{\}\[\]]/g, ""); // Remove disallowed characters
        setQuery(sanitizedText);
      }
      setIsBestSellerRecommendation(recommendation?.isBestSeller);
      setSearchRecommedations([]);
      setRecommendationClicked(true);
    };
  
    useEffect(() => {
      if(recommendationClicked) {
        if(!isBestSellerRecommendation) {
          handleSearch();
        } else {
            history.push({
              pathname: urls.BESTSELLER,
              state: {  
                  query
              }
          });
          setIsBestSellerRecommendation(false)
        }
        setRecommendationClicked(false);
      }
    },[recommendationClicked])
    
    useEffect(() => {
      if (query) {
        const timeout = setTimeout(() => {
          fetchPageData();
        },500);
      
         return () => clearTimeout(timeout);
      }
    }, [query,searchCriteria]);

    useEffect(() => {
        if (query) {
          setSearchRecommedations(
            allRecommendations
          );
        } else {
          setSearchRecommedations([]);
        }
      }, [query, allRecommendations]);

      const handleFocus = () => {
        if (blurTimeout) {
          clearTimeout(blurTimeout); 
        }
        setFocus(true);
      };
    
      const handleBlur = () => {
        const timeout = setTimeout(() => setFocus(false),1000);
        setBlurTimeout(timeout);
      };

      const getFilter = () => {
        let filter = {
          limit: 5,
          offset:0,
          where: {
            $and: [],
          },
        };
    
        if (query) {
            if(searchCriteria === "all") {
              filter.where.$and.push({
                $or: [
                  { author: `*${query}*` },
                  { title: `*${query}*` },
                  { language: `*${query}*` },
                  { sku: `*${query}*` },
                  { isbnNo: `*${query}*` },
                ],
              });
            } else {
              filter.where.$and.push({
                $or: [
                  { [searchCriteria]: `*${query}*` },
                ],
              });
            }
          }
    
        return filter;
      };

      const fetchPageData = async () => {
        if (query !== "") {
            const filter = getFilter()
            const dataBestSeller = await fetchBestSellerBook(
                     null,
                     filter
                    );
                    
            const data = await fetchBook(
              searchCriteria,
              query,
              null,
              5,
              1,
              null
            );

            const filteredBestSellerRecommendations = dataBestSeller?.data.map((book) => {
              if(searchCriteria !== "sku" && searchCriteria !== "isbnNo"){
              if (book?.author?.toLowerCase().includes(query.toLowerCase())) {
                return {title : book.author, isBestSeller:true};
              } else if (book?.title?.toLowerCase().includes(query.toLowerCase())) {
                return {title : book.title, isBestSeller:true};
              }}
              else if (book?.sku === query.toLowerCase()) {
                return {title : book.sku, isBestSeller:true};
              } else if (book?.isbnNo === query.toLowerCase()) {
                return {title : book.isbnNo, isBestSeller:true};
              }
          
              return null;
            }).filter((item) => item !== null); // Remove null values

            const filteredRecommendations = data.data.map((book) => {
              if(searchCriteria !== "sku" && searchCriteria !== "isbnNo"){
              if (book?.author?.toLowerCase().includes(query.toLowerCase())) {
                return {title : book.author, isBestSeller:false};
              } else if (book?.title?.toLowerCase().includes(query.toLowerCase())) {
                return {title : book.title, isBestSeller:false};
              }}
              else if (book?.sku === query.toLowerCase()) {
                return {title : book.sku, isBestSeller:false};
              } else if (book?.isbnNo === query.toLowerCase()) {
                return {title : book.isbnNo, isBestSeller:false};
              }
              return null;
            }).filter((item) => item !== null); // Remove null values

            const allFilteredRecommendations = filteredBestSellerRecommendations?.concat(filteredRecommendations)

            const uniqueRecommendations = Array.from(
              new Map(allFilteredRecommendations.map((item) => [item.title, item])).values()
            );
        
            setAllRecommendations(uniqueRecommendations);
    
        }
      };

    return (
        (!isSmallScreen ? (<div className={classes.search}>
                <div className={classes.container}>
                  <div className={classes.searchContainer}>
                    <div className={classes.dropdownContainer}>
                      <select
                          name="searchField"
                          id="searchField"
                          required
                          onChange={(e) => setSearchCriteria(e.target.value)}
                      >
                          <option value="all">All</option>
                          <option value="title">Book Title</option>
                          <option value="author">Author</option>
                          <option value="isbnNo">ISBN Number</option>
                          <option value="sku">SKU</option>
                          {/* <option value="keyword">Keyword</option>
                          <option value="ISBN">ISBN</option> */}
                      </select>
                    </div>

                    <input
                        type="text"
                        className={classes.searchInput}
                        placeholder="Search via , Book title, Author"
                        value={query}
                        onChange={handleInputChange}
                        onKeyDown={(e) => handleKeyPress(e)}
                        onFocus={handleFocus}  
                        onBlur={handleBlur} 
                        onPaste={handleInputPaste}
                    />
                    <PrimaryButton className={classes.searchButton} onClick={handleSearch}>
                        Search Now
                    </PrimaryButton>
                  </div>
                  {/* Dropdown with Recommendations */}
                  {searchRecommendations.length > 0 && focus && (
                    <div className={classes.dropdown}>
                    {searchRecommendations?.slice(0,10).map((recommendation, index) => (
                      <div
                        key={index}
                        className={classes.dropdownItem}
                        onClick={() => handleRecommendationClick(recommendation)}
                      >
                        <FontAwesomeIcon icon={faSearchengin}/>
                        {recommendation?.title}
                      </div>
                    ))}
                    </div>
                  )}
              </div>
           
                {/* <SearchBarSmall/> */}
        

            <div className={classes.keywords}>
              {keywords.map((keyword, index) => (
                <span key={index} onClick={() => handleKeyWordSearch(keyword)}>{keyword}</span>
              ))}
            </div>
        </div>) : (
          <div>
            <div className={classes.search}>
              <SearchBarSmall />
            </div>
            <div className={classes.keywords}>
              {keywords.map((keyword, index) => (
                <span key={index} onClick={() => handleKeyWordSearch(keyword)}>{keyword}</span>
              ))}
            </div>
          </div>
        ))
    );
};

export default SearchComponent;
