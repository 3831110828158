import classes from "./AboutForPublishers.module.css"

const image1 = "/Assets/Images/customization/LeatherMatrerialIcon.png"
const image2 = "/Assets/Images/customization/handcraftedExcellence.png"
const image3 = "/Assets/Images/customization/customization.png"

const cardData = [
    {
        title: "Special Bookseller Discount",
        desc: " Take advantage of our high discounts tailored for booksellers. Additionally, enjoy exclusive bulk order discounts when sourcing multiple leather-bound editions, enabling you to maximize your profitability while offering premium products to your customers.",
        image: image1
    },
    {
        title: "No Minimum Order Quantity",
        desc: "At Rare Biblio, there is no minimum order requirement. Whether you’re ordering a single book or multiple editions, we’re delighted to fulfill your order with the same level of dedication and care.",
        image: image2
    },
    {
        title: "High-profit margins",
        desc: "Leather-bound books cater to a premium market willing to pay a high price, allowing booksellers to enjoy increased profit margins on each sale.",
        image: image3
    },
    {
        title: "Drop shipping",
        desc: "Rare Biblio is pleased to offer drop shipping services, delivering any order directly to your customers worldwide at no additional cost. We ensure a seamless experience, handling the shipping with care and efficiency.",
        image: image3
    },
    {
        title: "Wide Range of Titles",
        desc: "At Rare Biblio, we offer an extensive selection of books across all genres. With a database of over 50 million titles, we can source any book you need—whether it’s romance, mystery, occult, or any other genre. Whatever your requirements, we have it covered.",
        image: image3
    },
    {
        title: "Customization Opportunities",
        desc: "We provide personalization options so that customers may choose the type and color of leather, along with aesthetic marble papers and even customized embossing of logos and borders on their books. This difference makes you stand apart from other book dealers.",
        image: image3
    },
]

const AboutForPublishers = () => {
    return (
        <div className={classes.mainContainer}>
            <div className={classes.headerSection}>
                <h2>
                    Why Partner with Us?
                </h2>
                {/* <p>Our mission is to encapsulate your academic journey in the timeless beauty of leather, ensuring that your thesis remains a testament to your dedication and intellect.</p> */}
            </div>

            <div className={classes.cardSection}>
                {cardData.map((card, index) => (
                    <div key={index} className={classes.card}>
                        <div className={classes.image}>
                            <img src={card.image} alt="" />
                        </div>
                        <div className={classes.title}>
                            <h2>{card.title}</h2>
                            <p>{card.desc}</p>
                        </div>
                    </div>
                ))}
            </div>


        </div>
    )
}

export default AboutForPublishers