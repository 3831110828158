import axios from "axios";
import { SERVICE_URL } from "../ServiceUrls";
import { BestSellerBookActions } from "../Slices/BestSellerBookSlice";
import { store } from "../Store";
import { getRandomImageForBestSellerBook } from "../../Util";

export const fetchAllBooks = async (dispatch, limit = 16, page = 1) => {
  const filter = {
    limit: limit,
    offset: (page - 1) * limit,
  };

  try {
    const response = await axios({
      url: `${SERVICE_URL.BESTSELLERBOOK}?filter=${encodeURIComponent(JSON.stringify(filter))}`,
      method: "get",
      responseType: "json",
    });

    if (response.data.success) {
      dispatch(BestSellerBookActions.updateBooks(response.data.data));
      return { data: response.data.data, total: response.data.total };
    }
    return { data: [], total: 0 };
  } catch (error) {
    console.error(error);
    return { data: [], total: 0 };
  }
};

export const fetchAllBooksCount = async (dispatch) => {
  try {
    const response = await axios({
      url: `${SERVICE_URL.BESTSELLERBOOK}/count`,
      method: "get",
      responseType: "json",
    });

    if (response.data.success) {
      return response.data.data;
    }
    return 0;
  } catch (error) {
    console.error(error);
    return 0;
  }
};

export const fetchBookById = (id,dispatch) => {
  return axios({
    url:`${SERVICE_URL.BESTSELLERBOOK}/${id}`,
    method:"get"
  }).then(res => {
    if(res.data.success){
      dispatch(BestSellerBookActions.updateBookById(res.data.data))
    }
  }).catch(err => {
    console.error(err)
  }) 
}

export const fetchBook = (
  dispatch,
  filter
) => {

  return axios({
    url: `${SERVICE_URL.BESTSELLERBOOK}?filter=${encodeURIComponent(
      JSON.stringify(filter)
    )}`,
    method: "get",
    responseType: "json",
  })
    .then((response) => {
      if (response.data.success) {
        const booksData = response.data.data;
        const allColorsData = store.getState().bookColor.colors;
        const addRandomImagesToBookData = booksData.map((data) =>  ({...data, imageUrl: getRandomImageForBestSellerBook(data, allColorsData )?.image }));
        if(dispatch) {
          dispatch(BestSellerBookActions.updateBooks(addRandomImagesToBookData));
        }
        return { data: addRandomImagesToBookData, total: response.data.total };
      }
      return { data: [], total: 0 };
    })
    .catch((error) => {
      console.error(error);
      return { data: [], total: 0 };
    });
};
export const fetchBookCount = (
  field,
  query,
  minPublishingYear,
  maxPublishingYear,
  selectedGenre,
  additionalQuery
) => {
  let filter = {
    where: {
      $and: [],
    },
  };

  if (!additionalQuery) {
    if (field !== "all" && query) {
      query = `'${query}'`
      filter.where.$and.push({ [field]: query });
    } else if (query) {
      query = `'${query}'`
      filter.where.$and.push({
        $or: [{ author: query }, { title: query }, { language: query },{ sku: query },{ isbnNo: query }],
      });
    }

    // Add publishingYear range condition in the format "MIN TO MAX"
    if (minPublishingYear || maxPublishingYear) {
      const publishingYearCondition = {
        publishingYear: `[${minPublishingYear || "*"} TO ${
          maxPublishingYear || "*"
        }]`,
      };
      filter.where.$and.push(publishingYearCondition);
    }

    // Add genre condition if selectedGenre is provided
    if (selectedGenre && selectedGenre?.length > 0) {
      filter.where.$and.push({ genres: selectedGenre.join(",") });
    }
  } else if(query) {
    query = `'${query}'`
    filter.where.$and.push({
      $or: [{ author: query }, { title: query }, { language: query }],
    });

    // Add additional query conditions
    if (additionalQuery) {
      filter.where.$and.push({
        $or: [
          { author: additionalQuery },
          { title: additionalQuery },
          { language: additionalQuery },
          { sku: additionalQuery },
          { isbnNo: additionalQuery },
        ],
      });
    }

    // Add publishingYear range condition in the format "MIN TO MAX"
    if (minPublishingYear || maxPublishingYear) {
      const publishingYearCondition = {
        publishingYear: `[${minPublishingYear || "*"} TO ${
          maxPublishingYear || "*"
        }]`,
      };
      filter.where.$and.push(publishingYearCondition);
    }

    // Add genre condition if selectedGenre is provided
    if (selectedGenre && selectedGenre?.length>0) {
      filter.where.$and.push({ genres: selectedGenre.join(",") });
    }
  }

  return axios({
    url: `${SERVICE_URL.BESTSELLERBOOK}/count?filter=${encodeURIComponent(
      JSON.stringify(filter)
    )}`,
    method: "get",
    responseType: "json",
  })
    .then((response) => {
      if (response.data.success) {
        return response.data.data;
      }
    })
    .catch((error) => {
      console.error(error);
      return null;
    });
};


export const getFacets = () => {
  return axios({
    url: `${SERVICE_URL.BESTSELLERBOOK}/facets`,
    method: "get",
    responseType: "json",
  }).then((response) => {
    if (response.data.success) {
      return response.data.data;
    }
  });
};