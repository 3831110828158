import axios from "../../Axios";
import { usersActions } from "../Slices/UsersSlice";
import Consts from "../../Consts";
import { SERVICE_URL } from "../ServiceUrls";
import { CartActions } from "../Slices/CartSlice";

export function signInUser(data, dispatch) {
  return axios({
    url: `${SERVICE_URL.LOGIN}`,
    method: "post",
    data: data,
    responseType: "json",
  })
    .then((response) => {
      if (response.data.success) {
        dispatch(usersActions.updateCurrentUser(response.data.user));
      }
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export function signUpUser(data, dispatch) {
  return axios({
    url: `${Consts.SERVER_URL}/users/create`,
    method: "post",
    data: data,
    responseType: "json"
  })
    .then((response) => {
      if(response.data.success){
        dispatch(usersActions.updateCurrentUser(response.data.user));
        return response;
      }
    })
    .catch((error) => {
      return error;
    })
}

export function logout(history,dispatch) {
  return axios({
    url: `${SERVICE_URL.LOGOUT}`,
    method: "get",
  }).then((e) => {
    dispatch(usersActions.logoutUser())
    dispatch(CartActions.updateCartItems([]));
    history.push("/")
  });
}

export function forgotPassword(data,dispatch) {
  return axios({
    url: `${Consts.SERVER_URL}/users/forgotpassword`,
    method:"post",
    data:data,
    responseType:"json"
  })
  .then((response) => {
    // if(response.data.success){
    //   // if(isUserLoggedIn){
    //     // dispatch(usersActions.updateCurrentUser(response.data.data));

    //   // }
    // }
    return response;
  })
  .catch((error) => {
    return error;
  })
}

export const fetchUserDetails = () => async(dispatch) => {
    const isLoggedIn = localStorage.getItem("isLoggedIn") === "true";

    if(isLoggedIn){
        return axios({
          url:`${Consts.SERVER_URL}/users/profile`,
          method:"get",
          responseType:"json"
        })

        .then((response) => {
          if(response.data.success){
            dispatch(usersActions.updateCurrentUser(response.data.data))
          }

          return response
        })
        .catch((error) => {
          return error
        })
    }
}

export const updateUser = (userDetails,photo,dispatch) => {
  const formData = new FormData();
  formData.append('userDetails', JSON.stringify(userDetails));
  formData.append('photo',photo)
  return axios({
    url:`${Consts.SERVER_URL}/users/updateProfile`,
    method:"post",
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    responseType:"json"
  })
  .then((response) => {
    if(response.data.success){
      dispatch(usersActions.updateCurrentUser(response.data.data))
    }

    return response
  })
  .catch((error) => {
    return error
  })
}

export const updatePassword = (data,dispatch) => {
  const isLoggedIn = localStorage.getItem("isLoggedIn") === "true";

  if(isLoggedIn){
    return axios({
      url:`${Consts.SERVER_URL}/users/updatePassword`,
      method:"put",
      data:data,
      responseType:"json"
    })
    .then((response) => {
      return response
    })
    .catch((error) => {
      return error
    })
  }
}

export const addUserAddress = (addressData, addressType, dispatch) => {
  return axios({
    url: `${Consts.SERVER_URL}/users/add-address`,
    method: "post",
    data: addressData,
    params: {
      addressType: addressType
    },
    headers: {
      'Content-Type': 'application/json',
    },
    responseType: "json"
  })
  .then((response) => {
    if (response.data.success) {
      dispatch(usersActions.updateCurrentUser(response.data.data));
    }

    return response;
  })
  .catch((error) => {
    return error;
  });
}

export const deleteAddress = (addressId, addressType, dispatch) => {
  return axios({
    url: `${Consts.SERVER_URL}/users/delete-address?addressId=${addressId}&addressType=${addressType}`,
    method: "delete",
    responseType: "json",
  })
  .then((response) => {
    if (response.data.success) {
      dispatch(usersActions.updateCurrentUser(response.data.data));
    }
    return response;
  })
  .catch((error) => {
    return error;
  });
};

export const updateAddress = (addressId, addressData, addressType, dispatch) => {
  return axios({
    url: `${Consts.SERVER_URL}/users/address/${addressId}?addressType=${addressType}`,
    method: "put",
    data: addressData,
    headers: {
      'Content-Type': 'application/json',
    },
    responseType: "json",
  })
  .then((response) => {
    if (response.data.success) {
      dispatch(usersActions.updateCurrentUser(response.data.data))
    }
    return response;
  })
  .catch((error) => {
    return error;
  });
};

export const validateRBExclusiveAccess = (code) => {
  return axios({
    url: `${Consts.SERVER_URL}/users/validate-exclusive-access?exclusiveCode=${code}`,
    method: "get",
  })
  .then((response) => {
    return response;
  })
  .catch((error) => {
    return error;
  });
};
