import React from "react";
import classes from "./OrderTimline.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBook, faCheck, faCheckCircle, faClipboardCheck, faListCheck, faPrint, faTruckFast } from "@fortawesome/free-solid-svg-icons";

const OrderTimeline = ({ currentStep = 2 }) => {
  const steps = [
    { label: "Order Placed", icon: <FontAwesomeIcon icon={faClipboardCheck} /> },
    { label: "Book Printing", icon: <FontAwesomeIcon icon={faPrint} /> },
    { label: "Book Binding", icon: <FontAwesomeIcon icon={faBook} /> },
    { label: "Quality Checking", icon: <FontAwesomeIcon icon={faListCheck} />},
    { label: "Shipped", icon: <FontAwesomeIcon icon={faTruckFast} /> },
    { label: "Delivered", icon: <FontAwesomeIcon icon={faCheckCircle} /> },
  ];

  return (
    <div className={classes.timeline}>
      {steps.map((step, index) => (
        <div key={index} className={`${classes.step} ${index < currentStep ? classes.active : ""}`}>
          <div className={classes.timelineItem}>
            <div className={`${classes.circle} ${index < currentStep && currentStep !== 6 ? classes.completed : currentStep === 6 ? classes.circleOrderComplete : ""}`}>
                {index < currentStep && <FontAwesomeIcon className={classes.tickIcon} icon={faCheck} />}
            </div>
            <div className={classes.itemDetail}>
                <div className={classes.icon}>{step.icon}</div>
                <p className={classes.label}>{step.label}</p>
            </div>
          </div>
          {index < steps.length - 1 && <div className={`${classes.line} ${index < currentStep && currentStep !== 6 ? classes.lineCompleted : currentStep === 6 ? classes.orderLineDelivered : ""}`}></div>}
        </div>
      ))}
    </div>
  );
};

export default OrderTimeline;
