import classes from "./ForgotPassword.module.css"
import React, { useState } from "react";
import { useHistory } from "react-router";

import PrimaryButton from "../../Components/Buttons/PrimaryButton/PrimaryButton";
import Password from "../../Components/Password/Password";
import {  forgotPassword } from "../../Store/actions/UsersActions";
import Input from "../../Components/Inputs/Input/Input";
import { useDispatch } from "react-redux";


import { useEffect } from "react";

const slide1 = "/Assets/Images/signUp1.svg"
const slide2 = "/Assets/Images/SignUp2.svg"
const slide3 = "/Assets/Images/SignUp3.svg"


export function ForgotPassword({ props }) {
  const history = useHistory();
  const dispatch = useDispatch()

  const [userDetails, setUserDetails] = useState({})
  const [confirmPassword, setConfirmPassword] = useState("")
  const [isPasswordValid, setIsPasswordValid] = useState(true)

  const signInHandler = (e) => {
    
  }


  const [currentIndex, setCurrentIndex] = useState(0);

  // const forgotPasswordHandler = (e) =>{
  //     e.preventDefault()
  //     

  //     if(validateEmail(userDetails.userId).valid){
  //         let data = {...userDetails}

  //         if(userDetails.password)
  //     }
  // }

  const forgotPasswordHandler = (e) => {
    e.preventDefault()

    const data = { ...userDetails, userId: userDetails.userId.toLowerCase() }

    if (userDetails.password === "" || confirmPassword === "" || userDetails.password !== confirmPassword) {
      setIsPasswordValid(false);
      return;
    }
    forgotPassword(data, dispatch).then((response) => {
      if (response && response.data && response.data.success) {
        history.push(
          history.location?.state?.from || "/login"
        );
      } 
    });
  }


  const slides = [
    {
      image:slide1,
      text:'"Cherish the Craftsmanship of Every Leather-Bound Edition."'
    },
    {
      image:slide2,
      text:'"Embrace the Timeless Elegance of Leather-Bound Treasures."'
    },
    {
      image:slide3,
      text:`"Elevate your book collection with us"`
    },
  ];

  const goToNextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
  };

  const goToSlide = (index) => {
    setCurrentIndex(index);
  };

  useEffect(() => {
    const interval = setInterval(goToNextSlide, 2000);
    return () => clearInterval(interval); // Clear the interval on component unmount
  }, []);

 
  const isDisabled = () => {
     if(!userDetails.userId || !userDetails.password || !confirmPassword || userDetails.password !== confirmPassword ){
      return true;
     }
     return false;
  }

  return (
    <div className={classes.mainContainer}>
      <div className={classes.Container}>
        <div className={classes.ForgotPasswordForm}>
          <h2>Forgot your password</h2>
          <p>Don’t worry, happens to all of us. Enter your email below to recover your password</p>
          <form>
            <div className={classes.formGroup}>
              {/* <label htmlFor="email">Email</label> */}
              {/* <input type="email" id="email" name="email" placeholder='Email' required /> */}
              <Input
                type='email'
                id='userId'
                placeholder="Email"
                autoFocus={true}
                onChange={(e) =>
                  setUserDetails((prevState) => {
                    return {
                      ...prevState,
                      userId: e.target.value.trim(),
                    };
                  })}
                value={userDetails.userId || ""}
              />
              {/* {!isEmailValid && (
                <p
                  style={{
                    color: "red",
                    marginTop: "1rem",
                    marginBottom: "0",
                  }}>
                  Email is not valid{" "}
                </p>
              )} */}

              <div className={classes.password}>
                {/* <input type={passwordVisisble? "text" :"password"} id="password" name="password" placeholder="Password" required />
                <span className={classes.showPassword} onClick={togglePasswordVisible}><img src={eye} alt="" /></span> */}
                <Password
                  id='password'
                  placeholder="Enter New Password"
                  togglePasswordVisibility={true}
                  height='2.25rem'
                  onChange={(e) =>
                    setUserDetails((prevState) => {
                      return {
                        ...prevState,
                        password: e.target.value,
                      };
                    })
                  }
                  autoComplete='off'
                  onCommit={signInHandler}
                />
                {!isPasswordValid && (
                  <p
                    style={{
                      color: "red",
                      marginTop: "1rem",
                      marginBottom: "0",
                    }}>
                    Password is not valid{" "}
                  </p>
                )}
              </div>
              <div className={classes.password}>
                {/* <input type={passwordVisisble? "text" :"password"} id="password" name="password" placeholder="Password" required />
                <span className={classes.showPassword} onClick={togglePasswordVisible}><img src={eye} alt="" /></span> */}
                <Password
                  id='password'
                  placeholder="Confirm New Password"
                  togglePasswordVisibility={true}
                  height='2.25rem'
                  onChange={(e) => setConfirmPassword(e.target.value)
                  }
                  autoComplete='off'
                  onCommit={signInHandler}
                />
                {!isPasswordValid && (
                  <p
                    style={{
                      color: "red",
                      marginTop: "1rem",
                      marginBottom: "0",
                    }}>
                    Password is not valid{" "}
                  </p>
                )}
              </div>

            </div>

            <PrimaryButton
              onClick={(e) => forgotPasswordHandler(e)}
              disabled={isDisabled()}
              style={{
                width: "98%",
                height: "2.5rem",
                fontSize: "0.875rem",
              }}>
              Submit
            </PrimaryButton>
          </form>
        </div>
        <div className={classes.carouselContainer}>
          <div className={classes.carousel}>
            {slides.map((slide, index) => (
              <div
                key={index}
                className={`${classes.slide} ${index === currentIndex ? classes.active : ''}`}
                style={{backgroundImage:`url(${slide.image})`}}
              >
                <p className={index % 2 !== 0? classes.slideTextTop:""}>{slide.text}</p>
              </div>
            ))}
          </div>
          <div className={classes.dots}>
            {slides.map((_, index) => (
              <span
                key={index}
                className={`${classes.dot} ${index === currentIndex ? classes.activeDot : ''}`}
                onClick={() => goToSlide(index)}
              ></span>
            ))}
          </div>
        </div>
      </div>
    </div>

  );
}