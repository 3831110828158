import React, { useState } from "react";
import { useHistory } from "react-router";
import Password from "../../Components/Password/Password";
import { validateEmail } from "../../Util";
import { signInUser } from "../../Store/actions/UsersActions";
import MessageDialog from "../../Components/MessageDialogue/MessageDialogue";
import Consts from "../../Consts";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { Link } from 'react-router-dom';
import classes from './Login.module.css';
import SocialMediaButton from '../../Components/Buttons/SocialMediaButton/SocialMediaButton';
import SecondaryButton from "../../Components/Buttons/SecondaryButton/SecondaryButton";
import Checkbox from "../../Components/Checkbox/Checkbox";
import PrimaryInput from "../../Components/Inputs/PrimaryInput/PrimaryInput";

const failIcon = "/Assets/Icons/icon-error.svg"
const slide1 = "/Assets/Images/signUp1.svg"
const slide2 = "/Assets/Images/SignUp2.svg"
const slide3 = "/Assets/Images/SignUp3.svg"
const google = "/Assets/Icons/google.svg";

const config = await fetch("/api/openAuth/googleAuthData").then(i => i.json()).then(i => i.data);
const scope = "openid profile email";
const state = "random_string_to_prevent_csrf";
const encode = window.encodeURIComponent;
const authEndpoint = `https://accounts.google.com/o/oauth2/v2/auth?response_type=code&client_id=${encode(config.clientId)}&redirect_uri=${encode(config.redirectUrl)}&scope=${encode(scope)}&state=${encode(state)}`;

const LoginComponent = (props) => {
    const history = useHistory();

    const [userDetails, setUserDetails] = useState(props.userDetails || {});
    const [isEmailValid, setIsEmailValid] = useState(true);
    const [isPasswordValid, setIsPasswordValid] = useState(true);
    const [showMessageDialog, setShowMessageDialog] = useState(false);
    const [dialogContent, setDialogContent] = useState({
        title: "",
        message: "",
    });

    const dispatch = useDispatch();
    
    function signInHandler(e) {
      e?.preventDefault();
      if (validateEmail(userDetails.userId).valid) {
        
        let data = { ...userDetails, userId: userDetails.userId.toLowerCase() };

        if (userDetails.password) {
          signInUser(data,dispatch).then((response) => {
            if (response && response.data && response.data.success) {
              
              localStorage.setItem("isLoggedIn", "true");
              history.push(
                history.location?.state?.from || "/"
              );
            } else if (response.data?.errorCode) {
              setDialogContent(() => {
                return {
                  title: "Failed",
                  message: "Invalid Email or Password",
                };
              });
              setShowMessageDialog(true);
            }
          });
          setIsPasswordValid(true);
        } else {
          setIsPasswordValid(false);
        }
        setIsEmailValid(true);
      } else {
        setIsEmailValid(false);
      }
    }
  
    function forgotPasswordHandler(e) {
      e.preventDefault()
      history.push({
        // pathname: `${Consts.BASE_URL}/forgot-password`,
        pathname: `/forgot-password`,
        state: { ...history.location?.state },
      });
    }
    

    const [currentIndex, setCurrentIndex] = useState(0);


  const slides = [
    {
      image:slide1,
      text:'"Cherish the Craftsmanship of Every Leather-Bound Edition."'
    },
    {
      image:slide2,
      text:'"Embrace the Timeless Elegance of Leather-Bound Treasures."'
    },
    {
      image:slide3,
      text:`"Elevate your book collection with us"`
    },
  ];

  const goToNextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
  };

  const goToSlide = (index) => {
    setCurrentIndex(index);
  };

  useEffect(() => {
    const interval = setInterval(goToNextSlide, 2000);
    return () => clearInterval(interval); // Clear the interval on component unmount
  }, );

  return (
    <div>
    <div className={classes.loginContainer}>
    {showMessageDialog && (
        <div > 
            <MessageDialog
            {...dialogContent}
            onClose={() => setShowMessageDialog(false)}
            closeText="Close"
            width={Consts.MESSAAGE_DIALOG_WIDTH}
            dialogueIcon={failIcon}
          />
        </div>

        )}
      <div className={classes.loginForm}>
        <h2>Login</h2>
        <p>Login to access your account</p>
        <form>
          <div className={classes.formGroup}>
            <PrimaryInput
                type='email'
                id='userId'
                placeholder="Email"
                autoFocus={true}
                onChange={(e) =>
                  setUserDetails((prevState) => {
                    return {
                      ...prevState,
                      userId: e.target.value.trim(),
                    };
                  })
                }
                value={userDetails.userId || ""}
              />
              {!isEmailValid && (
                <p
                  style={{
                    color: "red",
                    marginTop: "1rem",
                    marginBottom: "0",
                  }}>
                  Email is not valid{" "}
                </p>
              )}
          </div>
          <div className={classes.formGroup}>
            <div className={classes.password}>
                <Password
                id='password'
                placeholder="Password"
                togglePasswordVisibility={true}
                height='2.25rem'
                onChange={(e) =>
                  setUserDetails((prevState) => {
                    return {
                      ...prevState,
                      password: e.target.value,
                    };
                  })
                }
                autoComplete='off'
                onCommit={signInHandler}
                onKeyDown = {(e) => signInHandler(e)}
              />
              {!isPasswordValid && (
                <p
                  style={{
                    color: "red",
                    marginTop: "1rem",
                    marginBottom: "0",
                  }}>
                  Password is not valid{" "}
                </p>
              )}
            </div>
          </div>
          <div className={classes.options}>
            <div>
              <Checkbox id={"remember-me"} label={"Remember me"} onChange={() => {}} />
            </div>
            <div className={classes.forgotPassword} onClick={(e) => forgotPasswordHandler(e)}>
                  Forgot Password
              </div>
          </div>
          <SecondaryButton
                  onClick={(e) => signInHandler(e)}
                  style={{
                    width: "98%",
                    height: "2.5rem",
                    fontSize: "0.875rem",
                  }}>
                  Sign In
                </SecondaryButton>
        </form>
        <p className={classes.signUpText}>
          Don't have an account? <Link to="/signup">Sign up</Link>
        </p>
        <div className={classes.orLoginWith}>Or login with</div>
        <div className={classes.socialButtons}>
          <SocialMediaButton url={authEndpoint} className={classes.socialButton} icon={google} title={"Login with Google"}
            callback={e => {
              let data = {
                with: "google",
                token: e.id_token
              }
              signInUser(data, dispatch).then((response) => {
                if (response && response.data && response.data.success) {
                  localStorage.setItem("isLoggedIn", "true");
                  history.push("/");
                } else if (response.data?.errorCode) {
                  
                }
              });
            }}
          />
        </div>
      </div>
      <div className={classes.carouselContainer}>
        <div className={classes.carousel}>
          {slides.map((slide, index) => (
            <div
              key={index}
              className={`${classes.slide} ${index === currentIndex ? classes.active : ''}`}
              style={{backgroundImage: `url(${slide.image})`}}
            >
              <p className={index % 2 !== 0? classes.slideTextTop:""}>{slide.text}</p>
            </div>
          ))}
        </div>
        <div className={classes.dots}>
          {slides.map((_, index) => (
            <span
              key={index}
              className={`${classes.dot} ${index === currentIndex ? classes.activeDot : ''}`}
              onClick={() => goToSlide(index)}
            ></span>
          ))}
        </div>
      </div>
    </div>
    </div>
    
  );
};

export default LoginComponent;
