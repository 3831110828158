import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getClasses } from "../../Util";
import classes from "./Arrows.module.css"

export default function NextArrow(props) {
    const { onClick,className } = props;
    return (
      <div
        className={getClasses(className,classes.leftArrow)}
        onClick={onClick}
      >
        <FontAwesomeIcon className={classes.arrow} icon={faAngleRight} />
      </div>
    );
  }
  
  export function PrevArrow(props) {
    const { onClick,className } = props;
    return (
      <div
        className={getClasses(className,classes.RightArrow)}
        onClick={onClick}
      >
        <FontAwesomeIcon className={classes.arrow} icon={faAngleLeft} />
      </div>
    );
  }