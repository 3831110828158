import './App.css';
import WarningDialogue from "./Components/WarningDialogue/WarningDialogue";
import Loader from "./Components/Loader/Loader";
import MyRouter from './Routes/MyRouter';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { fetchUserDetails } from './Store/actions/UsersActions';
import { fetchFeaturedCollection } from './Store/actions/FeaturedCollectionActions';
import { fetchGalleryByType } from './Store/actions/GalleryActions';
import { fetchAllBlogs, getBlogCategories } from './Store/actions/BlogsAction';
import { fetchAllBookColors } from './Store/actions/BookColorAction';
import { fetchAllVideos } from './Store/actions/ServiceVideoAction';
import { addBestSellerCartItem, addCartItem, fetchAllCartItems } from './Store/actions/CartAction';
import { isUserLoggedIn } from './Util';
import { getSuggestionsForNormalBooks } from './Store/actions/BookAction';
import { fetchAllWishlistItems } from './Store/actions/WishlistAction';
import { CartActions } from './Store/Slices/CartSlice';
import VerificationPendingModal from './Components/verificationPendingModal/verificationPendingModal';

function App() {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.user.currentUser);
  const cartItems = useSelector((state) => state.cart.cartItems);
  const showMessage = useSelector((state) => state.verificationPending.showMessage);

  useEffect(() => {
    fetchAllBookColors(dispatch);
    dispatch(fetchUserDetails())
    fetchAllBlogs(dispatch);
    getBlogCategories(dispatch);
    fetchAllVideos(dispatch)
    if (isUserLoggedIn()) {
      fetchAllCartItems(dispatch);
      fetchAllWishlistItems(dispatch);
    } 
    fetchGalleryByType(dispatch);
    fetchFeaturedCollection(dispatch);
    getSuggestionsForNormalBooks(dispatch);
  },[dispatch])

  const syncCartOnLogin = () => {
    const guestCart = JSON.parse(localStorage.getItem("guestCart")) || [];
  
    if (guestCart.length > 0) {
      // Call API to sync cart items
      dispatch(CartActions.addCartItemSuccess([]))
      guestCart.forEach((item) => {
        if(item.book) {
          addCartItem(
            item.book,
            item.quantity,
            item.variantType,
            item.leatherType,
            item.color,
            dispatch
          );
        } else {
          addBestSellerCartItem(
            item?.bestSellerBook,
            item.quantity,
            item.variantType,
            item.leatherType,
            item.color,
            dispatch
          );
        }
      });
  
      // Clear local storage once synced
      localStorage.removeItem("guestCart");
    }
  };

  useEffect(() => {
    if (currentUser?.id) {
      syncCartOnLogin();
    }
  }, [currentUser]);

  useEffect(() => {
    const guestCart = localStorage.getItem("guestCart") || [];
    if(!currentUser?.id && cartItems?.length === 0 && guestCart?.length !== 0) {
        dispatch(CartActions.addCartItemSuccess(JSON.parse(guestCart)))
    }
  },[])
  
  return (
    <div className='App'>
      <WarningDialogue />
      <MyRouter />
      <Loader />
      {showMessage && <VerificationPendingModal />}
    </div>
  );
}

export default App;
