import React, {  } from 'react';
import classes from './ButtonLink.module.css'
import { getClasses } from '../../../Util';

function ButtonLink({className,onClick, title, children, style}) {
  return (
    <button onClick={onClick} style={style} className={getClasses(className,classes.buttonLink)}>
      {children || title}
    </button>
  );
}

export default ButtonLink