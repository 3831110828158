import axios from "axios";
import { store } from "../App/Store/Store";
import { loaderActions } from "../App/Store/Slices/LoaderSlice";
import { warningActions } from "../App/Store/Slices/WarningSlice";

axios.interceptors.request.use(function (request) {
  store.dispatch(loaderActions.fetching());
  return request;
});

axios.interceptors.response.use(
  function (response) {
    store.dispatch(loaderActions.fetched());
    return response;
  },
  function (error) {
    if (error?.response?.status === 401) {
      // window.location.href = urls.LOGIN;
      localStorage.removeItem("isLoggedIn");
    }

    
    let errorMessage;
    if (error?.response?.data === "BAD_CREDENTIALS") {
      errorMessage = "Wrong user ID or password. Please try again.";
    }

    if (error?.response?.data === "USER_ALREADY_EXISTS") {
      errorMessage = "An account with this email already exists.";
    }

    store.dispatch(loaderActions.fetched());
    if (error?.response?.status !== 401) {
       store.dispatch(warningActions.showWarning({ message: errorMessage }));
    }
    return Promise.reject(error);
  }
);

export default axios;
