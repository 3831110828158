import classes from "./Cart.module.css"
import { useSelector } from "react-redux"
import { getEnum, getRandomImageForBestSellerBook, getRandomImageForNormalBook } from "../../../../../Util"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { urls } from "../../../../../Routes/Urls";
import ButtonLink from "../../../../../Components/Buttons/ButtonLink/ButtonLink";
import { useState } from "react";
import Drawer from "../../../../../Components/Drawer/Drawer";
import Customization from "../../Customization";

const Cart = ({cartItems}) => {
    const allColorsData = useSelector(state => state.bookColor.colors);
    const [viewDetails, setViewDetails] = useState({ show: false, details: {} });
    const history = useHistory()

    const handleRedirect = (item) =>{
        item?.bestSellerBook ? 
            history.push({
              pathname: `${urls.BESTSELLERPDP}/${item.bestSellerBook.id}`,
          }) : 
            history.push({
              pathname: `${urls.PRODUCTDETAIL}/${item.book.id}`,
          });
    }
    // const getBookImage = (variant,leather,Itemcolor) => {
    //     const bookImage = allColorsData?.filter(color => color.variantType === variant && color.colorName === Itemcolor && color.leatherType === leather)
    //     return bookImage[0]?.baseImg
    // } 

    return (
        <>
            {viewDetails.show && (
            <Drawer
            name='Customization'
            onClose={() => setViewDetails({ show: false, details: {} })}>
            <Customization details={viewDetails.details} />
            </Drawer>
        )}
            <div className={classes.mainContainer}>
                <div className={classes.itemCount}>
                    <h3>Product ({cartItems?.length})</h3>
                    {/* <p>Add To Wishlist</p> */}
                </div>

                <div className={classes.cartTableWrapper}>
                    <table className={classes.cartTable}>
                        <thead>
                            <tr>
                                <th>Products</th>
                                <th>Quantity</th>
                                <th>Price</th>
                                <th>Customization</th>
                                <th>Sub total</th>
                            </tr>
                        </thead>
                        <tbody>
                            {cartItems && cartItems.map((item) => (
                                <tr key={item.id}>
                                    <td key={item?.id}>
                                        <div key={item?.id} className={classes.product} onClick={!item?.book?.isDeleted || !item?.bestSellerBook?.isDeleted ? () => handleRedirect(item) : () => {}}>
                                            <div className={classes.productImage} >
                                                <img src={item?.book ? item?.customization?.bookThumbnail ? item?.customization?.bookThumbnail :getRandomImageForNormalBook(item.book, allColorsData, item.color, item.leatherType, item.variantType)?.image : getRandomImageForBestSellerBook(item.bestSellerBook, allColorsData, item.color, item.leatherType, item.variantType)?.image} alt="Book" />
                                            </div>
                                            <div className={classes.productDetails}>
                                                <h2>{item?.book?.title || item?.bestSellerBook?.title}</h2>
                                                <p>Edition: {getEnum(item?.variantType)} | Color : {item?.color}</p>
                                            </div>
                                        </div>
                                    </td>
                                    <td className={classes.quantity}>
                                        <div className={classes.qtyController}>
                                            <h2>{item.quantity}</h2>
                                        </div>
                                    </td>
                                    <td className={classes.price}>
                                        <p>${(item.totalAmount / item.quantity)?.toFixed(2)}</p>
                                    </td>
                                    <td className={classes.customization}>
                                        <p>${item?.customizationCharges?.toFixed(2)} </p>
                                        {item?.customizationCharges > 0 && <ButtonLink onClick={() => setViewDetails({ show: true, details: item})}>View</ButtonLink>}
                                    </td>
                                    <td className={classes.subTotal}>
                                        ${item.totalAmount?.toFixed(2)}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}

export default Cart
