import classes from "./SelectEndpaper.module.css";

import Accordion from "../../Components/Accordion/Accordion";

import { useState, useEffect, useContext } from "react";
import useScreenSize from "../../../../Hooks/UseScreenSize";
import OptionCapsules from "../../../../Components/OptionCapsules/OptionCapsules";
import Slider from "react-slick/lib/slider";
import { CustomizationContext } from "../../Customization";
import { getEndPaper } from "../../../../Store/actions/ColorsAction";
import { addImageToCanvas } from "../../../../objectUtils";
import {
  useFabricCanvas,
  useFabricCanvasHandler,
  useFabricEditor,
} from "react-fabric-page";
import PreviewCard from "../../Components/PreviewCard/PreviewCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";


const getBounds = (canvas) => {
  const layer = canvas?.getObjects()?.find((o) => o.id === "background");
  if (layer) {
    return {
      left: layer.left,
      top: layer.top,
      width: layer.width,
      height: layer.height,
    };
  }
  return null;
};

async function setBookEndpaper(endpaper, canvas) {
  // return addImageToEndpaper(canvas,{ filepath: endpaper?.designImage, fileType: "image" }, 1)
  return addImageToCanvas(
    { filepath: endpaper?.designImage, fileType: "image" },
    1,
    () => getBounds(canvas)
  ).then((img) => {
    const layer = canvas?.getObjects()?.find((o) => o?.id === "background");
    if (layer) {
      layer._objects = layer._objects?.filter((o) => o.id !== "endpaper");
      layer.dirty = true;
      layer.add(img);
      img.id = "endpaper";
      img.hasControls = false;
      img.selectable = false;
      img.evented = false;
      img.imageUrl = endpaper?.designImage;
      layer.dirty = true;
      canvas.renderAll();
      // editor.saveCurrentState()
    }
  });
}

function NextArrow(props) {
  const {onClick } = props;
  return (
    <div className={classes.leftArrow} onClick={onClick}>
      <FontAwesomeIcon icon={faAngleRight} />
    </div>
  );
}
function PrevArrow(props) {
  const { onClick } = props;
  return (
    <div className={classes.RightArrow} onClick={onClick}>
      <FontAwesomeIcon icon={faAngleLeft} />
    </div>
  );
}

const SelectEndpaper = ({
  onClick,
  toggleDropdown,
  dropdownOpen,
  currentDropdown,
  isCanvasReady
}) => {
  const {
    selectedEndpaper,
    setSelectedEndpaper,
    allEndpapers
  } = useContext(CustomizationContext);

  const { isMediumScreen } = useScreenSize();
  const canvas = useFabricCanvas();
  const editor = useFabricEditor();

  const settings = {
    slidesToShow: allEndpapers?.length > 1 ? 2.5 : 1,
    swipeToSlide: true,
    infinite: false,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    centerPadding: "10px",
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: false,
          swipeToSlide: true,
        },
      },
      {
        breakpoint: 786,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          swipeToSlide: true,
        },
      },
      {
        breakpoint: 576,
        settings:{
          slidesToShow: 2,
          slidesToScroll: 2,
          swipeToSlide: true,
        }
      },
      {
        breakpoint: 400,
        settings:{
          slidesToShow: 1,
          slidesToScroll: 1,
          swipeToSlide:true,
        }
      }
    ],
  };

  const handleEndpaper = (endpaper) => {
    setSelectedEndpaper(endpaper);
    setBookEndpaper(endpaper, canvas).then(() => editor.saveCurrentState());
  };

  useEffect(() => {
    if(!selectedEndpaper?.designName) {
      setSelectedEndpaper(allEndpapers?.[0])
      setBookEndpaper(allEndpapers?.[0], canvas).then(() => editor?.saveCurrentState());
    }
  },[allEndpapers,isCanvasReady,editor])

  useFabricCanvasHandler(
    {
      "canvas:x-ready": () => {
        const layer = canvas?.getObjects()?.find((o) => o?.id === "background");
        if (layer) {
          const endpaperObj = layer._objects?.find((o) => o.id === "endpaper");
          const selectedPaper = allEndpapers?.find(
            (endpaper) => endpaper?.designImage === endpaperObj?.imageUrl
          );
          if (selectedPaper) {
            setSelectedEndpaper(selectedPaper);
            const contentsLayer = canvas
              ?.getObjects()
              ?.find((o) => o?.id === "contents");
            const taggedImageLayer = canvas
              ?.getObjects()
              ?.find((o) => o?.id === "taggedImages");
            if (
              (contentsLayer && contentsLayer?._objects) ||
              (taggedImageLayer && taggedImageLayer?._objects)
            ) {
              contentsLayer._objects.forEach((object) => {
                object.visible = false;
                object.dirty = true;
              });
              contentsLayer.dirty = true;
              taggedImageLayer._objects.forEach((object) => {
                object.visible = false;
                object.dirty = true;
              });
              taggedImageLayer.dirty = true;
            }
          } else {
            setSelectedEndpaper(null);
          }
        }
      },
    },
    [allEndpapers]
  );

  return (
    <div>
      {isMediumScreen ? (
        <div
          className={classes.selectColor}
          onClick={(e) => e.stopPropagation()}
        >
          <h2>Choose your endpaper</h2>

            <div>
              <div className={classes.previewContainer}>
                <Slider {...settings}>
                  {allEndpapers?.map((endpaper) => (
                    <PreviewCard
                      key={endpaper?.designName}
                      handleClick={() => handleEndpaper(endpaper)}
                      image={endpaper?.designImage}
                      isActive={
                        selectedEndpaper?.designName === endpaper?.designName
                      }
                      className={"endpapercard"}
                    />
                  ))}
                </Slider>
              </div>
            </div>
        </div>
      ) : (
        <div className={classes.mainContainer}>
          <Accordion
            dropdownName={"Endpaper"}
            onClick={onClick}
            toggleDropdown={toggleDropdown}
            dropdownOpen={dropdownOpen}
            toolText={"Select the endpaper design"}
            currentDropdown={currentDropdown}
            title={"Endpaper"}
          />

          {dropdownOpen && currentDropdown === "Endpaper" && (
            <div
              className={classes.selectColor}
              onClick={(e) => e.stopPropagation()}
            >
              <h2>Choose your endpaper</h2>

                <div>
                  <div className={classes.previewContainer}>
                    <Slider {...settings}>
                      {allEndpapers?.map((endpaper) => (
                        // <div
                        //   key={endpaper?.designName}
                        //   className={`${classes.previewCard} ${
                        //     selectedEndpaper?.designName ===
                        //     endpaper?.designName
                        //       ? classes.activePreviewCard
                        //       : ""
                        //   }`}
                        //   onClick={() => handleEndpaper(endpaper)}
                        // >
                        //   <img src={endpaper?.designImage} alt="" />
                        // </div>
                        <PreviewCard
                          key={endpaper?.designName}
                          handleClick={() => handleEndpaper(endpaper)}
                          image={endpaper?.designImage}
                          isActive={
                            selectedEndpaper?.designName ===
                            endpaper?.designName
                          }
                          className={"endpapercard"}
                        />
                      ))}
                    </Slider>
                  </div>
                </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default SelectEndpaper;
