import React, { useRef, useState,useEffect } from "react";
import { getClasses } from "../../../Util";
import classes from "./PrimaryNav.module.css";
import NavDropdown from "../NavDropdown/NavDropdown";
import useScreenSize from "../../../Hooks/UseScreenSize";
import ItemsNav from "../ItemsNav/ItemsNav";
import CollapsedItems from "./CollapsedItems";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import SecondaryButton from "../../../Components/Buttons/SecondaryButton/SecondaryButton";
import { fetchBook,fetchBookCount } from "../../../Store/actions/BookAction";
import { fetchBook as fetchBestSellerBook } from "../../../Store/actions/BestSellerBookAction";
import { urls } from "../../../Routes/Urls";
import MiniCart from "../../MyCart/MiniCart/MiniCart";
import useClickOutSide from "../../../Hooks/useClickOutSide";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearchengin } from "@fortawesome/free-brands-svg-icons";
const personIcon = "/Assets/Icons/person-icon.svg";
const CloseIcon = "/Assets/Icons/icon-x-grey.svg";
const menuIcon = "/Assets/Icons/icon-bars.svg";
const logoImg = "/Assets/Icons/logo.jpg";
const searchIcon = "/Assets/Icons/icon-search.svg";
const cartIcon = "/Assets/Icons/shopping_basket.png";


function PrimaryNav() {
  const { isLargeScreen,isMediumScreen } = useScreenSize();
  const [isNavBarActive, setIsNavbarActive] = useState(false);
  const [query,setQuery] = useState("");

  const [searchRecommendations,setSearchRecommedations] = useState([]);
  const [allRecommendations,setAllRecommendations] = useState([])
  const [recommendationClicked,setRecommendationClicked] = useState(false);
  const [isBestSellerRecommendation,setIsBestSellerRecommendation] = useState(false);
  const [focus,setFocus] = useState(false);
  const [blurTimeout, setBlurTimeout] = useState(null);

  const searchCriteria = "all"

  const navRef = useRef();

  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.user.currentUser);
  const cartItems = useSelector((state) => state.cart.cartItems);
  const user = useSelector((state) => state.user.currentUser)

  const history = useHistory();
  const location  = useLocation();

  const isSearchVisibile = (location.pathname !== urls.HOME && location.pathname !== urls.LISTING && location.pathname !== urls.BESTSELLER) || isMediumScreen;


  const handleSearch = async (e) => {
    if(e?.key === "Enter" || e?.type === "click"){
      if (query === "") return;

      const searchCriteria = "all";
  
      // const searchResults = await fetchBook(searchCriteria, query, dispatch);
      // const searchCount = await fetchBookCount(searchCriteria, query);
  
      history.push({
          pathname: urls.LISTING,
          state: { 
              // searchResults, 
              // searchCount,
              searchCriteria,
              query
          }
      });
    }
  };

  const loginRedirect = () => {
    history.push(history.location?.state?.from || "/login");
  };

  const handleClickOutside = () => {
    setIsNavbarActive(false)
  }

  useClickOutSide({ myRef: navRef, isActive:isNavBarActive, onClick: handleClickOutside});

  const handleRecommendationClick = (recommendation) => {
    const words = query.trim().split(" ");
    if (words.length > 1) {
      const sanitizedText = recommendation?.title?.replace(/[\(\)\{\}\[\]]/g, ""); // Remove disallowed characters
      words[words.length - 1] = sanitizedText; // Replace the last word with sanitized recommendation title
      setQuery(words.join(" "));
    } else {
      const sanitizedText = recommendation?.title?.replace(/[\(\)\{\}\[\]]/g, ""); // Remove disallowed characters
      setQuery(sanitizedText);
    }
    setIsBestSellerRecommendation(recommendation?.isBestSeller);
    setSearchRecommedations([]);
    setRecommendationClicked(true);
  };
    
        useEffect(() => {
          if(recommendationClicked) {
            if(!isBestSellerRecommendation) {
              history.push({
                pathname: urls.LISTING,
                state: { 
                    // searchResults, 
                    // searchCount,
                    searchCriteria,
                    query
                }
            });
            } else {
                history.push({
                  pathname: urls.BESTSELLER,
                  state: {  
                      query
                  }
              });
              setIsBestSellerRecommendation(false)
            }
            setRecommendationClicked(false);
          }
        },[recommendationClicked])
    
    useEffect(() => {
      if (query) {
        const timeout = setTimeout(() => {
          fetchPageData();
        },500);
      
         return () => clearTimeout(timeout);
      }
    }, [query,searchCriteria]);
  
    useEffect(() => {
          if (query) {
            const words = query.trim().split(/\s+/);
            setSearchRecommedations(
              allRecommendations
            );
          } else {
            setSearchRecommedations([]);
          }
      }, [query, allRecommendations]);
    
      const handleFocus = () => {
        if (blurTimeout) {
          clearTimeout(blurTimeout); 
        }
        setFocus(true);
      };
      
      const handleBlur = () => {
        const timeout = setTimeout(() => setFocus(false),1000);
        setBlurTimeout(timeout);
      };
    
  
    const getFilter = () => {
      let filter = {
        limit: 5,
        offset: 0,
        where: {
          $and: [],
        },
      };

      if (query) {
        if (searchCriteria === "all") {
          filter.where.$and.push({
            $or: [
              { author: `*${query}*` },
              { title: `*${query}*` },
              { language: `*${query}*` },
              { sku: `*${query}*` },
              { isbnNo: `*${query}*` },
            ],
          });
        } else {
          filter.where.$and.push({
            $or: [{ [searchCriteria]: `*${query}*` }],
          });
        }
      }

      return filter;
    };

    const fetchPageData = async () => {
      if (query !== "") {
        const filter = getFilter();
        const dataBestSeller = await fetchBestSellerBook(null, filter);

        const data = await fetchBook(searchCriteria, query, null, 5, 1, null);

        const filteredBestSellerRecommendations = dataBestSeller?.data
          .map((book) => {
            if (searchCriteria !== "sku" && searchCriteria !== "isbnNo") {
              if (book?.author?.toLowerCase().includes(query.toLowerCase())) {
                return { title: book.author, isBestSeller: true };
              } else if (
                book?.title?.toLowerCase().includes(query.toLowerCase())
              ) {
                return { title: book.title, isBestSeller: true };
              }
            } else if (book?.sku === query.toLowerCase()) {
              return { title: book.sku, isBestSeller: true };
            } else if (book?.isbnNo === query.toLowerCase()) {
              return { title: book.isbnNo, isBestSeller: true };
            }

            return null;
          })
          .filter((item) => item !== null); // Remove null values

        const filteredRecommendations = data.data
          .map((book) => {
            if (searchCriteria !== "sku" && searchCriteria !== "isbnNo") {
              if (book?.author?.toLowerCase().includes(query.toLowerCase())) {
                return { title: book.author, isBestSeller: false };
              } else if (
                book?.title?.toLowerCase().includes(query.toLowerCase())
              ) {
                return { title: book.title, isBestSeller: false };
              }
            } else if (book?.sku === query.toLowerCase()) {
              return { title: book.sku, isBestSeller: false };
            } else if (book?.isbnNo === query.toLowerCase()) {
              return { title: book.isbnNo, isBestSeller: false };
            }
            return null;
          })
          .filter((item) => item !== null); // Remove null values

        const allFilteredRecommendations =
          filteredBestSellerRecommendations?.concat(filteredRecommendations);

        const uniqueRecommendations = Array.from(
          new Map(
            allFilteredRecommendations.map((item) => [item.title, item])
          ).values()
        );

        setAllRecommendations(uniqueRecommendations);
      }
    };

  return (
    <nav className={classes.main}>
      <div className={classes.container}>
        <div className={classes.brand}>
          <div className={classes.brandLogo} onClick={() => history.push("/")}>
            <img src={logoImg} alt="logo" />
          </div>
        </div>

        <div className={classes.nav}>
          <ul className={classes.navItems}>
            {!isLargeScreen && <ItemsNav />}

            {isSearchVisibile && (
              <li key={"searchBar"}>
                <div className={classes.searchBar}>
                  <div
                    className={classes.navSearchIcon}
                    onClick={(e) => handleSearch(e)}
                  >
                    <img src={searchIcon} alt="Search" />
                  </div>
                  <input
                    type="text"
                    placeholder="Search..."
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                    className={classes.searchInput}
                    onKeyDown={(e) => handleSearch(e)}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                  />
                </div>

                {searchRecommendations.length > 0 && focus && (
                  <div className={classes.dropdown}>
                    {searchRecommendations
                      .slice(0, 10)
                      .map((recommendation, index) => (
                        <div
                          key={index}
                          className={classes.dropdownItem}
                          onClick={() =>
                            handleRecommendationClick(recommendation)
                          }
                        >
                          <FontAwesomeIcon icon={faSearchengin} />
                          {recommendation?.title}
                        </div>
                      ))}
                  </div>
                )}
              </li>
            )}

            {!isLargeScreen && <CollapsedItems />}
            {!isLargeScreen && currentUser.userId ? (
              <li key={"userAccount"}>
                <NavDropdown
                  options={[{ label: "My Account" }, { label: "Logout" }]}
                >
                  <div className={classes.userImage}>
                    <img src={currentUser.photoURL || personIcon} alt="" />
                  </div>
                </NavDropdown>
              </li>
            ) : (
              !isLargeScreen && (
                <li key={"login/signUp"}>
                  <SecondaryButton
                    className={classes.navItemBtn}
                    onClick={loginRedirect}
                  >
                    Login/ SignUp
                  </SecondaryButton>
                </li>
              )
            )}
            {isLargeScreen && (
              <li key="cartBtn">
                <div
                  className={`${classes.navItem} ${classes.cartBtnSmall}`}
                  onClick={() => history.push(urls.CART)}
                >
                  <img src={cartIcon} alt="" />
                  {cartItems?.length > 0 ? (
                    <div
                      className={
                        user?.id ? classes.cartCount : classes.countLogedOut
                      }
                    >
                      {cartItems?.length}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </li>
            )}
            {isLargeScreen && (
              <li key="hamburger">
                <div
                  className={`${classes.navItem} ${classes.sideBar}`}
                  onClick={() => setIsNavbarActive(true)}
                >
                  <img src={menuIcon} alt="menu" />
                </div>
              </li>
            )}
          </ul>

          {isLargeScreen && (
            <div
              ref={navRef}
              className={getClasses(
                classes.offCanvas,
                isNavBarActive ? classes.activeOffCanvas : ""
              )}
            >
              <div className={classes.offCanvasContent}>
                <ItemsNav />
                <ul className={classes.collapsedItems}>
                  <CollapsedItems />
                </ul>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: "1rem 0.5rem 0 0.5rem",
                  }}
                >
                  <div className={classes.userProfile}>
                    {currentUser.fullName ? (
                      <NavDropdown
                        options={[{ label: "My Account" }, { label: "Logout" }]}
                      >
                        <div className={classes.userImage}>
                          <img
                            src={currentUser.photoURL || personIcon}
                            alt=""
                          />
                        </div>
                        <div className={classes.userName}>
                          {currentUser.fullName}
                        </div>
                      </NavDropdown>
                    ) : (
                      <SecondaryButton
                        className={classes.navItemBtnSmall}
                        onClick={loginRedirect}
                      >
                        Login/SignUp
                      </SecondaryButton>
                    )}
                  </div>

                  <div
                    className={classes.navItem}
                    onClick={() => setIsNavbarActive(false)}
                  >
                    <img src={CloseIcon} alt="" />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <MiniCart />
    </nav>
  );
}

export default PrimaryNav;
