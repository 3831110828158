import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import classes from "./ListingBooks.module.css";
import Card from "../Card/Card2";
import { getRandomImageForNormalBook } from "../../../Util";
import axios from "axios";
import { SERVICE_URL } from "../../../Store/ServiceUrls";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import NextArrow from "../../../Components/Arrows/Arrows";
import {PrevArrow}  from "../../../Components/Arrows/Arrows";

const ListingBooks = () => {
  const allColorsData = useSelector((state) => state.bookColor.colors);

  const [bookData,setBookData] = useState([])

  useEffect(() => {
    const filter = {
        sortBy:"lastUpdated DESC",
        limit:20
    }
    axios({
        url: `${SERVICE_URL.BOOK}?filter=${encodeURIComponent(
          JSON.stringify(filter)
        )}`,
        method: "get",
        responseType: "json",
      }).then((res) => {
        if(res.data.success) {
            const booksData = res.data.data;
            const addRandomImagesToBookData = booksData.map((data) =>  ({...data, imageUrl: getRandomImageForNormalBook(data, allColorsData )?.image }));
            setBookData(addRandomImagesToBookData)
        }
      })
  },[allColorsData])



  const wishlist = useSelector((state) => state.wishlist.wishlistItems);
  
  const [currentIndex, setCurrentIndex] = useState(0);
  const [cardsToShow, setCardsToShow] = useState(4);
  const [disableHover, setDisableHover] = useState(false);

  const totalPages = Math.ceil(bookData.length / cardsToShow);

  const handleResize = () => {
    const width = window.innerWidth;
    if (width > 1200) setCardsToShow(4);
    else if (width > 1024) setCardsToShow(3);
    else if (width <= 768) setCardsToShow(2);
    else setCardsToShow(2);
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const nextSlide = () => {
    if (currentIndex < totalPages - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const prevSlide = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };
  const startIndex = currentIndex * cardsToShow;
  const visibleBooks = bookData?.slice(startIndex, startIndex + cardsToShow);

  const handleMouseEnter = () => setDisableHover(true);
  const handleMouseLeave = () => setDisableHover(false);

  const isWishListItem = (bookData) => {
    const bookOpt = wishlist?.find((item) => item?.book?.sku === bookData.sku && item?.book?.title === bookData?.title)
    if(bookOpt) {
      return {isPresent : true, data: bookOpt}
    } else {
      return {isPresent : false, data:null}
    }
  }

  return (
    <div className={classes.mainContainer}>
      <h2>Our Library</h2>

      <div className={classes.carouselContainer}>
        {/* <button
          className={}
          onClick={prevSlide}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          disabled={currentIndex === 0}
        >
          <FontAwesomeIcon icon={faAngleLeft} />
        </button> */}

        <PrevArrow className={`${classes.arrow} ${classes.prev}`} onClick={prevSlide}/>

        <div
          className={`${classes.carousel} ${
            disableHover ? classes.disableHover : ""
          }`}
        >
          {visibleBooks?.map((book, index) => (
            <Card
              key={index}
              bookData={book}
              image={book?.imageUrl}
              isBestSeller={false}
              price={
                getRandomImageForNormalBook(book, allColorsData)?.variantType ===
                "deluxe"
                  ? book.deluxePriceGenuine
                  : book.superDeluxePriceGenuine
              }
              altText={getRandomImageForNormalBook(book, allColorsData)?.altText}
              isWishListItem={isWishListItem(book)?.isPresent}
              wishlistItemId={isWishListItem(book)?.data?.id}
            />
          ))}
        </div>
{/* 
        <button
          className={`${classes.arrow} ${classes.next}`}
          onClick={nextSlide}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          disabled={currentIndex >= totalPages - 1}
        >
          <FontAwesomeIcon icon={faAngleRight} />
        </button> */}

        <NextArrow onClick={nextSlide} className={`${classes.arrow} ${classes.next}`} />
      </div>
    </div>
  );
};

export default ListingBooks;