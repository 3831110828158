import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import classes from "./AboutForPublishers.module.css"
import { urls } from "../../../../Routes/Urls"
import ButtonLink from "../../../../Components/Buttons/ButtonLink/ButtonLink"

const image1 = "/Assets/Images/FeatureIcons/ForPublishersF1.png"
const image2 = "/Assets/Images/FeatureIcons/ForPublishersF2.png"
const image3 = "/Assets/Images/FeatureIcons/ForPublishersF3.png"
const image4 = "/Assets/Images/FeatureIcons/ForPublishersF4.png"
const image5 = "/Assets/Images/FeatureIcons/ForPublishersF5.png"



const AboutForPublishers = () => {
    const history = useHistory()
    const cardData = [
        {
            title:"Higher-monetary benefits",
            desc:"As a publisher, you can offer your premium authors an exclusive service: launching a stunning leather-bound edition of their book. This special edition not only elevates the book's prestige but also creates an opportunity to generate additional revenue and royalties, benefiting both you and your authors.",
            image:image1
        },
        {
            title:"Exclusive Limited Editions",
            desc:"Collaborate with us to create exclusive, limited-edition leather-bound books that capitalize on scarcity and drive higher value through collector demand. \n \n These editions can feature bespoke, customized covers and include signed copies, appealing to a broader and more premium audience.",
            image:image2
        },
        {
            title:"Handcrafted craftsmanship and customizable options",
            desc:"Every book that is made benefits from the years of experience and commitment of our talented artisans. To guarantee that every book is a work of art, we only utilize the best supplies and traditional methods that are purely hand-stitched with exquisite leather.",
            image:image3,
            CTA:"Click here to learn more about our customization feature.",
            onClick:() => history.push(urls.SERVICES)
    
        },
        {
            title:"Dedicated Assistance",
            desc:"As part of our collaboration, your team will have access to a dedicated account manager who will work directly with you to answer any problems, speed up the process, and guarantee that everything runs smoothly from beginning to end.",
            image:image4
        },
        {
            title:"Variable Volume Selections",
            desc:"We provide various buying choices to fit your demands without putting you under pressure from big minimum orders, whether you need a single special edition book or a batch of volumes bound to order.",
            image:image5
        }
    ]
    return(
        <div className={classes.mainContainer}>
            <div className={classes.headerSection}>
                <h2>
                Benefits Of Joining Hands With Us?
                </h2>
                {/* <p>Our mission is to encapsulate your academic journey in the timeless beauty of leather, ensuring that your thesis remains a testament to your dedication and intellect.</p> */}
            </div>

            <div className={classes.cardSection}>
                {cardData.map((card,index) => (
                    <div key={index} className={classes.card}>
                        <div className={classes.image}>
                            <img src={card.image} alt="" />
                        </div>
                        <div className={classes.title}>
                            <h2>{card.title}</h2>
                            <p>{card.desc?.split("\n").map((line,index) => (
                               <span key={index}>
                               {line}
                               <br />
                             </span>
                            ))}</p>
                        </div>
                        {card.CTA && <div className={classes.cta}>
                            <ButtonLink className={classes.linkBtn} onClick={card.onClick}>{card.CTA}</ButtonLink>
                        </div>}
                    </div>
                ))}
            </div>


        </div>
    )
}

export default AboutForPublishers