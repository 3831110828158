import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classes from "./ColorPicker.module.css" 
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons"

const ColorPickerSmall = ({frontCoverColor,allowedColors,handleColorChange}) => {
    return (
        <div className={`${classes.colorPickerDropdown} ${classes.open}`} onClick={(e) => e.stopPropagation()}>
        <h3>Choose your preferred leather color</h3>

        <div className={classes.selectColor}>
            <h2>Color : {frontCoverColor?.colorName}</h2>
            <div className={classes.colors}>
                {allowedColors?.map((color, index) => (
                    frontCoverColor?.colorValue === color?.colorValue ? (
                        <div key={index} className={classes.selected}>
                            <div className={classes.active} style={{ background: color?.colorValue }} >
                            </div>
                        </div>
                    ) : (

                        <div key={index} className={classes.colorPicker} style={{ background: color?.colorValue }} onClick={(e) => handleColorChange(color,e)}> </div>
                    )
                ))}
            </div>
            {/* <p className={classes.disclaimer}>Note: Back Cover will be same as the Front Cover</p> */}
        </div>

        <div className={classes.info}>
            <p >
              <FontAwesomeIcon icon={faCircleInfo} />Note: Back Cover will be same as the Front Cover
            </p>
        </div>
    </div>
    )
}

export default ColorPickerSmall