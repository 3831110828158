import classes from "./OuterBorder.module.css";
import { useState, useEffect, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleUp,
  faAngleDown,
  faCircleXmark,
  faPlusCircle,
} from "@fortawesome/free-solid-svg-icons";
import OptionCapsules from "../../../../Components/OptionCapsules/OptionCapsules";
import { useFabricCanvas } from "react-fabric-page";
import useScreenSize from "../../../../Hooks/UseScreenSize";
import { addImageToCanvas } from "../../../../objectUtils";
import Slider from "react-slick";
import { CustomizationContext } from "../../Customization";
import ColorSelector from "../../Components/ColorSelector/ColorSelector";
import { getBorderDesigns } from "../../../../Store/actions/ColorsAction";
import { fabric } from "react-fabric-page";
import { useSelector } from "react-redux";
import ToolTip from "../../../../Components/ToolTip/ToolTip";

const outerBorderTabs = [
  {
    title: "None",
    value: "none",
  },
  {
    title: "Embossing",
    value: "embossing",
  },
  {
    title: "Foiling",
    value: "foiling",
  },
];

const getBounds = (canvas) => {
    const layer = canvas
      ?.getObjects()
      ?.find((o) => o.id === "border-image-area");
    if (layer) {
      return {
        left: layer.left,
        top: layer.top,
        width: layer.width,
        height: layer.height,
      };
    }
    return null;
  };

  const changeCoverBorder = (border,canvas,bookSize) => {
    let imageUrl = "";
    switch (bookSize) {
      case "F1":
        imageUrl = border?.designImage1;
        break;
      case "F2":
        imageUrl = border?.designImage2;
        break;
      case "F3":
        imageUrl = border?.designImage3;
        break;
      case "F4":
        imageUrl = border?.designImage4;
        break;
      default:
        break;
    }
    return addImageToCanvas(
      { filepath: imageUrl, fileType: "image" },
      1,
      () => getBounds(canvas),
      false,false,false
    )
      .then((img) => {
        const layer = canvas
          ?.getObjects()
          ?.find((o) => o?.id === "border-image-area");
        if (layer) {
            layer._objects = layer._objects?.filter(o => o.id !== "outerBorder")
          layer.add(img);
          img.hasControls = false;
          img.id = "outerBorder";
          layer.dirty = true;
          canvas.renderAll();
        }
      })
      .catch((error) => {
        
      });
  };

const OuterBorder = ({
  onClick,
  toggleDropdown,
  dropdownOpen,
  currentDropdown,
}) => {
  const {
    bookSize,
    selectedOuterBorder,
    setSelectedOuterBorder,
    selectedOuterBorderType,
    setSelectedOuterBorderType,
    bookVariant,
    outerBorderFoilingColor,
    setOuterBorderFoilingColor,
    userOuterBorder,
    setUserOuterBorder,
    selectedDesigns,
    allFoilingColors
  } = useContext(CustomizationContext);

  const customizationPricing = useSelector((state) => state.customizationPricing.prices?.[0]);
  const { isMediumScreen } = useScreenSize();
  const [allBorderDesigns, setAllBorderDesigns] = useState([]);
  const [borderDesigns, setBorderDesigns] = useState([]);

  const canvas = useFabricCanvas();

  const settings = {
    slidesToShow: borderDesigns?.length > 1 ? 2.5 : 1,
    swipeToSlide: true,
    infinite: false,
    centerPadding: "10px",
  };

  const handleFileSelect = (event) => {
    const file = event.target.files[0]; // Get the first selected file
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const base64Data = event.target.result; 
        if(base64Data) {
          setUserOuterBorder(base64Data); // Set the file to state
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const handleClick = () => {
    document.getElementById("fileInput").click();
  };

  const handleClickAndSelect = () => {
    onClick();
    toggleDropdown(!dropdownOpen);
  };

  const fetchBorderDesigns = async () => {
    try {
      const res = await getBorderDesigns({});
      if (res.status === 200) {
        setAllBorderDesigns(res.data.data);
      }
    } catch (err) {}
  };

  useEffect(() => {
    fetchBorderDesigns();
  }, []);

  useEffect(() => {
    const allowedBorders = allBorderDesigns?.filter(
      (border) =>
        border.borderType === "outer" &&
        border.borderStyle === selectedOuterBorderType &&
        border.variantType === bookVariant &&
        border.disabled === false
    );
    setBorderDesigns(allowedBorders);
  }, [selectedOuterBorderType, allBorderDesigns, bookVariant]);

  const handleChangeBorder = (border) => {
    if (selectedOuterBorder?.paletteImage !== border?.paletteImage) {
      (async function(params) {
        await changeCoverBorder(border, canvas, bookSize);
        setSelectedOuterBorder(border);
      })()
    }
  };

  const handleColorChange = (color, e) => {
    e.stopPropagation();
    setOuterBorderFoilingColor(color);
  };

  const changeFoilingColor = () => {
    if (canvas) {
      const layer = canvas?.getObjects()?.find((o) => o.id === "border-image-area");
      if (layer) {
        const activeObject = layer?._objects?.filter(
          (o) => o.id === "outerBorder"
        )?.[0];
        if (activeObject && outerBorderFoilingColor) {
          activeObject.filters[0] = new fabric.Image.filters.PatternColor({
            color: outerBorderFoilingColor?.colorValue,
            convert: true,
          });
          activeObject.applyFilters();
          layer.dirty = true;
        }
      }
      canvas.renderAll();
    }
  };

  useEffect(() => {
    if (selectedOuterBorderType === "foiling") {
      changeFoilingColor();
    }
  }, [outerBorderFoilingColor, selectedOuterBorderType, selectedOuterBorder]);

  useEffect(() => {
    if (selectedOuterBorderType === "none") {
      setSelectedOuterBorder(null);
      setSelectedOuterBorderType("none");
      setOuterBorderFoilingColor(null);
      const layer = canvas?.getObjects()?.find((o) => o.id === "border-image-area");
      if (layer) {
        layer._objects = layer._objects.filter((o) => o.id !== "outerBorder");
        layer.dirty = true;
        canvas.renderAll();
      }
    } else {
      if(selectedDesigns?.customization?.outerBorder?.designId) {
        const selectedDesign = allBorderDesigns?.find((border) => border.id === selectedDesigns?.customization?.outerBorder?.designId);
        if(selectedDesign) {
          (async function (params) {
            if (borderDesigns.length > 0) {
              await changeCoverBorder(selectedDesign, canvas, bookSize);
              setSelectedOuterBorder(selectedDesign);
            }
    
            if (selectedOuterBorderType === "foiling" && allFoilingColors.length > 0) {
              if(selectedDesigns?.customization?.outerBorder?.foilingColor) {
                const selectedFoilColor = allFoilingColors?.find((color) => color?.colorName === selectedDesigns?.customization?.outerBorder?.foilingColor) 
                if(selectedFoilColor) {
                  setOuterBorderFoilingColor(selectedFoilColor);
                  changeFoilingColor();
                } else {
                  setOuterBorderFoilingColor(allFoilingColors[0]);
                  changeFoilingColor();
                }
              }

            }
          })();
        } else {
          (async function (params) {
            if (borderDesigns.length > 0 && !selectedOuterBorder?.paletteImage) {
              await changeCoverBorder(borderDesigns[0], canvas, bookSize);
              setSelectedOuterBorder(borderDesigns[0]);
            }
    
            if (selectedOuterBorderType === "foiling" && allFoilingColors.length > 0) {
              setOuterBorderFoilingColor(allFoilingColors[0]);
              changeFoilingColor();
            }
          })();
        }
      } else if(!selectedOuterBorder?.paletteImage) {
        (async function (params) {
          if (borderDesigns.length > 0) {
            await changeCoverBorder(borderDesigns[0], canvas, bookSize);
            setSelectedOuterBorder(borderDesigns[0]);
          }
  
          if (selectedOuterBorderType === "foiling" && allFoilingColors.length > 0) {
            setOuterBorderFoilingColor(allFoilingColors[0]);
            changeFoilingColor();
          }
        })();
      }
    }
  }, [selectedOuterBorderType, borderDesigns, allFoilingColors,selectedDesigns]);

  useEffect(() => {
    if (selectedOuterBorderType === "foiling" && allFoilingColors?.length > 0) {
      setOuterBorderFoilingColor(allFoilingColors[0]);
      changeFoilingColor(allFoilingColors[0]);
    }
  }, [selectedOuterBorderType, allFoilingColors]);


  useEffect(() => {
    if(selectedDesigns?.customization?.outerBorder?.style) {
      setSelectedOuterBorderType(selectedDesigns?.customization?.outerBorder?.style)
    }
  },[selectedDesigns,allBorderDesigns])


  return (
    <div>
      {isMediumScreen ? (
            <div onClick={(e) => e.stopPropagation()}>
            <div className={classes.outerBorderTypes}>
              <div className={classes.outerBorderTypePreview}>
                <div className={classes.outerBorderType}>
                  {outerBorderTabs.map((option) => (
                    <OptionCapsules
                      key={option?.title}
                      option={option?.title}
                      active={selectedOuterBorderType === option.value}
                      onClick={() => setSelectedOuterBorderType(option.value)}
                    />
                  ))}
                </div>

                {selectedOuterBorderType !== "none" && (
                  <div className={classes.previewContainer}>
                    <Slider {...settings}>
                      {borderDesigns?.map((border) => (
                        <div
                          key={border?.designName}
                          className={`${classes.previewCard} ${
                            selectedOuterBorder?.designName ===
                            border?.designName
                              ? classes.activePreviewCard
                              : ""
                          }`}
                          onClick={() => handleChangeBorder(border)}
                        >
                          <img src={border?.paletteImage} alt="" />
                        </div>
                      ))}
                    </Slider>
                  </div>
                )}

                {selectedOuterBorderType === "foiling" && (
                  <div className={classes.foilingColorSection}>
                    <h2>Select Foiling Color</h2>
                    <ColorSelector
                      availableColors={allFoilingColors}
                      selectedColor={outerBorderFoilingColor}
                      handleColorChange={handleColorChange}
                    />
                  </div>
                )}
              </div>

              <div className={classes.uploadBtn} onClick={handleClick}>
                <div className={classes.addLogo}>
                  <FontAwesomeIcon
                    icon={faPlusCircle}
                    className={classes.addLogoIcon}
                  />
                  <p>Add your own design</p>
                </div>
                <div className={classes.logoPrice}>+${customizationPricing?.deluxeOuterBorderPrice}</div>
              </div>

              {/* Hidden file input to select an image */}
              <input
                type="file"
                id="fileInput"
                accept="image/*"
                style={{ display: "none" }}
                onChange={handleFileSelect} // Trigger on file selection
              />
              {/* Optional: Image preview (if image is selected) */}
              {userOuterBorder && (
                <div className={classes.imagePreview}>
                  <img
                    src={userOuterBorder} // Convert file to URL for preview
                    alt="Selected Design"
                  />
                  <FontAwesomeIcon
                    className={classes.deleteImage}
                    icon={faCircleXmark}
                    onClick={() => setUserOuterBorder(null)}
                  />
                </div>
              )}
              <p className={classes.disclaimer}>
                Disclaimer : uploaded design won’t be available for live
                preview
              </p>
            </div>
          </div>
      ) : (
        <div
          className={classes.outerBorder}
          onClick={() => handleClickAndSelect()}
        >
          <div className={classes.colorHeader}>
          <div className={classes.headerText}>
            <h2>Outer Border</h2>
            <ToolTip tooltipText={"Customize Outer Border for front cover"}/>
          </div>
            <div className={classes.dropDownImage} onClick={toggleDropdown}>
              {dropdownOpen && currentDropdown === "Outer Border" ? (
                <FontAwesomeIcon icon={faAngleUp} />
              ) : (
                <FontAwesomeIcon icon={faAngleDown} />
              )}
            </div>
          </div>

          {dropdownOpen && currentDropdown === "Outer Border" && (
            <div onClick={(e) => e.stopPropagation()}>
              <div className={classes.outerBorderTypes}>
                <div className={classes.outerBorderTypePreview}>
                  <div className={classes.outerBorderType}>
                    {outerBorderTabs.map((option) => (
                      <OptionCapsules
                        key={option.title}
                        option={option.title}
                        active={selectedOuterBorderType === option.value}
                        onClick={() => setSelectedOuterBorderType(option.value)}
                      />
                    ))}
                  </div>

                  {selectedOuterBorderType !== "none" && (
                    <div className={classes.previewContainer}>
                      <Slider {...settings}>
                        {borderDesigns?.map((border) => (
                          <div
                            key={border?.designName}
                            className={`${classes.previewCard} ${
                              selectedOuterBorder?.designName ===
                              border?.designName
                                ? classes.activePreviewCard
                                : ""
                            }`}
                            onClick={() => handleChangeBorder(border)}
                          >
                            <img src={border?.paletteImage} alt="" />
                          </div>
                        ))}
                      </Slider>
                    </div>
                  )}

                  {selectedOuterBorderType === "foiling" && (
                    <div className={classes.foilingColorSection}>
                      <h2>Select Foiling Color</h2>
                      <ColorSelector
                        availableColors={allFoilingColors}
                        selectedColor={outerBorderFoilingColor}
                        handleColorChange={handleColorChange}
                      />
                    </div>
                  )}
                </div>

                <div className={classes.uploadBtn} onClick={handleClick}>
                  <div className={classes.addLogo}>
                    <FontAwesomeIcon
                      icon={faPlusCircle}
                      className={classes.addLogoIcon}
                    />
                    <p>Add your own design</p>
                  </div>
                  <div className={classes.logoPrice}>+${customizationPricing?.deluxeOuterBorderPrice}</div>
                </div>

                {/* Hidden file input to select an image */}
                <input
                  type="file"
                  id="fileInput"
                  accept="image/*"
                  style={{ display: "none" }}
                  onChange={handleFileSelect} // Trigger on file selection
                />
                {/* Optional: Image preview (if image is selected) */}
                {userOuterBorder && (
                  <div className={classes.imagePreview}>
                    <img
                      src={userOuterBorder} // Convert file to URL for preview
                      alt="Selected Design"
                    />
                    <FontAwesomeIcon
                      className={classes.deleteImage}
                      icon={faCircleXmark}
                      onClick={() => setUserOuterBorder(null)}
                    />
                  </div>
                )}
                <p className={classes.disclaimer}>
                  Disclaimer : uploaded design won’t be available for live
                  preview
                </p>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default OuterBorder;
