import {useRef, useState } from "react";
import classes from "./Sidebar.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Dropdown from "../../ListingPage/Sidebar/Dropdown/Dropdown"
import {
  faBars,
} from "@fortawesome/free-solid-svg-icons";
import Slider from "../../ListingPage/Sidebar/Slider/Slider";
import useClickOutSide from "../../../Hooks/useClickOutSide";

const Sidebar = ({allGenres, allowedMinYear,allowedMaxYear, maxYear, setMaxYear, minYear, setMinYear, selectedGenres, setSelectedGenres }) => {
  const [isOpen, setIsOpen] = useState(false);
  const sidebarRef = useRef();

  useClickOutSide({myRef:sidebarRef,isActive:isOpen,onClick:() => setIsOpen(false)})

  
  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div ref={sidebarRef} className={classes.sidebarWrapper}>
      <button
        className={`${classes.hamburger} ${isOpen ? classes.open : ""}`}
        onClick={toggleSidebar}
      >
        <FontAwesomeIcon icon={faBars} />
      </button>
      <div
        className={`${classes.sidebarContainer} ${isOpen ? classes.open : ""}`}
      >
        <h2>Filter By</h2>
        <div className={classes.authors}>
          <Slider
            max={maxYear}
            setMax={setMaxYear}
            min={minYear}
            setMin={setMinYear}
            title={"Publishing Year"}
            allowedMin={allowedMinYear}
            allowedMax={allowedMaxYear}
          />
        </div>
        <div className={classes.authors}>
          <Dropdown selectedOptions={selectedGenres} setSelectedOptions={setSelectedGenres} categoryData={allGenres} title={"Category/Genres"} />
        </div>
        {/* <div className={classes.authors}>
                    <Slider max={maxPrice} setMax={setMaxPrice} min={minPrice} setMin={setMinPrice} title={"Price"} />
                </div> */}
      </div>
    </div>
  );
};

export default Sidebar;
