import { createPortal } from "react-dom";
import classes from "./Modal.module.css";
import { getClasses } from "../../Util";
const closeIcon = "/Assets/Icons/icon-x.svg";

export const ModalTitle = ({ children, onClose }) => {
  return (
    <div className={`${classes.header} ${onClose && !children ? classes.cancelIcon : ""}`}>
      {children}
      {onClose && (
        <div
          style={{
            marginLeft: "1rem",
            cursor: "pointer",
            alignSelf: "flex-end",
          }}
        >
          <img src={closeIcon} alt="Close" onClick={onClose} />
        </div>
      )}
    </div>
  );
};

export const ModalBody = ({ children }) => {
  return <div className={classes.body}>{children}</div>;
};

export const ModalFooter = ({ children }) => {
  return <div className={classes.footer}>{children}</div>;
};

const container = document.querySelector("#root");

const Modal = ({ title, children, onClose, className, style }) => {
  return createPortal(
    <>
      <div className={classes.backdrop}>
        <div
          className={getClasses(classes.modal, className ? className : "")}
          style={style}
        >
          {Boolean(title || onClose) && (
            <ModalTitle onClose={onClose}>{title}</ModalTitle>
          )}
          {children}
        </div>
      </div>
    </>,
    container
  );
};

export default Modal;
