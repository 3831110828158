import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classes from "./AddImage.module.css"
import { useState } from "react"
import { faCircleInfo, faPlus } from "@fortawesome/free-solid-svg-icons"
import LogoModal from "./LogoModal/LogoModal"


const AddImage = () => {
    const [modalOpen,setModalOpen] = useState(false)

    return(
        <div className={classes.mainContainer}>
            <div className={classes.uploadBtn} onClick={() => setModalOpen(true)}>
                <FontAwesomeIcon icon={faPlus} />
                <h2>Please upload an image for your dedicated page</h2>
            </div>

            <div className={classes.disclaimer}>
                <FontAwesomeIcon className={classes.infoIcon} icon={faCircleInfo}/>
                <p>You can not upload multiple images</p>
            </div>
            <LogoModal isOpen={modalOpen} onClose={() => setModalOpen(false)} />
        </div>
    )
}

export default AddImage