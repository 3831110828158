import React, { useState } from "react";
import classes from "./FeaturedCollection.module.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

import SecondaryButton from "../../../Components/Buttons/SecondaryButton/SecondaryButton";
import Card from "../../../Components/Card/Card2";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { urls } from "../../../Routes/Urls";
import { useSelector } from "react-redux";
import { getClasses, getRandomImageForBestSellerBook } from "../../../Util";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import NextArrow, { PrevArrow } from "../../../Components/Arrows/Arrows";


const footerImage = "/Assets/Images/sectionEnd.svg";

const tabs = [
  { name: "Newly Crafted", value: "newlyCrafted" },
  { name: "Best Sellers", value: "bestSelling" },
  { name: "Gifts", value: "gifts" },
];

function RightArrow(props) {
  const { onClick } = props;
  return (
    <NextArrow className={classes.RightArrow} onClick={onClick}/>
  );
}

function LeftArrow(props) {
  const { onClick } = props;
  return (
    <PrevArrow className={classes.leftArrow} onClick={onClick}/>
  );
}

function FeaturedCollection() {
  const featuredCollectionBooks = useSelector(
    (state) => state.featured.featuredCollection
  );
  const allColorsData = useSelector((state) => state.bookColor.colors);
  const wishlist = useSelector((state) => state.wishlist.wishlistItems);

  const history = useHistory();

  const [activeTab, setActiveTab] = useState(tabs[0].value);

  const featuredCollection =
    featuredCollectionBooks?.find((data) => data.category === activeTab)
      ?.books || [];

  const isWishListItem = (bookData) => {
    const bookOpt = wishlist?.find(
      (item) =>
        item?.book?.sku === bookData.sku && item?.book?.title === bookData?.title
    );
    return bookOpt
      ? { isPresent: true, data: bookOpt }
      : { isPresent: false, data: null };
  };

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    nextArrow: <RightArrow />,
    prevArrow: <LeftArrow />,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 3.5,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 390,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
    ],
  };

  return (
    <div className={classes.featuredContainer}>
      <div className={classes.headerSection}>
        <div className={classes.headerh2}>
          <h2>Featured Collection</h2>
        </div>
        <div className={classes.description}>
          <p>
            Explore our bestsellers, newly crafted designs, and perfect gifts
            for the discerning bibliophile.
          </p>
        </div>
      </div>

      <div className={classes.tabs}>
        {tabs.map((tab, index) => (
          <div
            key={index}
            className={`${classes.tab} ${
              activeTab === tab.value ? classes.active : ""
            }`}
            onClick={() => setActiveTab(tab.value)}
          >
            {tab.name}
          </div>
        ))}
      </div>

      {featuredCollection?.length > 0 && <Slider {...settings} className={classes.carousel}>
        {featuredCollection.map((card, index) => (
          <Card
            key={index}
            className={classes.card}
            altText={getRandomImageForBestSellerBook(
              card,
              allColorsData
            )?.altText}
            image={card.imageUrl}
            title={card?.title}
            author={card?.author}
            price={card?.priceGenuine}
            bookData={card}
            isWishListItem={isWishListItem(card).isPresent}
            wishlistItemId={isWishListItem(card).data?.id}
          />
        ))}
      </Slider>}

      <div className={classes.buttonContainer}>
        <SecondaryButton
          onClick={() => history.push(urls.BESTSELLER)}
        >
          Explore Our Collection
        </SecondaryButton>
      </div>

      <div className={classes.footerImage}>
        <img src={footerImage} alt="" />
      </div>
    </div>
  );
}

export default FeaturedCollection;
