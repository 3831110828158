import React, { useState } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import classes from './BookCustomization.module.css';
import VideoPlayer from '../../../Components/VideoPlayer/VideoPlayer';

import variantFeatures from "./VariantFeatures.json"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import Tabs from '../WhoAreWe/Tabs/Tabs';

const image = '/Assets/Images/customization_of_book.svg';

const tabs = [
  "Deluxe Edition",
  "Super Deluxe Edition"
]

const features = [
  {
    image: "leatherBinding.svg",
    title: "Exquisite Full Leather Binding",
    description: "Crafted entirely in premium leather for a luxurious feel and durability."
  },
  {
    image: "leatherType.svg",
    title: "Regal Aura",
    description: "Projects a sense of opulence and refinement suitable for collectors and figma antiquarians."
  },
  {
    image: "matchBinding.svg",
    title: "Enhanced Durability",
    description: "Ensures longevity with high-quality materials and expert craftsmanship."
  }
];

function NextArrow(props) {
  const { onClick } = props;
  return (
    <div
      className={classes.leftArrow}
      onClick={onClick}
    >
      <FontAwesomeIcon icon={faAngleRight} />
    </div>
  );
}
function PrevArrow(props) {
  const { onClick } = props;
  return (
    <div
      className={classes.RightArrow}
      onClick={onClick}
    >
      <FontAwesomeIcon icon={faAngleLeft} />
    </div>
  );
}

const sections = [
  {
    title: "Deluxe",
    desc: "Experience timeless elegance with our Deluxe Variant—crafted to captivate with half-leather binding and vibrant marble papers."
  },
  {
    title: "Super Deluxe",
    desc: "Indulge in luxury with our Super Deluxe Edition—fully leather-bound with premium materials for unparalleled sophistication."
  },
];

const BookCustomization = () => {
  const [activeTabIndex,setActiveTabIndex] = useState(0)
  const [activeTab, setActiveTab] = useState(sections[activeTabIndex]);

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 2.5,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2.2,
          slidesToScroll: 1,
          dots: false
        }
      },
      {
        breakpoint: 650,
        settings: {
          slidesToShow: 1.2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  const video = useSelector((state) => state?.serviceVideo?.videos);

  const handleTabClick = (index) => {
      setActiveTabIndex(index)
      setActiveTab(sections[index])
  }

  return (
    <section className={classes.bookCustomization}>
      <div className={classes.header}>
        <h2>Handcrafted Elegance: Our Binding Spectrum</h2>
        <p>Choose between our Super Deluxe and Deluxe bindings, each offering unique features and exquisite craftsmanship.</p>
        {/* <div className={classes.customizationTypes}>
          <div className={classes.buttonGroup}>
            <button
              className={`${classes.button} ${activeTab.title === "Super Deluxe" ? classes.claret : classes.outline}`}
              onClick={() => setActiveTab(sections[1])}
            >
              Super Deluxe Edition
            </button>
            <button
              className={`${classes.button} ${activeTab.title === "Deluxe" ? classes.claret : classes.outline}`}
              onClick={() => setActiveTab(sections[0])}
            >
              Deluxe Edition
            </button>
          </div>
        </div> */}

        <Tabs activeTab={activeTabIndex} onTabClick={handleTabClick} tabs={tabs}/>
      </div>
      <div className={classes.deluxeVariant}>
        <div className={classes.imageContainer}>
          <img src={image} alt="Reading" className={classes.image} />
        </div>
        <div className={classes.content}>
          <h2>{activeTab.title} Edition</h2>
          <p>{activeTab.desc}</p>
          <div className={classes.titles}>
            {variantFeatures[activeTab.title].map((data,index) => (
              <div key={index} className={classes.title}>
                <h3>{data.title}</h3>
                <p>{data.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className={classes.videoSection}>
        <div className={classes.videoContainer}>
          <VideoPlayer videoSrc={video?.leatherBindingVideo} />
        </div>
      </div>
      <div className={classes.customizationOptions}>
        <div className={classes.container}>
          <h2 className={classes.heading}>Why super deluxe customization</h2>
          <p className={classes.description}>
            Indulge in luxury with our Super Deluxe Edition—fully leather-bound with premium materials for unparalleled sophistication.
          </p>
          <Slider {...settings} className={classes.carousel}>
            {features.map((feature, index) => (
              <div className={classes.cardContainer} key={index}>
                <div className={classes.card}>
                  <img src={`/Assets/Images/${feature.image}`} alt={feature.title} className={classes.cardImage} />
                  <div className={classes.text}>
                    <h3 className={classes.cardTitle}>{feature.title}</h3>
                    <p className={classes.cardDescription}>{feature.description}</p>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </section>
  );
};

export default BookCustomization;
