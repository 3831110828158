import classes from "./CustomizationWelcomeScreen.module.css";

import { useContext, useEffect, useState } from "react";
import SecondaryButton from "../../../Components/Buttons/SecondaryButton/SecondaryButton";
import OptionCard from "../Components/OptionCard/OptionCard";
import ProductDetails from "../Components/ProductDetails/ProductDetails";
import useScreenSize from "../../../Hooks/UseScreenSize";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { CustomizationContext } from "../Customization";
import { deleteCustomizationDesign, getAllUserDesigns } from "../../../Store/actions/CustomizationAction";
import { useSelector } from "react-redux";
import { urls } from "../../../Routes/Urls";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const bookCover = "/Assets/Images/customization/bookCover.svg";
const createNew = "/Assets/Images/customization/create-new-design.svg";
const previousDesigns = "/Assets/Images/customization/previous-design.svg";

const CustomizationData = [
  {
    desc: "Create New Design",
    value: "create",
    image: createNew,
  },
  {
    desc: "Choose Previous Design",
    value: "previous",
    image: previousDesigns,
  },
];

const CustomizationWelcomeScreen = ({
  onNext,
  visibility,
  bookData,
  bookVariant,
  BookleatherType,
  redirectedDesign
}) => {
  const history = useHistory()
  const { isMediumScreen } = useScreenSize();
  const [cardType, setCardType] = useState("create");

  const {allSavedDesigns,setAllSavedDesigns,
    selectedDesigns,setSelectedDesigns,steps} = useContext(CustomizationContext)

  const currentUser = useSelector((state) => state.user.currentUser);

  useEffect(() => {
    if(cardType === "create" || steps === -1 && !redirectedDesign?.bookId) {
        setSelectedDesigns([])
    }
  },[cardType,steps])

  useEffect(() => {
    if(redirectedDesign?.bookId) {
      setCardType("previous");
      setSelectedDesigns(redirectedDesign);

      if (redirectedDesign?.bookId && steps >= 0) {
        history.replace({
          pathname: `${urls.CUSTOMIZATION}/${bookData?.id}`, // Current URL
          state: null,
        });
      }
    }
  },[steps,redirectedDesign])  

  const handleOnNext = () => {
    if(selectedDesigns?.length < 1) {
      onNext();
    } else {
      onNext();
      onNext();
    }
  };

  const handleDelete = (designId) => {
    deleteCustomizationDesign(designId).then((res) => {
      if(res.data.success) {
        getAllUserDesigns(currentUser?.userId,bookData?.id).then((res) => {
          if(res.data.success) {
            setAllSavedDesigns(res.data.data)
          }
        })
      }
    })
  } 

  useEffect(() => {
    if(steps === -1) {
      getAllUserDesigns(currentUser?.userId,bookData?.id).then((res) => {
        if(res.data.success) {
          setAllSavedDesigns(res.data.data)
        }
      })
    }
  },[cardType,steps])

  const isSamePages = (pages) => {
    if( (pages >= 0 && bookData?.pages >= 0) && (pages <= 200 && bookData?.pages <= 200) ) {
      return true;
    } else if( (pages >= 201 && bookData?.pages >= 201) && (pages <= 400 && bookData?.pages <= 400) ) {
      return true
    } else if( (pages >= 401 && bookData?.pages >= 401) && (pages <= 600 && bookData?.pages <= 600) ) {
      return true
    } else if( (pages >= 601 && bookData?.pages >= 601) && (pages <= 800 && bookData?.pages <= 800) ) {
      return true
    } else if(pages >= 801 && bookData?.pages >= 801) {
      return true
    } else {
      return false;
    }
  }

  return (
    <div>
      <div
        className={classes.mainContainer}
        style={{ display: visibility ? "flex" : "none" }}
      >
        <div className={classes.detailSection}>
          <h2>Welcome to your Customization Journey!</h2>
          <div className={classes.cardContainer}>
            <div className={classes.leatherDetails}>
              <div className={classes.leatherType}>
                <h3>How would you like to start?</h3>
                <div className={classes.leatherOptions}>
                    <OptionCard
                      key={1}
                      image={createNew}
                      desc="Create New Design"
                      active={cardType === "create"}
                      onClick={() => setCardType("create")}
                    />
                    {allSavedDesigns?.length > 0 && 
                    <OptionCard
                      key={2}
                      image={previousDesigns}
                      desc="Choose Previous Design"
                      active={cardType === "previous"}
                      onClick={() => setCardType("previous")}
                    />
                    }
                </div>

                {cardType === "previous" && <div
                  style={{ marginTop: "2rem" , height:"15rem",overflowY:"auto"}}
                  className={classes.sampleOptions}
                >
                  {cardType === "previous" &&
                    allSavedDesigns?.map((card, index) => (
                      <OptionCard
                        className={classes.savedDesigns}
                        key={index}
                        image={createNew}
                        desc={card?.title}
                        active={selectedDesigns?.title === card.title}
                        allowDelete = {true}
                        onDelete={() => handleDelete(card?.id)}
                        onClick={() => setSelectedDesigns(card)}
                        disabeled = {card?.sizeName !== bookData?.sizeName && !isSamePages()}
                        disabledText = "Design Not Available for This Book Size"
                      />
                    ))}
                </div>}
              </div>
            </div>
            <SecondaryButton onClick={handleOnNext}>
              Let’s Get Started!
            </SecondaryButton>
          </div>
        </div>

        {!isMediumScreen && (
          <div className={classes.imageSection}>
            <div className={cardType === CustomizationData[1].value ? classes.mainImgAlt : classes.mainImage}>
              <img src={bookCover} alt="" className={cardType !== CustomizationData[1].value ? classes.bookImg : "" }/>
            </div>
            <div className={classes.productDetails}>
              <ProductDetails
                bookData={bookData}
                leatherType={BookleatherType}
                variantType={bookVariant}
              />
            </div>
          </div>
        )}

      </div>
    </div>
  );
};

export default CustomizationWelcomeScreen;
