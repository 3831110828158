import PrimaryButton from "../../../../Components/Buttons/PrimaryButton/PrimaryButton";
import Modal, { ModalBody, ModalFooter } from "../../../../Components/Modal/Modal";
import classes from "./ScreenSizeModal.module.css"

const computerImage = "/Assets/Images/customization/computer-editing.png";
const ScreenSizeModal = ({onClose}) => {
    return (
        <Modal className={classes.modal} onClose={onClose}>
            <ModalBody>
                <div className={classes.container}>
                    <h2>For better experience</h2>
                    <p>Use desktop to customize your book</p>

                    <div className={classes.image}>
                        <img src={computerImage} alt="image" />
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
                <PrimaryButton onClick={onClose}>Yes, I understand</PrimaryButton>
            </ModalFooter>
        </Modal>
    )
}

export default ScreenSizeModal