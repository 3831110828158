import classes from "./AdditionalRequest.module.css"
import {useContext, useState, useEffect } from "react"
import PrimaryTextArea from "../../../Components/Inputs/PrimaryTextArea/PrimaryTextArea"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCircleXmark, faPlusCircle } from "@fortawesome/free-solid-svg-icons"
import { CustomizationContext } from "../Customization"
import useScreenSize from "../../../Hooks/UseScreenSize"
import PrimaryButton from "../../../Components/Buttons/PrimaryButton/PrimaryButton"
import { addCartItem } from "../../../Store/actions/CartAction"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import { urls } from "../../../Routes/Urls"
import MessageDialog from "../../../Components/MessageDialog/MessageDialog"


const AdditionalRequest = () => {

    const {setAdditionalRewquestText,
        additionalRequestImage,setAdditionalRequestImage,bookCustomization,bookData,bookQty,
        setDesignSaveInitiated,designSaveCompleted,setDesignSaveCompleted} = useContext(CustomizationContext);

    const {isMediumScreen} = useScreenSize()
    const dispatch = useDispatch();
    const history = useHistory();

    const [showMessageDialog,setShowMessageDialog] = useState(false);

    const handleFileSelect = (event) => {
        const file = event.target.files[0]; // Get the first selected file
        if (file) {
            setAdditionalRequestImage(file); // Set the file to state
        }
    };
        
    const handleClick = () => {
        document.getElementById("fileInput").click();
    };

    
    const handleAddCart = () => {
        if(bookCustomization) {
            setShowMessageDialog(true);
            // addCartItem(bookData,bookQty,bookCustomization?.variantType,bookCustomization?.leatherType,bookCustomization?.leatherColor,dispatch,bookCustomization)
        }
    }
    
    
    const handleMessageModalClose = () => {
        if(bookCustomization) {
            setShowMessageDialog(false);
            addCartItem(bookData,bookQty,bookCustomization?.variantType || "deluxe",bookCustomization?.leatherType || "realLeather",bookCustomization?.leatherColor,dispatch,bookCustomization);
            history.push(urls.CART)
        }
    }
    
        const handleMessageModalAccept = () => {
            if(bookCustomization) {
                setDesignSaveInitiated(true);
            }
    
            setTimeout(() => setDesignSaveCompleted(false),10000)
        }
    
    
        useEffect(() => {
            if(designSaveCompleted && showMessageDialog) {
                addCartItem(bookData,bookQty,bookCustomization?.variantType,bookCustomization?.leatherType,bookCustomization?.leatherColor,dispatch,bookCustomization,true);
                history.push(urls.CART)
                setShowMessageDialog(false);
            }
        },[designSaveCompleted])
    

    return (
        <div className={classes.mainContainer}>
            <div className={classes.details}>
                <div className={classes.headerSection}>
                   {!isMediumScreen && <h2>Do you have any Additional Request (5/5)</h2>}

                    <p>Place your request for any additional customizations and our team will reach out to you! </p>
                </div>
                <div className={classes.inputSection}> 
                    <PrimaryTextArea onChange={(e) => setAdditionalRewquestText(e.target.value) } />
                </div>

                <div className={classes.uploadBtn} onClick={handleClick}>
                  <div className={classes.addLogo}>
                    <FontAwesomeIcon
                      icon={faPlusCircle}
                      className={classes.addLogoIcon}
                    />
                    <p>Upload File</p>
                  </div>
                  {/* <div className={classes.logoPrice}>+$40</div> */}
                </div>

                {/* Hidden file input to select an image */}
                <input
                  type="file"
                  id="fileInput"
                  accept="image/*"
                  style={{ display: "none" }}
                  onChange={handleFileSelect} // Trigger on file selection
                />
                {/* Optional: Image preview (if image is selected) */}
                {additionalRequestImage && (
                  <div className={classes.imagePreview}>
                    <img
                      src={URL.createObjectURL(additionalRequestImage)} // Convert file to URL for preview
                      alt="Selected Design"
                    />
                    <FontAwesomeIcon className={classes.deleteImage} icon={faCircleXmark} onClick={() => setAdditionalRequestImage(null)} />
                  </div>
                )}

                <div className={classes.disclaimer}>
                    <p>Disclaimer : uploaded design won’t be available for live preview
                    We will contact you on your registered email / mobile to understand your needs more precisely.</p>
                </div>

                <PrimaryButton className={classes.doneBtn} onClick={handleAddCart}>Done</PrimaryButton>
            </div>
            
            {showMessageDialog && (
                <MessageDialog
                message={"Would you like to save this design for future use? Select 'Save' to keep it accessible anytime. Please note that if you choose not to save the design, you will not be able to edit your customization after adding it to the cart"}
                onCancel={handleMessageModalClose}
                isCancel
                title={"Save Customization Design"}
                closeText="Don't Save and Add to Cart"
                acceptBtnText="Save and Add To Cart"
                onAccept={handleMessageModalAccept}
                onClose={() => setShowMessageDialog(false)}
                />
            )}

        </div>
    )
}

export default AdditionalRequest