import classes from "./BannerHeader.module.css"

import BreadCrumb from "../BreadCrumb/BreadCrumb";
import SecondaryButton from "../Buttons/SecondaryButton/SecondaryButton";
import useScreenSize from "../../Hooks/UseScreenSize";

const BannerHeader = ({title,desc,button = false,buttonText,crumbs,bgImage}) => {
    const style = {
        backgroundImage: bgImage ? `url(${bgImage})` : undefined
    };
    const {isSmallScreen} = useScreenSize()
    return(
        <div className={classes.header} style={style}>
            <div className = {classes.background}>
            <BreadCrumb
                isBack={false}
                crumbs={crumbs}
                onBack={() => {
                    
                }}
            />
            </div>
            <div className={classes.content}>
                <h1>{title}</h1>
                {!isSmallScreen && <p>{desc}</p>}
                {button && <SecondaryButton className={classes.Btn}>{buttonText}</SecondaryButton>}
            </div>

        </div>
    )
}

export default BannerHeader