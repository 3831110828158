import classes from "./MarblePaper.module.css"

import { useContext, useEffect, useState } from "react";

import { useFabricCanvas, useFabricCanvasHandler, useFabricEditor } from "react-fabric-page";
import { addImageToCanvas } from "../../../../objectUtils";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleUp, faAngleDown } from "@fortawesome/free-solid-svg-icons";
import useScreenSize from "../../../../Hooks/UseScreenSize";
import { CustomizationContext } from "../../Customization";
import Slider from "react-slick/lib/slider";
import PreviewCard from "../../Components/PreviewCard/PreviewCard";
import ToolTip from "../../../../Components/ToolTip/ToolTip";

const MarblePaper = ({ onClick, toggleDropdown, dropdownOpen, currentDropdown,isCanvasReady }) => {
    const {frontMarblePaper,setFrontMarblePaper, selectedDesigns,allEndpapers} = useContext(CustomizationContext);
    const wantinnerBorder = "Yes"

    const canvas = useFabricCanvas()
    const editor = useFabricEditor()

    const {isMediumScreen} = useScreenSize()

    const settings = {
        slidesToShow: allEndpapers?.length > 1 ? 2.5 : 1,
        swipeToSlide: true,
        infinite: false,
        centerPadding: "10px",
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: allEndpapers?.length > 1 ? 3.5 : 1,
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: allEndpapers?.length > 1 ? 2.5 : 1,
              }
            },
            {
                breakpoint: 400,  // New breakpoint for screens below 400px
                settings: {
                  slidesToShow: allEndpapers?.length > 1 ? 1.85 : 1,
                }
              }
          ]
      };

      useEffect(() => {
        setFrontMarblePaper(allEndpapers?.[0])
      },[allEndpapers])

    const handleClickAndSelect = () => {
        onClick()
        if (dropdownOpen) {
            toggleDropdown(false)
        }
        else {
            toggleDropdown(true)
        }
    }
    const getBounds = () => {
        const imageBox = canvas?.getObjects().find(o => o.id==="image")

        if(imageBox){
            return {
                left: imageBox.left,
                top: imageBox.top,
                width: imageBox.width,
                height:imageBox.height
            }
        }
        else{
            return null
        }

    }


    const setCoverImage = (imageUrl) => {
        return addImageToCanvas({filepath:imageUrl,fileType:"image"},1,getBounds,null,false,false).then((img) => {
            const layer = canvas?.getObjects()?.find(o => o?.id === 'image');
            if(layer){
                layer._objects = layer._objects?.filter((o) => o.id !== "marblePaper") 
                img.imageUrl = imageUrl;
                img.id = "marblePaper"
                layer.add(img);
                canvas.dirty = true;
                canvas.renderAll();
                editor.saveCurrentState()
            }
          }).catch((error) => {
            
          })
    };

    useFabricCanvasHandler({
      'canvas:x-ready': () => {
          const layer = canvas?.getObjects()?.find(o => o.id === "image");
          if (layer) {
            const obj = layer._objects?.find((o) => o.id === "marblePaper")
            if(obj) {
              const appliedMarblePaper = allEndpapers?.find(paper => obj?.imageUrl === paper?.designImage); 
              if(appliedMarblePaper) {
                setFrontMarblePaper(appliedMarblePaper)
              }
            }
          }
      },
    },[allEndpapers]);


    useEffect(() => {
            if (allEndpapers?.length > 0 && isCanvasReady === true) {
                setCoverImage(allEndpapers?.[0]?.designImage).then(() => {
                  setFrontMarblePaper(allEndpapers?.[0]);
                });
            } 
    }, [canvas,allEndpapers,isCanvasReady]);

    useEffect(() => {
      if (allEndpapers?.length > 0 && isCanvasReady === true) {
          if(selectedDesigns?.customization?.marblePaper?.endpaper) {
            const selectedPaper = allEndpapers?.find((paper) => paper?.id === selectedDesigns?.customization?.marblePaper?.endpaper);
            if(selectedPaper) {
              setCoverImage(selectedPaper?.designImage).then(() => {
                setFrontMarblePaper(selectedPaper);
              });
            } 
          } 
      } 
}, [isCanvasReady,selectedDesigns]);

    const handleChangeMarblePaper = (paper) => {
        setFrontMarblePaper(paper);
        if(frontMarblePaper?.designName !== paper?.designName){
            setCoverImage(paper?.designImage)
        }
    }
    
    return (
        <div>
            {isMediumScreen ? (
                <div onClick={(e) => e.stopPropagation()}>

                {wantinnerBorder === "Yes" && <div className={classes.innerBorderTypes}>
                    <p>Select Design :</p>


                    <div className={classes.innerBorderTypePreview}>
                        <div className={classes.previewContainer}>
                        <Slider {...settings}>
                        {allEndpapers?.map((marblePaper) => (
                          <div
                            key={marblePaper?.designName}
                            className={`${classes.previewCard} ${
                              frontMarblePaper?.designName ===
                              marblePaper?.designName
                                ? classes.activePreviewCard
                                : ""
                            }`}
                            onClick={() => handleChangeMarblePaper(marblePaper)}
                          >
                            <img src={marblePaper?.designImage} alt="" />
                          </div>
                        ))}
                      </Slider>
                        </div>

                    </div>
                </div>}

            </div>
            ) : (
                <div className={classes.innerBorder} onClick={() => handleClickAndSelect()}>
                    <div className={classes.innerBorderHeader}>
                        <div className={classes.headerText}>
                          <h2>Marble Paper</h2>
                          <ToolTip tooltipText={"Choose Marble Paper for Front Cover"}/>
                        </div>
                        <div className={classes.dropDownImage} onClick={toggleDropdown}>
                            {dropdownOpen && currentDropdown === "Marble Paper" ? <FontAwesomeIcon icon={faAngleUp} /> : <FontAwesomeIcon icon={faAngleDown} />}
                        </div>
                    </div>

                    {dropdownOpen && currentDropdown === "Marble Paper" ? (
                <div onClick={(e) => e.stopPropagation()}>

                {wantinnerBorder === "Yes" && <div className={classes.innerBorderTypes}>
                    <p>Select Design :</p>


                    <div className={classes.innerBorderTypePreview}>
                        <div className={classes.previewContainer}>
                        <Slider {...settings}>
                        {allEndpapers?.map((marblePaper) => (
                          <PreviewCard
                          key={marblePaper?.designName}
                          image={marblePaper?.designImage}
                          altText={marblePaper?.designName}
                          handleClick={() => handleChangeMarblePaper(marblePaper)}
                          isActive={
                            frontMarblePaper?.designName === marblePaper?.designName
                          }
                        />
                        ))}
                      </Slider>
                        </div>

                    </div>
                </div>}

            </div>
                    ) : (
                        <div></div>
                    )
                    }
                </div>

            )}

        </div>
    )
}

export default MarblePaper
