import { useState } from "react";
import Slider from 'react-slick';

import classes from "./Gallery.module.css"

import { getClasses, getRandomImageForNormalBook } from "../../../Util";

import { useSelector } from "react-redux";

const tabsData = [
    {
        tabs:"Super Deluxe edition",
        type: "superDeluxe"
    },
    {
        tabs:"Deluxe edition",
        type: "deluxe"
    },
]

function Gallery() {
    const [currentIndex, setCurrentIndex] = useState(0);
    const allColorsData = useSelector(state => state.bookColor.colors);

    const settings = {
        dots: false,
        infinite: true,
        autoplay:true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        centerMode: true,
        focusOnSelect:true,
        centerPadding: '0px',
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
        ],
        beforeChange: (current, next) => {
            setCurrentIndex(next);
        },
    };

    const [activeTab, setActiveTab] = useState("superDeluxe");
    const bookData = useSelector((state) => state.gallery.gallery);

    const galleriesData = bookData?.find((data) =>  data.category === activeTab)?.books || [];
    
    return (
        <div className={classes.mainContainer}>
            <div className={classes.galleryContainer}>
                <div className={classes.headerSection}>
                    <div className={classes.header}>
                        <h1>Artistry in Action: Our Finest Work</h1>
                    </div>
                    <div className={classes.description}>
                        <p>Crafting excellence through meticulous artistry and skill.</p>
                    </div>
                </div>

                <div className={classes.tabs}>
                    {tabsData.map((tab, index) => (
                        <div
                            key={index}
                            className={`${classes.tab} ${activeTab === tab.type ? classes.active : ''}`}
                            onClick={() => setActiveTab(tab.type)}
                        >
                            <p>{tab.tabs}</p>
                        </div>
                    ))}
                </div>

                <div className={classes.carouselContainer}>
                    <Slider {...settings}>
                        {galleriesData.map((book, index) => (
                            <div key={index} className={classes.cardContainer}>
                            <div className={getClasses(classes.slide, index === currentIndex && classes.centerSlide)}>
                                    <img src={activeTab === tabsData[0].type ? book.imageUrlSuperDeluxe : book.imageUrlDeluxe} alt={getRandomImageForNormalBook(book,allColorsData)?.altText} />
                            </div>
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>
        </div>
    )
}

export default Gallery;
