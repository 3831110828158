import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  showMessage: false,
};

const verificationPendingSlice = createSlice({
  name: "verificationPending",
  initialState,
  reducers: {
    showMessage: (state) => {
      state.showMessage = true;
    },
    hideMessage: (state) => {
      state.showMessage = false;
    },
  },
});

export const verificationPendingActions = verificationPendingSlice.actions;
export default verificationPendingSlice.reducer;
