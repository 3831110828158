import { useState } from "react";
import { useSelector } from "react-redux";
import { urls } from "../../Routes/Urls";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

import { isUserLoggedIn } from "../../Util";
import classes from "./ExclusiveVerification.module.css";
import PrimaryButton from "../../Components/Buttons/PrimaryButton/PrimaryButton";
import CodeCard from "./CodeCard";

const middleImage = "../../Assets/Images/rb-exclusive.svg";
const leftImage = "../../Assets/Images/rb-exclusive-upper-image.svg";
const rightImage = "../../Assets/Images/rb-exclusive-lower-image.svg";
const ExclusiveVerification = ({setHasExclusiveAccess}) => {
  const history = useHistory();
  const userData = useSelector((state) => state.user.currentUser);

  const boxes = Array.from({ length: 29 }, (_, i) => i + 1);
  const [hoveredBoxes, setHoveredBoxes] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const colors = [
    "#ffffff",
    "#f6f6f6",
    "#ececec",
    "#e1e1e1",
    "#d0d0d0",
    "#d6d6d6",
    "#a9a9a9",
    "transparent",
    "transparent",
    "transparent",
  ];

  const handleMouseOver = (box) => {
    setHoveredBoxes((prevHoveredBoxes) => {
      const newHoveredBoxes = [...prevHoveredBoxes, box];
      return Array.from(new Set(newHoveredBoxes));
    });

    setTimeout(() => {
      setHoveredBoxes((prevHoveredBoxes) =>
        prevHoveredBoxes.filter((b) => b !== box)
      );
    }, 200);
  };

  const handleEnterNow = () => {
    if (isUserLoggedIn()) {
       if(userData.hasExclusiveAccess){
         setHasExclusiveAccess(true);
       } else {
         setShowModal(true);
       }
    } else {
      history.push(urls.LOGIN);
    }
  };

  return (
    <>
      {showModal && <CodeCard setHasExclusiveAccess={setHasExclusiveAccess} onClose={() => setShowModal(false)} />}
      <div className={classes.main}>
        <div className={classes.imageContainer}>
          <div className={classes.leftImage}>
            <img src={leftImage} alt="" />
          </div>
          <div className={classes.middleImage}>
            <img src={middleImage} alt="" />
          </div>
          <div className={classes.rightImage}>
            <img src={rightImage} alt="" />
          </div>
        </div>
        {!showModal && (
          <div className={classes.primaryButton}>
            <PrimaryButton onClick={handleEnterNow} className={classes.exclusiveVerification}>Enter Now</PrimaryButton>
          </div>
        )}
        <div className={classes.hoverContainer}>
          {boxes.map((item) => {
            const color = hoveredBoxes.includes(item)
              ? colors[hoveredBoxes.indexOf(item) % colors.length]
              : "transparent";

            return (
              <div
                key={item}
                className={classes.box}
                onMouseOver={() => handleMouseOver(item)}
                style={{ backgroundColor: color }}
              ></div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default ExclusiveVerification;
