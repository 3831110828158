import React, { useState,useRef, useEffect } from 'react';
import classes from './VideoPlayer.module.css';
import PrimaryButton from '../Buttons/PrimaryButton/PrimaryButton';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { urls } from '../../Routes/Urls';

const VideoPlayer = ({ videoSrc,videoId,button=false}) => {
  const history = useHistory()
  const [isPlaying, setIsPlaying] = useState(true);
  const [showPlayButton, setShowPlayButton] = useState(true);

  const videoRef = useRef(null);

  const handlePlayPause = () => {
    const video = videoRef.current;
    if (isPlaying) {
      video.pause();
      setIsPlaying(false);
      setShowPlayButton(true);
    } else {
      video.play();
      setIsPlaying(true);
      setShowPlayButton(false);
    }
  };

  useEffect(() => {
    // Check if video is autoplaying and hide the play button accordingly
    const video = videoRef.current;

    const handlePlay = () => {
      setIsPlaying(true);
      setShowPlayButton(false); // Hide play button when video starts
    };

    const handlePause = () => {
      setIsPlaying(false);
      setShowPlayButton(true); // Show play button when video is paused
    };

    // Attach event listeners for play and pause
    video?.addEventListener('play', handlePlay);
    video?.addEventListener('pause', handlePause);

    // Check if video starts playing automatically
    if (!video.paused) {
      handlePlay();
    }

    const preventFullscreen = () => {
      if (video) {
        video.requestFullscreen = null; // Disable fullscreen
      }
    };

    video?.addEventListener('click', preventFullscreen);
    return () => {
      video?.removeEventListener('click', preventFullscreen);
      video?.removeEventListener('play', handlePlay);
      video?.removeEventListener('pause', handlePause);

    };
  }, []);

  return (
    <div className={classes.mainContainer}>
    <div className={classes.videoFrame}>
        <video ref = {videoRef} id="video" src={videoSrc} onClick={handlePlayPause} autoPlay muted loop playsInline />
        {showPlayButton && (
          <div className={classes.playButton} onClick={handlePlayPause}>
            <div className={classes.playIcon} />
          </div>
        )}
    </div>
        {button && <PrimaryButton className={classes.btn} onClick={() => history.push(urls.LISTING)}>Explore Our Collection</PrimaryButton>}
    </div>
  );
};

export default VideoPlayer;
