import React, { useState } from 'react';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import classes from './BookCustomization.module.css';
import variantFeatures from "./VariantFeatures.json"
import Tabs from '../../../Body/WhoAreWe/Tabs/Tabs';

const image = '/Assets/Images/customization_of_book.svg';

const tabs = [
  "Deluxe Edition",
  "Super Deluxe Edition"
]

const sections = [
  {
    title: "Deluxe",
    desc: "Experience timeless elegance with our Deluxe Variant—crafted to captivate with half-leather binding and vibrant marble papers."
  },
  {
    title: "Super Deluxe",
    desc: "Indulge in luxury with our Super Deluxe Edition—fully leather-bound with premium materials for unparalleled sophistication."
  },
];

const BookCustomization = () => {
  const [activeTabIndex,setActiveTabIndex] = useState(0)
    const [activeTab, setActiveTab] = useState(sections[activeTabIndex]);

    const handleTabClick = (index) => {
      setActiveTabIndex(index)
      setActiveTab(sections[index])
  }

  return (
    <section className={classes.bookCustomization}>
      <div className={classes.header}>
        <h2>
          Our Special Leather Book Variants

        </h2>
        <p>Choose between our Super Deluxe and Deluxe bindings, each offering unique features and exquisite craftsmanship.
        </p>
        <Tabs activeTab={activeTabIndex} onTabClick={handleTabClick} tabs={tabs}/>
        {/* <div className={classes.customizationTypes}>
          <div className={classes.buttonGroup}>
            <button
              className={`${classes.button} ${activeTab.value === "Super Deluxe" ? classes.claret : classes.outline}`}
              onClick={() => setActiveTab(sections[1])}
            >
              Super Deluxe Edition
            </button>
            <button
              className={`${classes.button} ${activeTab.value === "Deluxe" ? classes.claret : classes.outline}`}
              onClick={() => setActiveTab(sections[0])}
            >
              Deluxe Edition
            </button>
          </div>
        </div> */}
      </div>
      <div className={classes.deluxeVariant}>
        <div className={classes.imageContainer}>
          <img src={image} alt="Reading" className={classes.image} />
        </div>
        <div className={classes.content}>
          <h2>{activeTab.title} Edition</h2>
          <p>{activeTab.desc}</p>
          <div className={classes.titles}>
            {variantFeatures[activeTab.title].map((data,index) => (
              <div key={index} className={classes.title}>
                <h3>{data.title}</h3>
                <p>{data.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default BookCustomization;
