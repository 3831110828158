import React, { useState, useContext } from 'react';
import classes from './LogoModal.module.css';
import { useFabricCanvas } from 'react-fabric-page';
import { createPortal } from 'react-dom';
import SecondaryButton from '../../../../Components/Buttons/SecondaryButton/SecondaryButton';
import { CustomizationContext } from '../../Customization';
import Modal, { ModalBody } from '../../../../Components/Modal/Modal';
const hexagon = "/Assets/Icons/hexagon.svg";
const lineArt = "/Assets/Icons/lineArt.jpg";

const LogoModal = ({ isOpen, onClose }) => {
  const { setFrontCoverLogo } = useContext(CustomizationContext);
  const canvas = useFabricCanvas();
  const [imageURL, setImageURL] = useState("");
  const [errorMessage, setErrorMessage] = useState(""); // To show error message
  const [isFileValid, setIsFileValid] = useState(false); // To disable/enable upload button

  const handlePhotoUpload = (file) => {
    if (file) {
      const fileType = file.type.split("/")[1].toLowerCase();
      if (fileType === "jpeg" || fileType === "jpg") {
        const reader = new FileReader();
        reader.onload = (e) => {
          setImageURL(e.target.result); // Set the Base64 string
          setErrorMessage("");
          setIsFileValid(true);
        };
        reader.onerror = () => {
          setErrorMessage("Error reading the file. Please try again.");
          setIsFileValid(false);
        };
        reader.readAsDataURL(file); // Convert file to Base64
      } else {
        setImageURL("");
        setErrorMessage("Wrong file format! Please upload a JPEG or JPG file.");
        setIsFileValid(false);
      }
    }
  };

  const handleFileInput = (e) => {
    const file = e.target.files[0];
    handlePhotoUpload(file);
  };

  const updateImageURL = (canvas, newImageURL) => {
    const layer = canvas?.getObjects().find(o => o.id === 'contents');
    if (layer) {
      const imageObject = layer._objects.find(obj => obj.type === 'imageBox');
      if (imageObject) {
        imageObject.updateSrc(newImageURL).then(e => {
          layer.dirty = true;
          canvas.renderAll();
          setFrontCoverLogo(newImageURL);
        });
        onClose();
      }
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const file = e.dataTransfer.files[0];
    handlePhotoUpload(file);
  };

  if (!isOpen) return null;

  const container = document.querySelector("#root");

  return (
      <Modal className={classes.modalOverlay} onClick={onClose}>
        <ModalBody>
          <div className={classes.modalContent} onClick={(e) => e.stopPropagation()}>
            <h2>Upload your Unique design</h2>
            <div className={classes.uploadArea} onDragOver={handleDragOver}
            onDrop={handleDrop}>
              <div className={classes.uploadBox}>
                <input
                  type="file"
                  id="fileUpload"
                  accept="image/jpeg, image/jpg"
                  onChange={handleFileInput}
                  className={classes.fileInput}
                />
                <label htmlFor="fileUpload" className={classes.uploadLabel} >
                  <div className={classes.uploadIcon}>&#128194;</div>
                  <div className={classes.uploadText}>Click or drag file to this area to upload</div>
                </label>
              </div>
              <p>We accept <span>JPEG</span> and <span>JPG</span> formats only!</p>
              {errorMessage && <p className={classes.errorText}>{errorMessage}</p>}
            </div>
            <div className={classes.sampleImageSection}>
      
              <div className={classes.sampleImageHeader}>
                <span className={classes.sampleImage}>Sample Image</span>
                <button className={classes.recommendedButton}>Recommended</button>
              </div>

              <div className={classes.sampleLogo}>
                  <img src={lineArt} alt="" />  
                </div>

              <div className={classes.sampleDetails}>
                <div className={classes.lineArt}>
                  <div className={classes.circle}>
                    <img src={hexagon} alt="" />
                  </div>
                  <div className={classes.detailsContent}>
                    <h3>Line Art</h3>
                    <p>Please try to submit your design in line art format to ensure seamless embossing/foiling.</p>
                    <p>The image above illustrates what we mean by line art/line drawing.</p>
                  </div>
                </div>
                <div className={classes.fileFormat}>
                  <div className={classes.circle}>
                    <img src={hexagon} alt="" />
                  </div>
                  <div className={classes.detailsContent}>
                    <h3>File Format</h3>
                    <p>Please note that we only accept files in JPEG, and PNG formats.</p>
                  </div>
                </div>
                <div className={classes.fileSize}>
                  <div className={classes.circle}>
                    <img src={hexagon} alt="" />
                  </div>
                  <div className={classes.detailsContent}>
                    <h3>File Size</h3>
                    <p>Please ensure your files do not exceed the maximum size limit. We accept files up to {10} MB to ensure efficient processing.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.footer}>
              <div className={classes.disclaimer}>
                <p>Disclaimer: Uploaded design won't be available for live preview</p>
              </div>
              <div className={classes.buttons}>
                <SecondaryButton
                  onClick={(e) => updateImageURL(canvas, imageURL)}
                  className={classes.uploadButton}
                  disabled={!isFileValid} // Disable the button if file is invalid
                >
                  Upload File
                </SecondaryButton>
                <button className={classes.cancelButton} onClick={onClose}>Cancel</button>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
  );
};

export default LogoModal;
