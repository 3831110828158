import React from "react";
import classes from "./ItemsNav.module.css";
import NavDropdown from "../NavDropdown/NavDropdown";
import NavItem from "../NavItem/NavItem";
import SecondaryButton from "../../../Components/Buttons/SecondaryButton/SecondaryButton";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { urls } from "../../../Routes/Urls";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

function ItemsNav() {
  const history = useHistory();
  const location= useLocation();

  const handleNavLinks = (option) => {
    if(option === 'Contact Us'){
      history.push(urls.CONTACTUS)
    }
    else if(option === "Our Collection"){
      history.push(urls.LISTING)
    }
    else if(option === "Best Seller"){
      history.push(urls.BESTSELLER)
    }
    else if(option === 'Customization'){
      history.push(urls.CUSTOMIZATION)
    } else if (option === "RB-Exclusive"){
      history.push(urls.RB_EXCLUSIVE);
    }
    else if(option === "Our Blogs"){
      history.push(urls.BLOG)
    }
    else if(option === "Home"){
      history.push(urls.HOME)
    }
  }

  const isSearchVisibile = location.pathname !== urls.HOME && location.pathname !== urls.LISTING && location.pathname !== urls.BESTSELLER

  return <nav className={isSearchVisibile ? classes.main : classes.mainHidden}>
        <div className={classes.container}>
            <NavItem onClick={() => handleNavLinks("Home")}>Home</NavItem>
            <NavItem onClick={() => handleNavLinks("Our Collection")}>Our Collections</NavItem>
            <NavItem onClick={() => handleNavLinks("Best Seller")}>Best Sellers</NavItem>
            <NavDropdown options={[{label: "Book Customization"},{label:"Leather Binding"},{label:"PDF Printing"},{label:"Book Restoration"},{label:"Thesis Binding"}]}><div>Our Services</div></NavDropdown>
            <NavDropdown options={[{label:"Become Our Partner"},{label:"For Publishers"},{label:"For Authors"},{label:"Corporate Gifts"}]}><div>Partner With Us</div></NavDropdown>
            <NavDropdown options={[{label: "Our Company"},{label: "Contact us"},{label:"FAQs"}]}><div>About us</div></NavDropdown>
            <NavItem onClick={() => handleNavLinks("Our Blogs")}>Our Blogs</NavItem>
            <div>
            <SecondaryButton className={classes.exclusiveBtn} onClick={() => handleNavLinks("RB-Exclusive")}>RB Exclusive</SecondaryButton>
            </div>
        </div>
  </nav>;
}

export default ItemsNav;
