import { useDispatch, useSelector } from "react-redux"
import BannerHeader from "../../../Components/BannerHeader/BannerHeader"
import CustomerReview from "../../Body/CustomerReview/CutomerReview"
import Gallery from "../../Body/Gallery/Gallery"
import Footer from "../../Footer/Footer"
import PrimaryNav from "../../Header/PrimaryNav/PrimaryNav"
import { ContactUs } from "../Components/ContactUs/ContactUs"
import ServiceVideo from "../Components/ServiceVideo/ServiceVideo"
import FAQ from "../FAQ/FAQ"
import AboutForPublishers from "./AboutForPublishers/AboutForPublishers"
import classes from "./ForPublishers.module.css"
import { useEffect } from "react"
import { getAllFaq } from "../../../Store/actions/FaqAction"
import { urls } from "../../../Routes/Urls"
import useScrollToTop from "../../../Hooks/useScrollToTop";
import useScreenSize from "../../../Hooks/UseScreenSize"
const bgImage = "Assets/Images/ServicePages/ForPublishers.png"

const ForPublishers = () => {
    const dispatch = useDispatch()
    const crumbs = [
        { title: "Home", path: ("/") },
        { title: "For Publishers", path: urls.FORPUBLISHERS },
    ]
    const video = useSelector((state) => state.serviceVideo?.videos?.forPublishersVideo)
    const allFaq = useSelector(state => state.Faq.questions);

    const {isSmallScreen} = useScreenSize()

    useEffect(() => {
        getAllFaq("category","For Publishers","",dispatch)
    },[])
    const scrollRef = useScrollToTop();

    return (
        <div ref={scrollRef}>
            <PrimaryNav />
            <BannerHeader 
            bgImage={bgImage}
            crumbs={crumbs} title={"For Publishers"} desc={"Unite with us in a shared commitment to excellence"} />

            <ServiceVideo
                title={"Let’s Start Publishing Together"}
                videoSrc={video}
                desc={isSmallScreen ? "Our objective is to establish a long-lasting and reliable partnership with publishers so that they may provide their elite writers with the exclusive option of getting their writings turned into luxurious leather-bound editions." :
                    "We provide publishing houses with a special collaboration option that grants them access to our excellent bookbinding services. Our objective is to establish a long-lasting and reliable partnership with publishers so that they may provide their elite writers with the exclusive option of getting their writings turned into luxurious and premium masterpieces."
                }
            />

            <AboutForPublishers />
            <div className={classes.join}>
                <h2>
                    Join Hands With Us
                </h2>
                <p>
                Find out how Rare Biblo's superior bookbinding services might improve your publishing offers.<br/> <br/> Come celebrate the craft of bookmaking with us as we provide your writers and authors with premium quality leather-bound handcrafted customized books that are exceptional in terms of elegance and quality.
                Would you like a partnership with us? <br/> <br/> To get in touch with us and find out more about how we may collaborate, click the link below.

                </p>
            </div>

            <div className={classes.contactUs}>
                <ContactUs title={"Transform Your Publications—Contact Us Today"} />   
            </div>

            <Gallery />

            <CustomerReview />

            <FAQ data={allFaq} />
           

            <Footer />
        </div>
    )
}

export default ForPublishers