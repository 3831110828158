import ButtonLight from "../../../../Components/Buttons/ButtonLight/ButtonLight"
import PrimaryButton from "../../../../Components/Buttons/PrimaryButton/PrimaryButton"
import PrimaryInput from "../../../../Components/Inputs/PrimaryInput/PrimaryInput"
import Modal, { ModalBody, ModalFooter } from "../../../../Components/Modal/Modal"
import classes from "./DesignNameModal.module.css"

const DesignNameModal = ({designName,setDesignName,onSave,onClose}) => {
    return (
        <Modal title={"Save Customization Design"} onClose={onClose}>
            <ModalBody>
                <div className={classes.container}>
                    <PrimaryInput value={designName} onChange={e => setDesignName(e.target.value)} label={"Enter Design Name"} />
                </div>
            </ModalBody>
            <ModalFooter>
                <div className={classes.footerAction}>
                    <PrimaryButton disabled={!designName} onClick={onSave}>Save</PrimaryButton>
                    <ButtonLight onClick={onClose}>Cancel</ButtonLight>
                </div>
            </ModalFooter>
        </Modal>
    )
}

export default DesignNameModal;