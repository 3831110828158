import { useDispatch, useSelector } from "react-redux";
import BannerHeader from "../../../Components/BannerHeader/BannerHeader";
import CustomerReview from "../../Body/CustomerReview/CutomerReview";
import Gallery from "../../Body/Gallery/Gallery";
import Footer from "../../Footer/Footer";
import PrimaryNav from "../../Header/PrimaryNav/PrimaryNav";
import { ContactUs } from "../Components/ContactUs/ContactUs";
import ServiceVideo from "../Components/ServiceVideo/ServiceVideo";
import FAQ from "../FAQ/FAQ";
import AboutForPublishers from "./AboutForPublishers/AboutForPublishers";
import classes from "./BecomePartner.module.css";
import { useEffect } from "react";
import { getAllFaq } from "../../../Store/actions/FaqAction";
import { urls } from "../../../Routes/Urls";
import useScrollToTop from "../../../Hooks/useScrollToTop";
import useScreenSize from "../../../Hooks/UseScreenSize";
import PrimaryButton from "../../../Components/Buttons/PrimaryButton/PrimaryButton";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const bgImage = "Assets/Images/ServicePages/BecomOurPartner.png"

const BecomePartner = () => {
  const dispatch = useDispatch();
  const history = useHistory()
  const crumbs = [
    { title: "Home", path: "/" },
    { title: "Become Our Partner", path: urls.BECOMEPARTNER },
  ];
  const video = useSelector(
    (state) => state.serviceVideo?.videos?.becomeOurPartnerVideo
  );
  const allFaq = useSelector((state) => state.Faq.questions);

  const {isSmallScreen} = useScreenSize()

  useEffect(() => {
    getAllFaq("category", "For Booksellers / Become our Partner", "", dispatch);
  }, []);

  const scrollRef = useScrollToTop();

  return (
    <div ref={scrollRef}>
      <PrimaryNav />
      <BannerHeader
        crumbs={crumbs}
        title={"Become Our Partner"}
        bgImage={bgImage}
        desc={ isSmallScreen ? 'Turning possibilities into realities' :
          "Unite with us in a shared commitment to excellence, where every book is a testament to the craftsmanship and the enduring power of stories."
        }
      />

      <ServiceVideo
        title={"For Booksellers"}
        videoSrc={video}
        desc={"Rare Biblio specializes in crafting exquisite leather-bound books that are fully customizable and meticulously hand-crafted. \n \n Renowned for their superior quality, timeless design, and classic elegance, these books stand as a testament to unparalleled craftsmanship. \n \n Working with us will enable you to provide your customers with a unique selection of beautiful leather-bound books that will win them over and add value to their libraries."}
      />

      <AboutForPublishers />
      <div className={classes.join}>
        <h2>How to Become Our Partner</h2>
        <p>
          To show your interest in joining us as a partner, get in touch with us
          by sending us a message at <a href="mailto:info@rarebiblio.com">info@rarebiblio.com</a>. <br/> <br/> We'll arrange an
          introductory meeting to talk about how we can best collaborate and
          understand your needs.
        </p>
      </div>

      <div className={classes.contactUs}>
        <ContactUs title={"Partner With Us – Let's Collaborate!"} />
      </div>

      <div className={classes.join}>
        <h2>Explore Our Catalog:</h2>
        <p>
        You can go on and check our catalog section where you can get a glimpse of our collection of books with leather bindings and choose the ones that appeal to your target audience. 
        . <br/> <br/> We'll walk you through the picking process and make suggestions according to the features of your shop.
        </p>

        <PrimaryButton className={classes.explore} onClick={() => history.push(urls.BESTSELLER)}>Explore Our Catalog</PrimaryButton>
      </div>

      <Gallery />

      <CustomerReview />

      <FAQ data={allFaq} />
     

      <Footer />
    </div>
  );
};

export default BecomePartner;
