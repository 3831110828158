import classes from "./InnerBorder.module.css";
import Slider from "react-slick";
import { useState, useEffect, useContext } from "react";
import { fabric } from "react-fabric-page";
import { useFabricCanvas } from "react-fabric-page";
// import { addImageToCanvas } from "../../../../objectUtils";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleUp, faAngleDown, faPlusCircle, faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import useScreenSize from "../../../../Hooks/UseScreenSize";
import ToolTip from "../../../../Components/ToolTip/ToolTip";
import {
  getBorderDesigns,
} from "../../../../Store/actions/ColorsAction";
import OptionCapsules from "../../../../Components/OptionCapsules/OptionCapsules";
import { CustomizationContext } from "../../Customization";
import ColorSelector from "../../Components/ColorSelector/ColorSelector";
import { addImageToLine } from "../../../../objectUtils";
import { useSelector } from "react-redux";

const innerBorderTabs = [
  {
    title: "None",
    value: "none",
  },
  {
    title: "Embossing",
    value: "embossing",
  },
  {
    title: "Foiling",
    value: "foiling",
  },
];

const changeCoverBorder = async (border, canvas, bookSize) => {
  let imageUrl = '';
  switch (bookSize) {
    case 'F1':
      imageUrl = border?.designImage1;
      break;
    case 'F2':
      imageUrl = border?.designImage2;
      break;
    case 'F3':
      imageUrl = border?.designImage3;
      break;
    case 'F4':
      imageUrl = border?.designImage4;
      break;
    default:
      break;
  }

  fabric.Image.fromURL(imageUrl, async (img) => {
    if (!img) {
      return;
    }

    const foilLines = [
      { layerId: 'top-right-foil-line', lineId: 'test-line-top-right' },
      { layerId: 'bottom-right-foil-line', lineId: 'test-line-bottom-right' },
      { layerId: 'left-rectangle-foil-line', lineId: 'test-line-left-rectangle' }
    ];

    // Use async/await within a for loop to ensure each image is processed correctly
    for (const { layerId, lineId } of foilLines) {
      const layer = canvas?.getObjects()?.find((o) => o?.id === layerId);
      const line = layer?._objects?.find((o) => o.id === lineId);

      if (line) {
        const existingImage = layer?._objects?.find((obj) => obj.id === `innerBorder`);
        if (existingImage) {
          layer.remove(existingImage); 
        }
        
        // Await the image addition to ensure angle and scale are applied correctly before moving to the next one
        await addImageToLine(canvas, layer, line, img, `innerBorder`);
      }
    }

    // Render the canvas after adding all images
    canvas.renderAll();
  });
};

const InnerBorder = ({
  onClick,
  toggleDropdown,
  dropdownOpen,
  currentDropdown,
}) => {
  const {
    bookSize,
    selectedInnerBorder,
    setSelectedInnerBorder,
    selectedInnerBorderType,
    setSelectedInnerBorderType,
    InnerBorderFoilingColor, 
    setInnerBorderFoilingColor,
    bookVariant,
    userInnerBorder,setUserInnerBorder,
    selectedDesigns,
    allFoilingColors
  } = useContext(CustomizationContext);

  const { isMediumScreen } = useScreenSize();
  const customizationPricing = useSelector((state) => state.customizationPricing.prices?.[0]);
  const [allBorderDesigns, setAllBorderDesigns] = useState([]);
  const [borderDesigns, setBorderDesigns] = useState([]);


  const canvas = useFabricCanvas();

  const handleFileSelect = (event) => {
    const file = event.target.files[0]; // Get the first selected file
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const base64Data = event.target.result; 
        if(base64Data) {
          setUserInnerBorder(base64Data); // Set the file to state
        }
      }
      reader.readAsDataURL(file)
    }
  };

  const handleClick = () => {
    document.getElementById("fileInput").click();
  };

  const settings = {
    slidesToShow: borderDesigns?.length > 1 ?  2.5 : 1,
    swipeToSlide: true,
    infinite:false,
    centerPadding: "10px",
    }

  const fetchBorderDesigns = async () => {
    try {
      const res = await getBorderDesigns({});
      if (res.status === 200) {
        setAllBorderDesigns(res.data.data);
      }
    } catch (err) {}
  };

  useEffect(() => {
    fetchBorderDesigns();
  }, []);

  useEffect(() => {
    const allowedBorders = allBorderDesigns?.filter(
      (border) =>
        border.borderType === "inner" &&
        border.borderStyle === selectedInnerBorderType &&
        border.variantType === bookVariant &&
        border.disabled === false
    );
    setBorderDesigns(allowedBorders);
  }, [selectedInnerBorderType]);

  const handleClickAndSelect = () => {
    onClick();
    if (dropdownOpen) {
      toggleDropdown(false);
    } else {
      toggleDropdown(true);
    }
  };

  const changeFoilingColor = () => {
    const foilLines = [
      { layerId: 'top-right-foil-line', lineId: 'test-line-top-right' },
      { layerId: 'bottom-right-foil-line', lineId: 'test-line-bottom-right' },
      { layerId: 'left-rectangle-foil-line', lineId: 'test-line-left-rectangle' }
    ];

    if (canvas) {
      foilLines.forEach(({ layerId }) => {
        const layer = canvas?.getObjects()?.find((o) => o?.id === layerId);
        if (layer) {
          const activeObject = layer?._objects?.find((obj) => obj.id === `innerBorder`);
          if (activeObject && InnerBorderFoilingColor) {
            activeObject.filters[0] = new fabric.Image.filters.PatternColor({
              color: InnerBorderFoilingColor?.colorValue,
              convert: true,
            });
            activeObject.applyFilters();
            layer.dirty = true;
          }
          layer.dirty = true
        }
      });
      canvas.renderAll();
    }
  };

  useEffect(() => {
    if(selectedInnerBorderType === "foiling"){
      changeFoilingColor()
    }
  },[InnerBorderFoilingColor,selectedInnerBorder,selectedInnerBorderType])

  useEffect(() => {
    if (selectedInnerBorderType === "none") {
      setSelectedInnerBorderType("none");
      setSelectedInnerBorder(null);
      setInnerBorderFoilingColor(null);
      const foilLines = [
        { layerId: 'top-right-foil-line', lineId: 'test-line-top-right' },
        { layerId: 'bottom-right-foil-line', lineId: 'test-line-bottom-right' },
        { layerId: 'left-rectangle-foil-line', lineId: 'test-line-left-rectangle' }
      ];
  
      if (canvas) {
        foilLines.forEach(({ layerId, lineId }) => {
          const layer = canvas?.getObjects()?.find((o) => o?.id === layerId);
          if (layer) {
            const activeObject = layer?._objects?.find((obj) => obj.id === `innerBorder`);
            if (activeObject) {
              layer.remove(activeObject)
              layer.dirty = true;
            }
          }
        });
        canvas.renderAll();
      }
    } else {
      if(selectedDesigns?.customization?.innerBorder?.designId) {
        const selectedDesign = allBorderDesigns?.find((border) => border?.id === selectedDesigns?.customization?.innerBorder?.designId && border.disabled === false);
        if(selectedDesign) {
          (async function (params) {
            if (borderDesigns.length > 0) {
              await changeCoverBorder(selectedDesign,canvas,bookSize);
              setSelectedInnerBorder(selectedDesign);
            }
      
            if (selectedInnerBorderType === "foiling" && allFoilingColors.length > 0) {
              const selectedFoilColor = allFoilingColors?.find((color) => color?.colorName === selectedDesigns?.customization?.innerBorder?.foilingColor);
              if(selectedFoilColor) {
                setInnerBorderFoilingColor(selectedFoilColor);
                changeFoilingColor()
              } else {
                setInnerBorderFoilingColor(allFoilingColors[0]);
                changeFoilingColor()
              }
            }
          })()
        } else {
          (async function (params) {
            if (borderDesigns.length > 0) {
              await changeCoverBorder(borderDesigns[0],canvas,bookSize);
              setSelectedInnerBorder(borderDesigns[0]);
            }
      
            if (selectedInnerBorderType === "foiling" && allFoilingColors.length > 0) {
              setInnerBorderFoilingColor(allFoilingColors[0]);
              changeFoilingColor()
            }
          })()
        }
      } else {
        (async function (params) {
          if (borderDesigns.length > 0) {
            await changeCoverBorder(borderDesigns[0],canvas,bookSize);
            setSelectedInnerBorder(borderDesigns[0]);
          }
    
          if (selectedInnerBorderType === "foiling" && allFoilingColors.length > 0) {
            setInnerBorderFoilingColor(allFoilingColors[0]);
            changeFoilingColor()
          }
        })()
      }
    }

  }, [selectedInnerBorderType,allFoilingColors]);

  const handleChangeBorder = (border) => {
    if(selectedInnerBorder?.paletteImage !== border?.paletteImage ){
      (async function(params) {
        await changeCoverBorder(border,canvas,bookSize);
        setSelectedInnerBorder(border);
      })()
    }
  };

  const handleColorChange = (color,e) => {
    e.stopPropagation()
    setInnerBorderFoilingColor(color)
}

useEffect(() => {
  if(selectedDesigns?.customization?.innerBorder?.style) {
    setSelectedInnerBorderType(selectedDesigns?.customization?.innerBorder?.style)
  }
},[selectedDesigns,allBorderDesigns])


  return (
    <div>
      {isMediumScreen ? (
            <div onClick={(e) => e.stopPropagation()}>
            <div className={classes.innerBorderTypes}>
              <div className={classes.innerBorderTypePreview}>
                <div className={classes.innerBorderType}>
                  {innerBorderTabs.map((option) => (
                    <OptionCapsules
                      key={option.title}
                      option={option.title}
                      active={selectedInnerBorderType === option.value}
                      onClick={() => setSelectedInnerBorderType(option.value)}
                    />
                  ))}
                </div>

                {selectedInnerBorderType !== "none" && (
                  <div className={classes.previewContainer}>
                    <Slider {...settings}>
                    {borderDesigns?.map((border) => (
                      <div
                        key={border?.designName}
                        className={`${classes.previewCard} ${
                          selectedInnerBorder?.designName ===
                          border?.designName
                            ? classes.activePreviewCard
                            : ""
                        }`}
                        onClick={() => handleChangeBorder(border)}
                      >
                        <img src={border?.paletteImage} alt="" />
                      </div>
                    ))}
                    </Slider>
                  </div>
                )}

                {selectedInnerBorderType === "foiling" && borderDesigns?.length > 0 && (
                  <div className={classes.allFoilingColorsection}>
                    <h2>Select Foiling Color</h2>
                    <ColorSelector
                      availableColors={allFoilingColors}
                      selectedColor={InnerBorderFoilingColor}
                      handleColorChange={handleColorChange}
                    />
                  </div>
                )}
              </div>

              <div className={classes.uploadBtn} onClick={handleClick}>
                <div className={classes.addLogo}>
                  <FontAwesomeIcon
                    icon={faPlusCircle}
                    className={classes.addLogoIcon}
                  />
                  <p>Add your own design</p>
                </div>
                <div className={classes.logoPrice}>+${customizationPricing?.deluxeInnerBorderPrice}</div>
              </div>

              {/* Hidden file input to select an image */}
              <input
                type="file"
                id="fileInput"
                accept="image/*"
                style={{ display: "none" }}
                onChange={handleFileSelect} // Trigger on file selection
              />
              {/* Optional: Image preview (if image is selected) */}
              {userInnerBorder && (
                <div className={classes.imagePreview}>
                  <img
                    src={userInnerBorder} // Convert file to URL for preview
                    alt="Selected Design"
                  />
                  <FontAwesomeIcon className={classes.deleteImage} icon={faCircleXmark} onClick={() => setUserInnerBorder(null)} />
                </div>
              )}
              <p className={classes.disclaimer}>
                Disclaimer : uploaded design won’t be available for live
                preview
              </p>
            </div>
          </div>
      ) : (
        <div
          className={classes.innerBorder}
          onClick={() => handleClickAndSelect()}
        >
          <div className={classes.innerBorderHeader}>
            <div className={classes.headerText}>
              <h2>Inner Border</h2>
              <ToolTip tooltipText={"Add Inner Border on front cover"} />
            </div>
            <div className={classes.dropDownImage} onClick={toggleDropdown}>
              {dropdownOpen && currentDropdown === "Inner Border" ? (
                <FontAwesomeIcon icon={faAngleUp} />
              ) : (
                <FontAwesomeIcon icon={faAngleDown} />
              )}
            </div>
          </div>

          {dropdownOpen && currentDropdown === "Inner Border" ? (
            <div onClick={(e) => e.stopPropagation()}>
              <div className={classes.innerBorderTypes}>
                <div className={classes.innerBorderTypePreview}>
                  <div className={classes.innerBorderType}>
                    {innerBorderTabs.map((option) => (
                      <OptionCapsules
                        key={option.title}
                        option={option.title}
                        active={selectedInnerBorderType === option.value}
                        onClick={() => setSelectedInnerBorderType(option.value)}
                      />
                    ))}
                  </div>

                  {selectedInnerBorderType !== "none" && (
                    <div className={classes.previewContainer}>
                      <Slider {...settings}>
                      {borderDesigns?.map((border) => (
                        <div
                          key={border?.designName}
                          className={`${classes.previewCard} ${
                            selectedInnerBorder?.designName ===
                            border?.designName
                              ? classes.activePreviewCard
                              : ""
                          }`}
                          onClick={() => handleChangeBorder(border)}
                        >
                          <img src={border?.paletteImage} alt="" />
                        </div>
                      ))}
                      </Slider>
                    </div>
                  )}

                  {selectedInnerBorderType === "foiling" && borderDesigns?.length > 0 && (
                    <div className={classes.foilingColorSection}>
                      <h2>Select Foiling Color</h2>
                      <ColorSelector
                        availableColors={allFoilingColors}
                        selectedColor={InnerBorderFoilingColor}
                        handleColorChange={handleColorChange}
                      />
                    </div>
                  )}
                </div>

                <div className={classes.uploadBtn} onClick={handleClick}>
                  <div className={classes.addLogo}>
                    <FontAwesomeIcon
                      icon={faPlusCircle}
                      className={classes.addLogoIcon}
                    />
                    <p>Add your own design</p>
                  </div>
                  <div className={classes.logoPrice}>+${customizationPricing?.deluxeInnerBorderPrice}</div>
                </div>

                {/* Hidden file input to select an image */}
                <input
                  type="file"
                  id="fileInput"
                  accept="image/*"
                  style={{ display: "none" }}
                  onChange={handleFileSelect} // Trigger on file selection
                />
                {/* Optional: Image preview (if image is selected) */}
                {userInnerBorder && (
                  <div className={classes.imagePreview}>
                    <img
                      src={userInnerBorder} // Convert file to URL for preview
                      alt="Selected Design"
                    />
                    <FontAwesomeIcon className={classes.deleteImage} icon={faCircleXmark} onClick={() => setUserInnerBorder(null)} />
                  </div>
                )}
                <p className={classes.disclaimer}>
                  Disclaimer : uploaded design won’t be available for live
                  preview
                </p>
              </div>
            </div>
          ) : (
            <div></div>
          )}
        </div>
      )}
    </div>
  );
};

export default InnerBorder;
