import React from 'react';
import styles from './CounterButton.module.css';
import { getClasses } from '../../../Util';

const CounterButton = ({count,incrementer,decrementer,className}) => {

    return (
        <div className={getClasses(className,styles.counterContainer)}>
            <button className={styles.button} onClick={decrementer}>-</button>
            <span className={styles.count}>{count}</span>
            <button className={styles.button} onClick={incrementer}>+</button>
        </div>
    );
};

export default CounterButton;
