import { getEndPaper, getFontColor, getGildingColor } from "../../../Store/actions/ColorsAction";
import Viewer from "../../WebGLViewer/Viewer";
import classes from "./Customization.module.css";
import { useEffect, useState } from "react";
import ClosableBox from "../../../Components/ClosableBox/ClosableBox";
import { calculateCustomizationCharges } from "../../../Store/actions/CustomizationAction";

const Customization = ({ details }) => {
  const [colorData, setColorData] = useState([]);
  const [allTextColors,setAllTextColors] = useState([]);
  const [allEndpapers,setAllEndpapers] = useState([]);
  const [customizationCharges,setCustomizationCharges] = useState([])

  useEffect(()=>{
      const calculateCustomization = async () => {
        const res = await calculateCustomizationCharges(details?.customization);
          setCustomizationCharges(res.data.data)
      }
      calculateCustomization();
    },[])

  const getBorderStyle = (name) => {
    switch (name) {
      case "none":
        return "None";
      case "embossing":
        return "Embossing";
      case "foiling":
        return "Foiling";
      default:
        return "";
    }
  };

  const getColor = async () => {
    let filter = {};
    try {
      const res = await getGildingColor(filter);
      if (res.status === 200) {
        setColorData(res.data?.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getTextColor = async () => {
      let filter = {
        where : {
          disabled: false
        }
      };
  
      try {
        const res = await getFontColor(filter);
        if (res.status === 200) {
          setAllTextColors(
            res.data?.data
          );
        }
      } catch (err) {
        console.log(err);
      }
    };

    const getEndpapersData = async () => {
        let filter = {
          where : {
            disabled:false
          }
        };
    
        try {
          const res = await getEndPaper(filter);
          if (res.status === 200) {
            setAllEndpapers(res.data?.data);
          }
        } catch (err) {}
      };

  useEffect(() => {
    getColor();
    getTextColor();
    getEndpapersData();
  }, []);

  const getColorHex = (color) => {
    return colorData.find((data) => data.colorName === color)?.colorValue;
  };

  const textureData = [
    { src: details?.customization?.frontCoverThumbnail, target: "front" },
    { src: details?.customization?.frontCoverThumbnail, target: "back" },
    { src: details?.customization?.spineThumbnail, target: "spine" },
    {
      color: getColorHex(details?.customization?.gilding?.gildingColor),
      target: "all_pages",
    },
  ];

  const getNameFromColorId = (colorId) => {
    return allTextColors?.find((color) =>color.id === colorId)?.colorName
  }

  const getNameFromMarblePaperId = (paperId) => {
    return allEndpapers?.find((paper) => paper.id === paperId)?.designName
  }

  return (
    <div className={classes.main}>
      <div className={classes.viewerContainer}>
        <Viewer textureData={textureData} bookData={details?.book} />
      </div>
      <div className={classes.detailsContainer}>
        <ClosableBox name="Front Cover" price={customizationCharges?.frontCoverCharges?.toFixed(2)} defaultState>
          <div className={classes.box}>
            <DataField
              name="Leather Color:"
              data={details?.customization?.leatherColor || "None"}
            />
            <div className={classes.subTitle}>Outer Border:</div>
            <div className={classes.bothDataField}>
              <DataField
                name="Style:"
                data={getBorderStyle(
                  details?.customization?.outerBorder?.style
                ) || "None"}
              />
              <DataField
                name="Color:"
                data={details?.customization?.outerBorder?.foilingColor || "None"}
              />
            </div>
            <div className={classes.subTitle}>Inner Border:</div>
            <div className={classes.bothDataField}>
              <DataField
                name="Style:"
                data={getBorderStyle(
                  details?.customization?.innerBorder?.style
                ) || "None"}
              />
              <DataField
                name="Color:"
                data={details?.customization?.innerBorder?.foilingColor || "None"}
              />
            </div>
          </div>
        </ClosableBox>
        <ClosableBox name="Spine" price={customizationCharges?.spineCharges?.toFixed(2)} defaultState>
          <div className={classes.box}>
            <div className={classes.spine}>
              <DataField
                name="Rib Design:"
                data={details?.customization?.spine?.ribDesign || "None"}
              />
              <DataField
                name="Rib Design Color:"
                data={details?.customization?.spine?.ribDesignColor || "None"}
              />
            </div>
            <div className={classes.subTitle}>Sections:</div>
            {details?.customization?.spine?.sections.map((section, idx) => (
              <div className={classes.section}>
                <div>{idx + 1}.</div>
                <div className={classes.sectionContainer}>
                  {!section?.image?.image && (
                    <DataField name="Color:" data={section?.color} />
                  )}
                  {!section?.image?.image && (
                    <DataField name="Text:" data={section?.text?.text} />
                  )}
                  {!section?.image?.image && (
                    <DataField name="Text Color:" data={getNameFromColorId(section?.text?.color)} />
                  )}
                </div>
              </div>
            ))}
          </div>
        </ClosableBox>
        <ClosableBox name="Paper" price={customizationCharges?.gildingCharges?.toFixed(2)} defaultState>
           <div className={classes.box}>
           <DataField name="Gilding Color:" data={details?.customization?.gilding?.gildingColor || "None"} />
           </div>
        </ClosableBox>
        <ClosableBox name="Endpaper" price={(customizationCharges?.endpaperCharges + customizationCharges?.dedicationPageCharges)?.toFixed(2)} defaultState>
        <div className={classes.box}>
           <DataField name="Name:" data={getNameFromMarblePaperId(details?.customization?.endpaper?.endpaper) || "None"} />
           <div className={classes.subTitle}>Dedication Page:</div>
           <div className={classes.bothDataField}>
           <DataField name="Name:" data={details?.customization?.dedication?.text?.text || "None"} />
           <DataField name="Color:" data={details?.customization?.dedication?.text?.color || "None"} />
           </div>
           </div>
        </ClosableBox>
        <ClosableBox name="Additional Request" defaultState>
           <div className={classes.box}>
           <div>{details?.customization?.additionalRequest?.requestText || "None"} </div>
           </div>
        </ClosableBox>
      </div>
    </div>
  );
};

export default Customization;

const DataField = ({ name, data }) => {
  return (
    <div className={classes.dataField}>
      <div className={classes.name}>{name}</div>
      <div className={classes.data}>{data}</div>
    </div>
  );
};
