import React from 'react';
import classes from './BlogCardLarge.module.css'
import useScreenSize from '../../../Hooks/UseScreenSize';
const flagPlaceholder = "/Assets/Images/flag_placeholder.png"

function BlogCardLarge({ image, date, title, authorName, onClick, category,desc,countryCode }) {
  const {breakPoint} = useScreenSize();
  return (
    <div className={classes.cardContainer} onClick={onClick}>
      <div className={classes.imageContainer}>
        <img src={image} alt="Article" className={classes.image} />
        <div className={classes.categoryTag}>{category}</div>
      </div>
      <div className={classes.cardContent}>
        <p className={classes.date}>{date}</p>
        <h3 className={classes.title}>{title}</h3>
        {breakPoint === "xs" ?(
           <p className={classes.desc}>{desc?.length >=400 ? `${desc?.slice(0,400)}...` : desc}</p>
        ) : (
        <p className={classes.desc}>{desc}</p>)}
        <div className={classes.authorSection}>
          {/* <img src={flagPlaceholder} alt={authorName} className={`${classes.authorImage} ${classes.flag} ${classes[`flag-${countryCode}`]}`} /> */}
          <i class={`${classes.flag} ${classes[`flag-64`]} ${classes[`flag-${countryCode}`]}`}></i>
          <div className={classes.authorNameDetails}>
            <h2 className={classes.authorName}>{authorName}</h2>  
            <p>Writer</p>
          </div>
        </div>
      </div>
    </div>
      );
}

export default BlogCardLarge;
