import {
  PayPalScriptProvider,
  PayPalButtons,
} from "@paypal/react-paypal-js";
import Modal, { ModalBody } from "../../Components/Modal/Modal";
import { createOrder } from "../../Store/actions/OrdersActions";
import classes from "./PayPal.module.css";

const PayPalCard = ({ billingAddress,onClose }) => {
  const url = window.location.href;
  const domainName = url.substring(0, url.lastIndexOf(`/`));

  function create() {
    return createOrder(billingAddress,"paypal").then((order) => {
      return order.data.orderId;
    });
  }
  function onApprove(data) {
    if (data.paymentID) {
      window.location.href = `${domainName}/payment.html?payment_intent=${data.paymentID}`;
    }
  }

  return (
    <Modal className={classes.modal} title="Payment" onClose={onClose}>
      <ModalBody>
        <div className={classes.main}>
          <PayPalScriptProvider
            options={{
              clientId:
                "AR7hl6PArQaOYeZihe3JYNIq-3YBHQ91Ax7g48ZrsYT70eHULtGCGFJ3_rzc2CbvlTLN3rEGLrIsoznj",
            }}
          >
            <PayPalButtons createOrder={create} onApprove={onApprove} />
          </PayPalScriptProvider>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default PayPalCard;
