import { useEffect, useState } from "react";
import classes from "./SavedCustomizations.module.css";
import {useSelector } from "react-redux";
import { countCustomizationDesign, deleteCustomizationDesign, getAllUserDesigns } from "../../../Store/actions/CustomizationAction";
import OptionCard from "../../Customization/Components/OptionCard/OptionCard";
import PrimaryButton from "../../../Components/Buttons/PrimaryButton/PrimaryButton";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { urls } from "../../../Routes/Urls";

const createNew = "/Assets/Images/customization/create-new-design.svg";

const pageSize = 16; // Number of items per page

const SavedCustomizations = () => {
  const history = useHistory();
  const currentUser = useSelector(state => state.user.currentUser);
  const [savedDesigns, setSavedDesigns] = useState([]);
  const [selectedDesigns, setSelectedDesigns] = useState(null);
  const [allDesignsCount, setAllDesignsCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  // Calculate the total number of pages
  const totalPages = Math.ceil(allDesignsCount / pageSize);

  useEffect(() => {
    if (currentUser?.id) {
      // Fetch designs for the current page
      getAllUserDesigns(
        currentUser.userId,
        null,
        currentPage,
        pageSize,
      ).then((res) => {
        if (res.data.success) {
          setSavedDesigns(res.data.data);
        }
      });

      // Fetch total design count only once
      countCustomizationDesign({
        userId: currentUser.userId,
      }).then((res) => {
        if (res.data.success) {
          setAllDesignsCount(res.data.data);
        }
      });
    }
  }, [currentUser, currentPage]);

  const handleCustomizationRedirect = () => {
    if(selectedDesigns?.title) {
      history.push({
        pathname: `${urls.CUSTOMIZATION}/${selectedDesigns?.bookId}`,
        state: { design: selectedDesigns },
      });
    }
  }

  useEffect(() => {
    handleCustomizationRedirect()
  },[selectedDesigns])

  const handleDelete = (designId) => {
    deleteCustomizationDesign(designId).then((res) => {
      if (res.data.success) {
        // Refetch designs after deletion
        getAllUserDesigns(
          currentUser.userId,
          null,
          currentPage,
          pageSize
        ).then((res) => {
          if (res.data.success) {
            setSavedDesigns(res.data.data);
          }
        });
        countCustomizationDesign({
          userId: currentUser.userId,
        }).then((res) => {
          if (res.data.success) {
            setAllDesignsCount(res.data.data);
          }
        });
      }
    });
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  return (
    <div className={classes.main}>
      <div className={classes.title}>Saved Designs</div>
      <div className={classes.container}>
          <p className={classes.text}>Here are your saved designs. Review them or pick up where you left off to continue customizing your book.</p>
        <div className={classes.cardsSection}>
          {savedDesigns?.map((card, index) => (
            <div className={classes.OptionCard} key={index}>
              <OptionCard
                className={classes.savedDesigns}
                image={card?.frontThumbnail || createNew}
                desc={card?.title}
                active={selectedDesigns?.title === card.title}
                allowDelete={true}
                onDelete={() => handleDelete(card?.id)}
                onClick={() => setSelectedDesigns(card)}
                wantThumbnail={true}
              />
              <p>{card?.bookTitle}</p>
            </div>
          ))}
        </div>

        {/* Pagination controls */}
        <div className={classes.pagination}>
          <PrimaryButton
            className={classes.pageButton}
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          >
            Previous
          </PrimaryButton>
          <span className={classes.pageInfo}>
            Page {currentPage} of {totalPages}
          </span>
          <PrimaryButton
            className={classes.pageButton}
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            Next
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
};

export default SavedCustomizations;
