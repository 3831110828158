import React, { useEffect, useState } from "react";
import classes from "./CustomDropdown.module.css"; // Import CSS module
import { getClasses } from "../../../Util";

const flagPlaceholder = "/Assets/Images/flag_placeholder.png";

const CustomDropdown = ({ className,CountryCodeList,setCountry,country}) => {
  const [selectedCountry, setSelectedCountry] = useState(
    CountryCodeList?.countryCode.find((item) => item.dial_code === country) || "Country Code"
  );

  const [isOpen, setIsOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredCountries, setFilteredCountries] = useState(CountryCodeList || []);

  const handleSelect = (item) => {
    setSelectedCountry(item);
    setCountry(item?.dial_code)
    setIsOpen(false); // Close the dropdown
  };

    useEffect(() => {
      const foundCountry = CountryCodeList?.countryCode.find(
          (item) => item.dial_code === country
      );
      if (foundCountry) {
          setSelectedCountry(foundCountry);
          setCountry(foundCountry.dial_code);
      }
  }, [country, CountryCodeList, setCountry])


  // Filter countries based on search query
  useEffect(() => {
    if (searchQuery.trim()) {
      const filtered = CountryCodeList?.countryCode.filter(
        (item) =>
          item.code.toLowerCase().includes(searchQuery.toLowerCase()) ||
          item.dial_code.includes(searchQuery) ||
          item.name?.toLowerCase().includes(searchQuery.toLowerCase())
      );
      if(filtered?.length > 0) {
        setFilteredCountries(filtered?.length > 0 ? filtered : CountryCodeList?.countryCode);
      } else {
        setFilteredCountries(CountryCodeList?.countryCode);
        setSearchQuery("");
      }
    } else {
      setFilteredCountries(CountryCodeList?.countryCode || []);
    }
  }, [searchQuery, CountryCodeList]);


  // Listen for keypress events when dropdown is open
  useEffect(() => {
    if (!isOpen) return;

    const handleKeyPress = (event) => {
      setSearchQuery((prev) => prev + event.key);
    };

    const handleBackspace = (event) => {
      if (event.key === "Backspace") {
        setSearchQuery((prev) => prev.slice(0, -1));
      }
    };

    document.addEventListener("keypress", handleKeyPress);
    document.addEventListener("keydown", handleBackspace);

    return () => {
      document.removeEventListener("keypress", handleKeyPress);
      document.removeEventListener("keydown", handleBackspace);
    };
  }, [isOpen]);


  return (
    <div className={getClasses(className,classes.dropdown)}>
      <div className="buttonContainer">
        <p
          className={classes.dropdownButton}
          onClick={(e) => {
            e.preventDefault();
            setIsOpen(!isOpen);
          }}
        >
          {selectedCountry?.code ? (
            <img
              src={flagPlaceholder}
              className={` ${classes.flag} ${
                classes[`flag-${selectedCountry.code.toLowerCase()}`]
              }`}
            />
          ) : (
            " "
          )}{" "}
          {selectedCountry?.dial_code? selectedCountry.dial_code : selectedCountry}
        </p>
      </div>
      {isOpen && (
        <div
          className={classes.dropdownMenu}
          onMouseLeave={() => {setIsOpen(false); setSearchQuery("")}}
        >
          {filteredCountries?.map((item, idx) => (
            <div
              key={idx}
              className={classes.dropdownItem}
              onClick={() => handleSelect(item)}
            >
              <img
                src={flagPlaceholder}
                className={` ${classes.flag} ${
                  classes[`flag-${item.code.toLowerCase()}`]
                }`}
              />
              &nbsp;&nbsp;
              <span> {item.code}</span> &nbsp;&nbsp;{" "}
              <span>{item.dial_code}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CustomDropdown;
