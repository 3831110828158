import React, { useState } from "react";
import Modal, { ModalBody, ModalFooter } from "../Modal/Modal";
import classes from "./verificationPendingModal.module.css";
import PrimaryButton from "../Buttons/PrimaryButton/PrimaryButton";
import ButtonLight from "../Buttons/ButtonLight/ButtonLight";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHourglassHalf } from "@fortawesome/free-solid-svg-icons";
import { useDispatch } from "react-redux";
import { verificationPendingActions } from "../../Store/Slices/VerificationPendingSlice";
import axios from "axios";
import { SERVICE_URL } from "../../Store/ServiceUrls";

function VerificationPendingModal() {
  const dispatch = useDispatch();
  const [buttonText, setButtonText] = useState("Resend Link");
  const [isSending, setIsSending] = useState(false);

  const handleClose = () => {
    dispatch(verificationPendingActions.hideMessage());
  };

  const handleResendCode = () => {
    setIsSending(true); // Start sending
    setButtonText("Sending...");

    axios({
      url: SERVICE_URL.RESENDCODE,
      method: "post",
    })
      .then(() => {
        setButtonText("Sent!");
        setTimeout(() => {
          setButtonText("Resend Link"); // Reset text after 2 seconds
          setIsSending(false); // Reset sending state
        }, 2000); // 2 seconds to show "Sent"
      })
      .catch(() => {
        setButtonText("Failed to Send");
        setTimeout(() => {
          setButtonText("Resend Link"); // Reset text after 2 seconds
          setIsSending(false); // Reset sending state
        }, 2000); // Reset to original state after 2 seconds
      });
  };

  return (
    <Modal title="Email Verification Pending">
      <ModalBody>
        <div className={classes.message}>
          <FontAwesomeIcon icon={faHourglassHalf} className={classes.waitIcon} />
          <p>
            Your account is almost ready! Please verify your email to proceed.
            <br />
            A verification link was sent to your registered email address.
          </p>
        </div>
      </ModalBody>
      <ModalFooter>
        <div className={classes.actions}>
          <ButtonLight className={classes.btn} onClick={handleClose} negative>
            Close
          </ButtonLight>
          <PrimaryButton
            className={`${classes.btn} ${isSending ? classes.sendingButton : ""}`}
            onClick={handleResendCode}
            disabled={isSending}
          >
            {buttonText}
          </PrimaryButton>
        </div>
      </ModalFooter>
    </Modal>
  );
}

export default VerificationPendingModal;
