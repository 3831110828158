import classes from "./Faq.module.css"
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Header from "../Header/Header";
import BannerHeader from "../../Components/BannerHeader/BannerHeader";
import FAQCard from "./FAQCard/FAQCard";

import { useDispatch } from "react-redux";
import { getAllCategories, getAllFaq } from "../../Store/actions/FaqAction";
import { urls } from "../../Routes/Urls";
import useScrollToTop from "../../Hooks/useScrollToTop";
import Footer from "../Footer/Footer";
import NextArrow, { PrevArrow } from "../../Components/Arrows/Arrows";
import Slider from "react-slick";

function RightArrow(props) {
    const { onClick } = props;
    return (
      <NextArrow className={classes.rightArrow} onClick={onClick} />
    );
  }

function LeftArrow(props) {
    const { onClick } = props;
    return (
      <PrevArrow className={classes.leftArrow} onClick={onClick} />
    );
  }

const FAQ = () => {

    const dispatch = useDispatch()
    const faqs = useSelector((state) => state.Faq.questions);
    const categories = useSelector((state) => state.Faq.categories);
    const [activeTab,setActiveTab] = useState(categories[0] || "");
    const [selectedCategory,setSelectedCategory] = useState("")
    const onTabClick = (tab) => {
        if(selectedCategory === tab) {
          setSelectedCategory("")
          setActiveTab("")
        } else {
        setActiveTab(tab)
        setSelectedCategory(tab)
        }
    }

    const crumbs  = [
        {title:"Home",path:"/"},
        {title:"FAQ",path:urls.FAQ},
    ];

    useEffect(() => {
        getAllFaq("category", selectedCategory, "", dispatch);
        getAllCategories(dispatch);
    },[activeTab])

    const settings = {
        dots: false,
        arrows: true,
        autoplay: false,
        accessibility: true,
        infinite: false,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        centerMode: false,
        focusOnSelect: true,
        centerPadding: "0px",
        nextArrow: <RightArrow />,
        prevArrow: <LeftArrow />,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2.5,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 340,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      };
    

    const scrollRef = useScrollToTop();
    return (
      <div className={classes.mainContainer} ref={scrollRef}>
        <Header />
        <BannerHeader title={"Frequently Asked Questions"} crumbs={crumbs} />
        {categories.length > 0 && <div className={classes.tabs}>
          <Slider className={classes.Slider} {...settings}>
            {categories.map((tab, index) => (
              <div
                key={tab}
                className={`${classes.tab} ${
                  activeTab === tab ? classes.active : ""
                }`}
                onClick={() => onTabClick(tab)}
              >
                {tab !== "For Booksellers / Become our Partner" ? tab : "Become our Partner"}
              </div>
            ))}
          </Slider>
        </div>}
        <div className={classes.faqContainer}>
          <div className={classes.cardContainer}>
            {faqs?.map((faq, index) => (
              <FAQCard key={index} ques={faq.question} answer={faq.answer} />
            ))}
          </div>
        </div>
        <Footer />
      </div>
    );
}

export default FAQ