import React from 'react';
import classes from './BlogCard.module.css'
import { getClasses, getFormattedDate } from '../../../../Util';
import useScreenSize from '../../../../Hooks/UseScreenSize';
const flagPlaceholder = "/Assets/Images/flag_placeholder.png"

function BlogCard({ image, date, title, authorName, desc, category,className,countryCode,onClick}) {
  return (
    <div className={getClasses(className,classes.cardContainer)} onClick={onClick}>
      <div className={classes.imageContainer}>
        <img src={image} alt="Article" className={classes.image} />
        <div className={classes.categoryTag}>{category}</div>
      </div>
      <div className={classes.cardContent}>
        <p className={classes.date}>{getFormattedDate(date)}</p>
        <h3 className={classes.title}>{title}</h3>
        {desc && <p className={classes.authorName}>{desc}</p>}
        <div className={classes.authorSection}>
        <i class={`${classes.flag} ${classes[`flag-48`]} ${classes[`flag-${countryCode}`]}`}></i>
          <div className={classes.authorNameDetails}>
            <h2 className={classes.authorName}>{authorName}</h2>
            <p>Writer</p>
          </div>
        </div>
      </div>
    </div>
      );
}

export default BlogCard;
