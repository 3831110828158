import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useScreenSize from "../../../../Hooks/UseScreenSize";
import classes from "./CartButtonSmall.module.css"
import { faCartShopping } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useContext, useEffect, useState } from "react";
import { CustomizationContext } from "../../Customization";
import { addCartItem } from "../../../../Store/actions/CartAction";
import { useDispatch } from "react-redux";
import { urls } from "../../../../Routes/Urls";
import MessageDialog from "../../../../Components/MessageDialog/MessageDialog";

const CartButtonSmall = () => {
    const history = useHistory();
    const dispatch = useDispatch()

     const {bookQty,bookData,bookCustomization,setCustomizationCharges,setDesignSaveInitiated,designSaveCompleted,setDesignSaveCompleted} = useContext(CustomizationContext)
    const [showDetailModal,setShowDetailModal] = useState(false);
    const [showMessageDialog,setShowMessageDialog] = useState(false);

    const handleAddCart = () => {
            if(bookCustomization) {
                setShowMessageDialog(true);
                // addCartItem(bookData,bookQty,bookCustomization?.variantType,bookCustomization?.leatherType,bookCustomization?.leatherColor,dispatch,bookCustomization)
            }
        }

    
        const handleMessageModalClose = () => {
            if(bookCustomization) {
                setShowMessageDialog(false);
                addCartItem(bookData,bookQty,bookCustomization?.variantType || "deluxe",bookCustomization?.leatherType || "realLeather",bookCustomization?.leatherColor,dispatch,bookCustomization);
                history.push(urls.CART)
            }
        }
    
        const handleMessageModalAccept = () => {
            if(bookCustomization) {
                setDesignSaveInitiated(true);
            }
    
            setTimeout(() => setDesignSaveCompleted(false),10000)
        }
    
    
        useEffect(() => {
            if(designSaveCompleted && showMessageDialog) {
                addCartItem(bookData,bookQty,bookCustomization?.variantType,bookCustomization?.leatherType,bookCustomization?.leatherColor,dispatch,bookCustomization,true);
                history.push(urls.CART)
                setShowMessageDialog(false);
            }
        },[designSaveCompleted])
    
    return (
      <>
        <FontAwesomeIcon className={classes.cartIcon} icon={faCartShopping} onClick={handleAddCart} />
        {showMessageDialog && (
        <MessageDialog
          message={"Would you like to save this design for future use? Select 'Save' to keep it accessible anytime. Please note that if you choose not to save the design, you will not be able to edit your customization after adding it to the cart"}
          onCancel={handleMessageModalClose}
          isCancel
          title={"Save Customization Design"}
          closeText="Don't Save and Add to Cart"
          acceptBtnText="Save and Add To Cart"
          onAccept={handleMessageModalAccept}
          onClose={() => setShowMessageDialog(false)}
        />
      )}
      </>
    )
}

export default CartButtonSmall