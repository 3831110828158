import classes from "./WhyUs.module.css"
const stepsImage = "/Assets/Images/CustomizationOverview.svg"

const cardData = [
    "Vivid Leather Color Variants",
    "Customizable Spine with text, logo design",
    "Custom border and logo design option",
    "Deluxe and super deluxe editions ",
    "Elegant marble paper for the deluxe edition",
    "Gold edged gilding"
]

const WhyUs = () => {
    return(
        <div className={classes.mainContainer}>
            <div className={classes.header}>
                <h2>Why Rare Biblio For Customization?</h2>
                <p>We make you experience the extraordinary like never before!</p>
            </div>

            <div className={classes.cardSection}>
                {cardData.map((card,index) => (
                    <div key={index} className={classes.card}>
                        <h3>{card}</h3>
                    </div>
                ))}
            </div>

            <div className={classes.imageSection}>
                <img src={stepsImage} alt="" />
            </div>

        </div>
    )
}

export default WhyUs