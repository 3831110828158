import React from "react";
import classes from "./InputModal.module.css";
import Modal, { ModalBody } from "../../../../Components/Modal/Modal";

const InputModal = ({ title, label, value, onChange, onCancel, onSubmit }) => {
  return (
    <Modal className={classes.modal}>
      <ModalBody>
        <div className={classes.modalContainer}>
          <h2 className={classes.modalTitle}>{title}</h2>
          <div className={classes.modalSubtitle}>{label}</div>
          <div>
            <label className={classes.modalLabel}>Enter text</label>
            <input
                type="text"
                className={classes.modalInput}
                placeholder="Enter Book Title"
                value={value}
                onChange={onChange}
            />
            <div className={classes.modalActions}>
                <button className={classes.modalCancel} onClick={onCancel}>
                Cancel
                </button>
                <button className={classes.modalSubmit} onClick={onSubmit}>
                Submit
                </button>
            </div>

          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default InputModal;
