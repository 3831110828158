import classes from "./MaterialVariantSelector.module.css";

import OptionCard from "../Components/OptionCard/OptionCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import SecondaryButton from "../../../Components/Buttons/SecondaryButton/SecondaryButton";
import { useContext, useEffect, useState } from "react";
import ProductDetails from "../Components/ProductDetails/ProductDetails";

import useScreenSize from "../../../Hooks/UseScreenSize";
import LeatherType from "../../ProductDetail/LeatherType/LeatherType";
import VariantType from "../../ProductDetail/VariantType/VariantType";
import {
    FabricContainer,
    useFabricCanvas,
} from "react-fabric-page";
import { FabricCanvas } from "../../Editor/FabricCanvas";
import ButtonLight from "../../../Components/Buttons/ButtonLight/ButtonLight";
import { CustomizationContext } from "../Customization";
const bookCover = "/Assets/Images/customization/bookCover.svg";
const realLeather = "/Assets/Images/customization/genuine-leather.svg";
const veganLeather = "/Assets/Images/customization/vegan-leather.svg";
const variant1 = "/Assets/Images/customization/deluxeCover.png";
const variant2 = "/Assets/Images/customization/superDeluxeCover.png";

const leatherTypeData = [
  {
    desc: "Classic Genuine Leather	",
    value: "realLeather",
    image: realLeather,
  },
  {
    desc: "Vegan Leather",
    value: "veganLeather",
    image: veganLeather,
  },
];

const variantTypeData = [
  {
    name: "Super Deluxe",
    value: "superDeluxe",
    image: variant2,
  },
  {
    name: "Deluxe",
    value: "deluxe",
    image: variant1,
  },
  
];

const MaterialVariantSelector = ({
  onNext,
  onPrev,
  changeBookVariant,
  visibility,
  bookData,
  bookVariant,
  changeLeatherType,
  BookleatherType,
  setEditor,
  editor,
  page,
}) => {
  const { isSmallScreen } = useScreenSize();
  // const [overviewVisible, setOverviewVisible] = useState(false);
  const [showLeatherModal, setShowLeatherModal] = useState(false);
  const [showVariantModal, setShowVariantModal] = useState(false);

  const {selectedDesigns,steps} = useContext(CustomizationContext);

  useEffect(() => {
    if(selectedDesigns?.id) {
      changeBookVariant(selectedDesigns?.variantType);
      changeLeatherType(selectedDesigns?.leatherType);
    }
  },[selectedDesigns])

  const handleOnNext = () => {
    // if (isSmallScreen) {
    //   setOverviewVisible(true);
    // } else {
    //   onNext();
    // }
    onNext();
  };

  useEffect(() => {
    if(!selectedDesigns?.id && !bookVariant && !BookleatherType && steps === 0) {
      changeBookVariant(variantTypeData[1].value);
      changeLeatherType(leatherTypeData[0].value);
    }
  },[steps])

  
  const handleOnPrev = () => {
    // if (isSmallScreen) {
    //   setOverviewVisible(true);
    // } else {
    //   onPrev();
    // }
    onPrev();
  };

  return (
    <FabricContainer value={editor}>
      <div
        className={classes.mainContainer}
        style={{ display: visibility ? "flex" : "none" }}
      >
        <div className={classes.detailSection}>
          <h2>Your Book Deserves an Elegant Cover!</h2>
          <div className={classes.leatherDetails}>
            <div className={classes.leatherType}>
              <h3>Pick a leather type for your book</h3>

              <p
                className={classes.detail}
                onClick={() => setShowLeatherModal(true)}
                style={{ cursor: "pointer" }}
              >
                Learn more about leather type{" "}
                <FontAwesomeIcon
                  icon={faAngleRight}
                  className={classes.moreIcon}
                />
              </p>

              <div className={classes.leatherOptions}>
                {leatherTypeData.map((card, index) => (
                  <OptionCard
                    key={index}
                    image={card.image}
                    desc={card.desc}
                    active={BookleatherType === card.value}
                    disabeled={selectedDesigns?.id ? true : false}
                    onClick={() => {
                      if (!selectedDesigns?.id) {
                        changeLeatherType(card.value);
                      }
                    }}
                  />
                ))}
              </div>
            </div>
          </div>
          <div className={classes.variantDetails}>
            <div className={classes.variantType}>
              <h3>Pick your preferred book variant!</h3>

              <p
                className={classes.detail}
                onClick={() => setShowVariantModal(true)}
                style={{ cursor: "pointer" }}
              >
                Learn more about variant type{" "}
                <FontAwesomeIcon
                  icon={faAngleRight}
                  className={classes.moreIcon}
                />
              </p>

              <div className={classes.variantOptions}>
                {variantTypeData.map((card, index) => (
                  <OptionCard
                    key={index}
                    image={card.image}
                    desc={card.name}
                    active={bookVariant === card.value}
                    onClick={() => {
                      if (!selectedDesigns?.id) {
                        changeBookVariant(card.value);
                      }
                    }}
                    disabeled={selectedDesigns?.id ? true : false}
                  />
                ))}
              </div>
            </div>
          </div>
          <div className={classes.buttonContainer}>
            <ButtonLight onClick={handleOnPrev}>Prev</ButtonLight>
            <SecondaryButton onClick={handleOnNext}>Next</SecondaryButton>
          </div>
        </div>
        {!isSmallScreen && (
          <div className={classes.imageSection}>
            <div className={classes.mainImage}>
              <img
                src={bookVariant === "deluxe" ? variant1 : variant2}
                alt=""
              />
            </div>
            <div className={classes.productDetails}>
              <ProductDetails
                bookData={bookData}
                leatherType={BookleatherType}
                variantType={bookVariant}
              />
            </div>
          </div>
        )}

        {showLeatherModal && (
          <LeatherType onClose={() => setShowLeatherModal(false)} />
        )}
        {showVariantModal && (
          <VariantType onClose={() => setShowVariantModal(false)} />
        )}
      </div>

      {/* <FabricCanvas
        onShow={setEditor}
        page={page}
        onPageMount={(e) => {
          editor.urStack = [];
          editor.currentStep = -1;
          editor.saveCurrentState();
        }}
        style={{ width: "auto" }}
      /> */}
      <div className={classes.editorSection}>
        <FabricCanvas
          onShow={setEditor}
          page={page}
          onPageMount={(e) => {
            editor.urStack = [];
            editor.currentStep = -1;
            editor.saveCurrentState();
          }}
        />

      </div>
    </FabricContainer>
  );
};

export default MaterialVariantSelector;
