import PrimaryNav from "../Header/PrimaryNav/PrimaryNav"
import classes from "./Checkout.module.css"
import { urls } from "../../Routes/Urls"
import BreadCrumb from "../../Components/BreadCrumb/BreadCrumb"
import { useState,useEffect } from "react"
import BillingAddress from "./BillingAddress/BillingAddress"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import MultipleAddress from "./MultipleAddrees/MultipleAddress"
import { useDispatch, useSelector } from "react-redux"
import { fetchAllProcessingCosts } from "../../Store/actions/ProcessingCostAction"
import OrderSummary from "./OrderSummary/OrderSummary"
import Modal from "../../Components/Modal/Modal"
import { ModalBody } from "../../Components/Modal/Modal"
import { CheckoutFormComponent } from "./CheckoutForm"
import PaymentOptionCard from "./PaymentOptionCard";
import PayPal from "./PayPal"

const paypal = "paypal";
const stripe = "stripe";

const Checkout = () => {
    const crumbs = [
        { title: "Home", path: "/" },
        { title: "Collection", path: urls.LISTING },
        { title: "Checkout", path: urls.CART },
    ]

    const dispatch = useDispatch()

    const cartItems = useSelector(state => state.cart.cartItems)
    const currentUser = useSelector((state) => state.user.currentUser);
    const userBillingAddresses = currentUser?.billingAddresses || [];

    useEffect(() => {
        fetchAllProcessingCosts(dispatch)
    },[])

    const [currentSection, setCurrentSection] = useState("shippingAddress")
    const [selectedShippingAddress, setSelectedShippingAddress] = useState({});
    const [selectedShippingSpeeds, setSelectedShippingSpeeds] = useState({});
    const [selectedBillingAddress, setSelectedBillingAddress] = useState(userBillingAddresses[0]);
    const [paymentModal,setPaymentModal] = useState(false);
    const [paymentMethod, setPaymentMethod] = useState("");

    const isDisabled = () => {
        const isAnyShippingAddressMissing = cartItems.some((address) => !address?.shippingAddress?.addressId)
        if (selectedBillingAddress && !isAnyShippingAddressMissing) {
            return false;
        }
        else {
            return true;
        }
    }

    return (
        <div>
            <PrimaryNav />

            <div className={classes.mainContainer}>
                <BreadCrumb className={classes.breadCrumbs} crumbs={crumbs} />

                <div className={classes.headerSection}>
                    <h2>Checkout</h2>
                </div>

                <div className={classes.mainBody}>
                    <div className={classes.sections}>
                        <MultipleAddress isActive={currentSection === "shippingAddress"} setActive={setCurrentSection} selectedAddress={selectedShippingAddress} setSelectedAddress={setSelectedShippingAddress} shippingSpeed={selectedShippingSpeeds} setShippingSpeed={setSelectedShippingSpeeds} />
                        <BillingAddress isActive={currentSection === "billingAddress"} shippingAddress={selectedShippingAddress} setActive={setCurrentSection} selectedAddress={selectedBillingAddress} setSelectedAddress={setSelectedBillingAddress} />
                    </div>

                    <div className={classes.sideBar}>
                        <OrderSummary  disabled={isDisabled()} onClick = {() => setPaymentModal(true)} />
                    </div>
                </div>
            </div>
            {
               paymentModal && <PaymentOptionCard onSelect={(e) => setPaymentMethod(e)} onClose={() => setPaymentModal(false)}/>
            }
            {paymentMethod === stripe && <Modal title={"Payment"} onClose={() => setPaymentMethod("")}>
                <ModalBody>
                    <div className={classes.stripePayment}>
                        <CheckoutFormComponent selectedBillingAddress={selectedBillingAddress} onClose={() => setPaymentMethod("")}/>
                    </div>
                </ModalBody>
            </Modal>}
            {paymentMethod === paypal && <PayPal billingAddress={selectedBillingAddress} onClose={() => setPaymentMethod("")} />}
        </div>
    )
}

export default Checkout