import BannerHeader from "../../../Components/BannerHeader/BannerHeader"
import PrimaryNav from "../../Header/PrimaryNav/PrimaryNav"
import ServiceVideo from "../Components/ServiceVideo/ServiceVideo"
import classes from "./LeatherBinding.module.css"

import AboutLeatherBinding from "./AboutLeatherBinding/AboutLeatherBinding"
import Gallery from "../../Body/Gallery/Gallery"
import CustomerReview from "../../Body/CustomerReview/CutomerReview"
import FAQ from "../FAQ/FAQ"
import { ContactUs } from "../Components/ContactUs/ContactUs"
import Footer from "../../Footer/Footer"
import { useDispatch, useSelector } from "react-redux"
import { useEffect } from "react"
import { fetchAllVideos } from "../../../Store/actions/ServiceVideoAction"
import BookCustomization from "./BookCustomization/BookCustomization"
import { getAllFaq } from "../../../Store/actions/FaqAction"
import { urls } from "../../../Routes/Urls"
import useScrollToTop from "../../../Hooks/useScrollToTop";
const bgImage = "/Assets/Images/ServicePages/LeatherBinding.png"

const LeatherBinding = () => {
    const crumbs = [
        {title:"Home",path:("/")},
        {title:"Leather Binding",path:urls.LEATHERBINDING},
    ]

    const allFaqs = useSelector(state => state.Faq.questions);
    const video = useSelector((state) => state.serviceVideo.videos);
    const dispatch = useDispatch();
    useEffect(() => {
        fetchAllVideos(dispatch)
        getAllFaq("category","Leather Binding","",dispatch)
    },[])

    const scrollRef = useScrollToTop();

    return(
        <div ref={scrollRef}>
            <PrimaryNav />
            <BannerHeader 
            bgImage={bgImage}
            crumbs={crumbs} title={'Online Custom Leather Book Binding.'} desc={"Leather-bound books: where stories meet legacy."}/>

            <ServiceVideo title={"Online Leather Book Binding Services"} desc={"We are happy to share our passion for finely produced leather bookbinding. We've been perfecting this traditional art for over a decade, motivated by our love of books and the timeless appeal of leather. "} videoSrc={video?.leatherBindingVideo}/>

            <AboutLeatherBinding />

            <BookCustomization />

            <Gallery />

            <CustomerReview />

            <FAQ data={allFaqs} />

            <div className={classes.contactUs}>
                <ContactUs />
            </div>

            <Footer />
        </div>
    )
}

export default LeatherBinding