import classes from "./ProductDetail.module.css";
import Header from "../Header/Header";
import BreadCrumb from "../../Components/BreadCrumb/BreadCrumb";
import { useEffect, useState, useRef } from "react";
import SecondaryButton from "../../Components/Buttons/SecondaryButton/SecondaryButton";
import FeatureCard from "./FeatureCard/FeaturerCard";
import AboutBook from "./AboutBook/AboutBook";
import Reviews from "./Reviews/Reviews";
import { addCartItem } from "../../Store/actions/CartAction";
import Recommendations from "./Recommendation/Recommendations";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faAngleLeft, faArrowRight, faHeart as faHeartFilled } from "@fortawesome/free-solid-svg-icons";
import { faHeart } from "@fortawesome/free-regular-svg-icons";
import {
  useHistory
} from "react-router-dom/cjs/react-router-dom.min";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { fetchBookById } from "../../Store/actions/BookAction";
import { useDispatch, useSelector } from "react-redux";
import Footer from "../Footer/Footer";
import { urls } from "../../Routes/Urls";
import LeatherType from "./LeatherType/LeatherType";
import VariantType from "./VariantType/VariantType";
import Metatag from "../../Components/Metatag";
import ButtonLight from "../../Components/Buttons/ButtonLight/ButtonLight";
import { addWishlistItem, deleteWishlistItem } from "../../Store/actions/WishlistAction";
import { getBookReviews } from "../../Store/actions/BookReviewAction";
import { CartActions } from "../../Store/Slices/CartSlice";
import { generateUUID } from "three/src/math/MathUtils.js";
import { setIsOpen } from "../../Store/Slices/ToggleSlice";
import useScreenSize from "../../Hooks/UseScreenSize";

const sewn = "/Assets/Images/pdp/sewn.png";
const notSprayed = "/Assets/Images/pdp/notSprayed.png";
const acidFree = "/Assets/Images/pdp/acidfree.png";
const goldPrinting = "/Assets/Images/pdp/goldFoil.png";
const goldEmbossing = "/Assets/Images/pdp/goldEmbossing.png";
const ribbonBookmark = "/Assets/Images/pdp/ribbonBookmark.png";
const marbleEndpaper = "/Assets/Images/pdp/marbleEnpaper.png";

const tabsData = ["About the book", "Reviews"];

const features = [
  {
    image: sewn,
    title: "Sewn Pages",
    desc: "Pages sewn not glued",
  },
  {
    image: acidFree,
    title: "Acid Free",
    desc: "Acid free natural shade paper",
  },
  {
    image: goldPrinting,
    title: "Gold Foil Printing",
    desc: "Gold Foil Printing on hubbed spine",
  },
  {
    image: goldEmbossing,
    title: "Gold Foil Embossing",
    desc: "Gold Foil embossing on frontcover",
  },
  {
    image: notSprayed,
    title: "Not Sprayed",
    desc: "Gold Foil gliding of edges are not sprayed",
  },
  {
    image: ribbonBookmark,
    title: "Ribbon Bookmark",
    desc: "leather bound book with ribbon bookmark",
  },
  {
    image: marbleEndpaper,
    title: "Marble Endpapers",
    desc: "Colored Marbled Endpapers",
  },
];

const VariantTabs = [
  {
    name: "Deluxe Edition",
    value: "deluxe",
  },
  {
    name: "Super Deluxe Edition",
    value: "superDeluxe",
  },
];

const LeatherTabs = [
  {
    name: "Genuine Leather",
    value: "realLeather",
  },
  {
    name: "Vegan Leather",
    value: "veganLeather",
  },
];

const ProductDetail = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const history = useHistory();
    const bookData = useSelector((state) => state.books.book[0]);
    const currentUser = useSelector((state) => state.user.currentUser);
    const wishlist = useSelector((state) => state.wishlist.wishlistItems);
  
    const [showLeatherType, setShowLeatherType] = useState(false);
    const [showVariantType, setShowVariantType] = useState(false);
    const {isSmallScreen} = useScreenSize();

  
    useEffect(() => {
      fetchBookById(id, dispatch);
    }, [id, dispatch]);
  
    const crumbs = [
      {
        title: "Home",
        path: "/",
      },
      {
        title: "Collection",
        path: "/listing",
      },
      {
        title: bookData?.title,
        path: `${urls.PRODUCTDETAIL}/${id}`,
      },
    ];
  
    const allColorsData = useSelector((state) => state.bookColor.colors);
    const [currentPhotoIndex, setCurrentPhotoIndex] = useState(0);
    const [activeVariant, setActiveVariant] = useState(VariantTabs[1]);
    const [activeLeather, setActiveLeather] = useState(LeatherTabs[0]);
    const [qty, setQty] = useState(1);
    const [activeTab, setActiveTab] = useState(0);
  
    const allowedColors = allColorsData?.filter(
      (color) =>
        color.leatherType === activeLeather.value &&
        color.variantType === activeVariant.value && 
        color.isBestSeller === false &&
        color.disabled === false
    );
  
    const [selectedColor, setSelectedColor] = useState(allowedColors[0]);
  
    useEffect(() => {
      const defaultColor = allColorsData?.find(
        (color) =>
          color.leatherType === activeLeather.value &&
          color.variantType === activeVariant.value
      );
      setSelectedColor(defaultColor || allowedColors[0]);
    }, [activeLeather, activeVariant, allColorsData]);
  
    // Initialize images as an empty array
    const [images, setImages] = useState([]);
    const [currentPhoto, setCurrentPhoto] = useState("");
  
    const updateImagesBasedOnBookData = () => {
      let newImages = []; // Create an empty array
  
      // Populate the array with the default selectedColor images
      if (selectedColor) {
        newImages = [
          selectedColor?.baseImg,
          selectedColor?.sideImg1,
          selectedColor?.sideImg2,
          selectedColor?.sideImg3,
          selectedColor?.sideImg4,
        ];
      }
  
      // If bookData has images and the key exists, update the images based on the key
      if (bookData && bookData.images && selectedColor && activeLeather) {
        const key = `${selectedColor?.colorName}_${activeVariant?.value}_${activeLeather?.value}`;
    
        if (doesKeyExist(bookData.images, key)) {
          const imagesObj = JSON.parse(bookData.images);

          // Find the actual matching key
          const matchedKey = Object.keys(imagesObj).find(
            (existingKey) => existingKey.toLowerCase() === key.toLowerCase()
          );
          const bookImages = JSON.parse(bookData.images)[matchedKey]?.links;

          newImages = newImages.map((img, index) =>
            bookImages[index] !== "" ? bookImages[index] : img
          );
        }
      }
  
      // Finally, update the state with the populated images array
      setImages(newImages);
      // Set the current photo to the first image
      if (newImages.length > 0) {
        setCurrentPhoto(newImages[0]);
        setCurrentPhotoIndex(0); // Reset photo index to 0 when images update
      }
    };

    const getBookDataAlt = () => {
        return selectedColor?.altText;
      };
    
  
    useEffect(() => {
      if (bookData) {
        updateImagesBasedOnBookData();
      }
    }, [bookData, selectedColor, activeLeather, activeVariant]);
  
    useEffect(() => {
      if (images.length > 0) {
        setCurrentPhoto(images[currentPhotoIndex]);
      }
    }, [currentPhotoIndex, images]);
  
    const handleLeftArrowClick = () => {
        let newIndex = currentPhotoIndex;
        do {
          newIndex = newIndex === 0 ? images.length - 1 : newIndex - 1;
        } while (images[newIndex] === "" || images[newIndex] === null);
    
        setCurrentPhotoIndex(newIndex);
      };
    
      // Handle Right Arrow Click
      const handleRightArrowClick = () => {
        let newIndex = currentPhotoIndex;
        do {
          newIndex = newIndex === images.length - 1 ? 0 : newIndex + 1;
        } while (images[newIndex] === "" || images[newIndex] === null);
    
        setCurrentPhotoIndex(newIndex);
      };
  
    const handleSetActiveLeather = (type) => {
      setActiveLeather(type);
    };
  
    const handleSetActiveVariant = (type) => {
      setActiveVariant(type);
    };
  
    const handleQtyDecrease = () => {
      if (qty > 1) {
        setQty(qty - 1);
      }
    };
    const handleQtyIncrease = () => {
      setQty(qty + 1);
    };

    const getItemPrice = (bookData,leatherType,variantType) => {
      if(bookData && leatherType && variantType) {
        if(variantType === "deluxe") {
          if(leatherType === "veganLeather") {
            return bookData?.deluxePriceVegan
          } else {
            return bookData?.deluxePriceGenuine
          }
        } else if(variantType === "superDeluxe") {
          if(leatherType === "veganLeather") {
            return bookData?.superDeluxePriceVegan
          } else {
            return bookData?.superDeluxePriceGenuine
          }
        }
      }
    }
  
    const addToCart = () => {
      const cartItem = {
        id:generateUUID(),
        book: bookData, // or bestSellerBook depending on your logic
        quantity: qty,
        variantType: activeVariant.value,
        leatherType: activeLeather.value,
        color: selectedColor?.colorName || "",
        itemPrice:getItemPrice(bookData,activeLeather.value,activeVariant.value)
      };
      if (currentUser?.id) {
        addCartItem(
          bookData,
          qty,
          activeVariant.value,
          activeLeather.value,
          selectedColor?.colorName || "",
          dispatch
        );
        dispatch(setIsOpen(true));
        
      } else {
        // history.push(urls.LOGIN, { from: history.location.pathname + history.location.search });
        const cart = JSON.parse(localStorage.getItem("guestCart")) || [];
        if (cart?.length > 0) {
          const isBookInCart = cart?.some(
            (item) =>
              item?.book?.sku === bookData?.sku &&
              item?.leatherType === activeLeather.value && 
              item?.variantType === activeVariant.value && 
              item?.color === selectedColor?.colorName
          );

          if (isBookInCart) {
            const updatedCart = cart.map((item) =>
              item?.book?.sku === bookData?.sku &&
              item?.leatherType === activeLeather.value &&
              item?.variantType === activeVariant.value && 
              item?.color === selectedColor?.colorName
                ? { ...item, quantity: item?.quantity + qty }
                : item
            );

            localStorage.setItem("guestCart", JSON.stringify(updatedCart));
            dispatch(CartActions.addCartItemSuccess(updatedCart));
            dispatch(setIsOpen(true));
            return;
          }
        }
        cart.push(cartItem);
        localStorage.setItem("guestCart", JSON.stringify(cart));

        dispatch(CartActions.addCartItemSuccess(JSON.parse(localStorage.getItem("guestCart"))));
        dispatch(setIsOpen(true));
      }
    };

  
    const handleCustomization = () => {
      history.push({
        pathname: `${urls.CUSTOMIZATION}/${bookData?.id}`,
      });
    };
  
    const scrollRef = useRef(null);
  
    useEffect(() => {
      if (scrollRef.current) {
        scrollRef.current.scrollIntoView();
      }
    }, []);
  
    const getBookPrice = () => {
      if (
        activeLeather.value === "realLeather" &&
        activeVariant.value === "deluxe"
      ) {
        return bookData?.deluxePriceGenuine?.toFixed(2);
      } else if (
        activeLeather.value === "realLeather" &&
        activeVariant.value === "superDeluxe"
      ) {
        return bookData?.superDeluxePriceGenuine?.toFixed(2);
      } else if (
        activeLeather.value === "veganLeather" &&
        activeVariant.value === "deluxe"
      ) {
        return bookData?.deluxePriceVegan?.toFixed(2);
      }
      return bookData?.superDeluxePriceVegan?.toFixed(2);
    };
  
    function doesKeyExist(jsonString, keyToCheck) {
      if (jsonString) {
        const imagesObj = JSON.parse(jsonString);
    
        // Convert keys to lowercase and compare
        const lowerCaseKeyToCheck = keyToCheck.toLowerCase();
        return Object.keys(imagesObj).some(
          (key) => key.toLowerCase() === lowerCaseKeyToCheck
        );
      }
      return false;
    }

    const handleWishListAdd = () => {
      if(currentUser?.id){
        addWishlistItem(bookData,1,dispatch)
      }
      else{
        history.push(urls.LOGIN, { from: history.location.pathname + history.location.search });
      }
      
    }

    useEffect(() => {
      getBookReviews(bookData?.id, dispatch);
    }, [bookData,id]);

    const handleRemoveFromWishlist = (itemId) => {
      deleteWishlistItem(itemId,dispatch)
  }
    
  const isWishListItem = () => {
    const bookOpt = wishlist?.find((item) => item?.book?.sku === bookData?.sku && item?.book?.title === bookData?.title)
    if(bookOpt) {
      return {isPresent : true, data: bookOpt}
    } else {
      return {isPresent : false, data:null}
    }
  }

  const handleHeartClick = () => {
    if(!isWishListItem().isPresent) {
        handleWishListAdd()
    } else {
        handleRemoveFromWishlist(isWishListItem().data?.id)
    }
  }

  return (
    <div className={classes.container} ref={scrollRef}>
      <Header />
      {bookData && (
        <>
          {bookData?.metaTitle && (
            <Metatag name="title" content={bookData.metaTitle} />
          )}
          {bookData?.metaDescription && (
            <Metatag name="description" content={bookData.metaDescription} />
          )}
          {bookData?.metaKeywords && (
            <Metatag name="keywords" content={bookData.metaKeywords} />
          )}
        </>
      )}
      <div className={classes.mainContainer}>
        <div className={classes.crumb}>
          <BreadCrumb className={classes.crumb} crumbs={crumbs} />
        </div>

        <div className={classes.productDetails}>
          <div className={classes.imageSection}>
            <div className={classes.mainImage}>
              <img src={currentPhoto} alt={getBookDataAlt()} />
              <div className={classes.arrowLeft} onClick={handleLeftArrowClick}>
                <FontAwesomeIcon
                  icon={faAngleLeft}
                  className={classes.leftIcon}
                />
              </div>
              <div
                className={classes.arrowRight}
                onClick={handleRightArrowClick}
              >
                <FontAwesomeIcon
                  icon={faAngleRight}
                  className={classes.rigthIcon}
                />
              </div>
              <div
                className={classes.wishlistHeart}
                onClick={handleHeartClick}
              >
                <FontAwesomeIcon
                  icon={isWishListItem().isPresent ? faHeartFilled : faHeart}
                  className={classes.heartIcon}
                />
              </div>
            </div>
            <div className={classes.images}>
              {images
                ?.filter((image) => image && image !== "")
                ?.map((image, index) => (
                  <img
                    key={index}
                    src={image}
                    alt={getBookDataAlt()}
                    onClick={() => setCurrentPhoto(image)}
                    className={
                      currentPhoto === image ? classes.activeImage : ""
                    }
                  />
                ))}
            </div>
          </div>
          <div className={classes.details}>
            <h1 title={bookData?.title}>
              {bookData?.title &&
                (bookData.title.length > 100
                  ? `${bookData.title.slice(0, 100)}...`
                  : bookData.title)}
            </h1>
            <div className={classes.authors}>
              <h2>{bookData?.author}</h2>
            </div>

            <h3>${getBookPrice()}</h3>

            <div className={classes.leatherTypes}>
              <div className={classes.leatherHeader}>
                <h2>Variant Types</h2>
                <p
                  style={{ cursor: "pointer" }}
                  onClick={() => setShowVariantType(true)}
                >
                  Know about variant &gt;
                </p>
              </div>
              <div className={classes.leatherTypeBtn}>
                {VariantTabs.map((type, index) => (
                  <div
                    className={`${classes.leatherBtn} ${
                      activeVariant.name === type.name
                        ? classes.activeleatherBtn
                        : ""
                    }`}
                    onClick={() => handleSetActiveVariant(type)}
                    key={index}
                  >
                    <p>{type.name}</p>
                  </div>
                ))}
              </div>
            </div>
            <div className={classes.varientTypes}>
              <div className={classes.varientHeader}>
                <h2>Leather Types</h2>
                <p
                  style={{ cursor: "pointer" }}
                  onClick={() => setShowLeatherType(true)}
                >
                  Know about Leather &gt;
                </p>
              </div>
              <div className={classes.leatherTypeBtn}>
                {LeatherTabs.map((type, index) => (
                  <div
                    className={`${classes.leatherBtn} ${
                      activeLeather.name === type.name
                        ? classes.activeleatherBtn
                        : ""
                    }`}
                    onClick={() => handleSetActiveLeather(type)}
                    key={index}
                  >
                    <p>{type.name}</p>
                  </div>
                ))}
              </div>
            </div>

            <div className={classes.selectColor}>
              <h2>Color : {selectedColor?.colorName}</h2>
              <div className={classes.colors}>
                {allowedColors?.map((color, index) => (
                  <div
                    key={index}
                    className={`${classes.colorPicker} ${
                      selectedColor?.colorName === color.colorName
                        ? classes.active
                        : ""
                    }`}
                    onClick={() => setSelectedColor(color)}
                  >
                    <img
                      src={color.paletteImage}
                      alt={getBookDataAlt()}
                      title={color.colorName}
                    />
                  </div>
                ))}
              </div>
            </div>

            <div className={classes.quantityContainer}>
              <h2>Quantity</h2>
              <div className={classes.quantity}>
                <div className={classes.selectQty}>
                  <div
                    className={classes.qtyButton}
                    onClick={handleQtyDecrease}
                  >
                    <p>-</p>
                  </div>
                  <h2>{qty}</h2>
                  <div
                    className={classes.qtyButton}
                    onClick={handleQtyIncrease}
                  >
                    <p>+</p>
                  </div>
                </div>
                <SecondaryButton
                  className={classes.addToCart}
                  onClick={addToCart}
                >
                  Add to Cart
                </SecondaryButton>
                <div
                  className={classes.customizeBtn}
                  onClick={handleCustomization}
                >
                  <button onClick={handleCustomization}>Customize Book</button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={classes.bindingFeatures}>
          <h2 className={classes.featuresHeading}>About the Binding</h2>
          {isSmallScreen && <p>Slide to view more features <FontAwesomeIcon icon={faArrowRight}/></p> }
          <div className={classes.featureSection}>
            {features.map((data, index) => (
              <div key={index} className={classes.features}>
                <FeatureCard
                  key={index}
                  image={data.image}
                  title={data.title}
                />
              </div>
            ))}
          </div>
        </div>

        <div className={classes.mainBody}>
          <div className={classes.tabs}>
            {tabsData.map((tab, index) => (
              <div
                key={tab}
                className={`${classes.tab} ${
                  activeTab === index ? classes.activeTab : ""
                }`}
                onClick={() => setActiveTab(index)}
              >
                <p>{tab}</p>
              </div>
            ))}
          </div>
          {activeTab === 0 ? (
            <AboutBook
              author={bookData?.author}
              publicationDate={bookData?.publishingYear}
              language={bookData?.language}
              title={bookData?.title}
              desc={bookData?.description}
            />
          ) : (
            <div>
              <Reviews bookData={{ ...bookData, imageUrl: currentPhoto }} />
            </div>
          )}
        </div>

        <div className={classes.recommendation}>
          <Recommendations />
        </div>
      </div>
      <Footer />

      {showLeatherType && (
        <LeatherType onClose={() => setShowLeatherType(false)} />
      )}
      {showVariantType && (
        <VariantType onClose={() => setShowVariantType(false)} />
      )}
    </div>
  );
};

export default ProductDetail;
