import { useState } from "react";

import classes from "./ClosableBox.module.css";
import Card from "./Card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faArrowUp } from "@fortawesome/free-solid-svg-icons";

const ClosableBox = ({
  name,
  children,
  price,
  defaultState = false,
}) => {
  const [boxOpen, setBoxOpen] = useState(defaultState);

  const dropDownHandler = () => {
    setBoxOpen((state) => !state);
  };

  return (
    <Card className={classes.main}>
      <>
        <div className={classes.nameContainer} onClick={dropDownHandler}>
          <div className={classes.name}>{name} {price ? `: $${price}` : ": $0.00"} </div>
          {boxOpen ? (
            <FontAwesomeIcon className={classes.icon} icon={faArrowUp} />
          ) : (
            <FontAwesomeIcon className={classes.icon} icon={faArrowDown} />
          )}
        </div>
        {boxOpen && <div className={classes.contentBox}>{children}</div>}
      </>
    </Card>
  );
};

export default ClosableBox;
