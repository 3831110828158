import React from "react";
import classes from "./ConfirmationModal.module.css";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { urls } from "../../../Routes/Urls";

const emailIcon = "/Assets/Icons/confirmEmailIcon.svg"

const ConfirmationModal = ({ email, onResend, onEditEmail, onClose }) => {
  const history = useHistory()
  return (
    <div className={classes.modal}>
      <div className={classes.modalContent}>
        <div className={classes.iconContainer}>
            <div className={classes.emailIcon}>
            <img
                src={emailIcon}
                alt="Email Icon"
            />
            </div>
        </div>
        <h2 className={classes.title}>Verify Your Email</h2>
        <p className={classes.message}>
          Almost there! We have sent a verification link to your email{" "}
          <strong>{email}</strong>.
          <br />
          You need to verify your email to complete your account set-up.
        </p>
        <div className={classes.actions}>
          {/* <button className={classes.resendButton} onClick={onResend}>
            Resend Email
          </button> */}
          <button className={classes.editButton} onClick={onClose}>
            Edit your email
          </button>
          <button className={classes.editButton} onClick={() => history.push(urls.HOME)}>
            Verify Later
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
