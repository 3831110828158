import {React, useEffect, useState} from "react";
import { Canvas } from "@react-three/fiber";
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader'
import { CameraControls } from "@react-three/drei";
import { TextureLoader, MeshStandardMaterial } from "three";

const textureLoader = new TextureLoader();
const objLoader = new OBJLoader();

function OBJModel(props) {
  const [obj, setObj] = useState();
  const {path, textureData} = props;

  useEffect(() => {
    if (path) {
      objLoader.load(path, setObj);
    }
  }, [path]);

  useEffect(() => {
    if (obj && textureData?.length) {
      let map = new Map();
      for(let texture of textureData) {
        map.set(texture.target, texture);
      }
      
      for(let mesh of obj.children) {
        const {name} = mesh;
        // Create new material for each mesh
        const material = new MeshStandardMaterial({
          roughness: 0.3,    // Makes the material slightly glossy
          metalness: 0.1,    // Adds subtle metallic sheen
          envMapIntensity: 1.2, // Enhances environment reflections
        });

        if (map.has(name)) {
          const tex = map.get(name);
          if (tex.src) {
            const texture = textureLoader.load(tex.src);
            material.map = texture;
          } else if (tex.color) {
            material.color.set(tex.color);
          }
        }
        
        // Apply leather-like properties
        material.bumpScale = 0.2;    // Subtle surface variation
        material.roughnessMap = material.map;  // Use texture for roughness variation
        mesh.material = material;
      }
    }
  }, [obj, textureData]);

  return obj && <primitive {...props} object={obj} />;
}

function Viewer({textureData, bookData}) {
  const getBookModel = () => {
    const bookSize = bookData?.sizeName;
    const bookPages = bookData?.pages;

    if(bookPages >= 0 && bookPages <= 200){
      return `/models/${bookSize}/0-200.obj`
    }
    if(bookPages >= 201 && bookPages <= 400){
      return `/models/${bookSize}/201-400.obj`
    }
    if(bookPages >= 401 && bookPages <= 600){
      return `/models/${bookSize}/401-600.obj`
    }
    if(bookPages >= 601 && bookPages <= 800){
      return `/models/${bookSize}/601-800.obj`
    }
    else{
      return `/models/${bookSize}/800.obj`
    }
  }

  return (
    <Canvas gl={{ preserveDrawingBuffer: true }}>
      {/* Enhanced lighting setup */}
      <ambientLight intensity={0.5} /> {/* Base lighting */}
      
      {/* Main key light */}
      <directionalLight 
        position={[5, 5, 5]} 
        intensity={1.2} 
        color="#ffffff"
      />
      
      {/* Fill light */}
      <directionalLight 
        position={[-5, 3, -5]} 
        intensity={1.2} 
        color="#ffffff"
      />
      
      {/* Top light for leather highlights */}
      <pointLight 
        position={[0, 8, 0]} 
        intensity={0.5} 
        color="#fff6e6"
      />

      {/* Rim light for edge definition */}
      <pointLight position={[0, 2, -8]} intensity={0.4} color="#ffd9b3" />
      
      <OBJModel path={getBookModel()} scale={0.15} textureData={textureData} position={[0, 0.5, 0]} />
      <CameraControls makeDefault minAzimuthAngle={-20 * Math.PI} maxAzimuthAngle={20 * Math.PI} minPolarAngle={-20 * Math.PI} maxPolarAngle={20 * Math.PI} />
    </Canvas>
  );
}

export default Viewer;