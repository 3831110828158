import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';  // Import if you're tracking route changes
import smoothscroll from 'smoothscroll-polyfill';

const useScrollToTop = () => {
  const scrollRef = useRef(null);
  const location = useLocation();  // Track route changes

  useEffect(() => {
    smoothscroll.polyfill();
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: 'smooth' });  // Smooth scroll
    }
  }, [location]); 

  return scrollRef;
};

export default useScrollToTop;
