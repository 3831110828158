import React, { useState, useEffect } from 'react';
import classes from "./Corousel.module.css";

import { addWishlistItem, deleteWishlistItem } from '../../../Store/actions/WishlistAction';
import { useDispatch, useSelector } from 'react-redux';
import { urls } from '../../../Routes/Urls';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart as faRegularHeart } from '@fortawesome/free-regular-svg-icons';
import { faHeart } from '@fortawesome/free-solid-svg-icons';
import Card from "../Card/Card2"
import { getRandomImageForBestSellerBook } from '../../../Util';
import NextArrow,{PrevArrow} from '../../../Components/Arrows/Arrows';
import useScreenSize from '../../../Hooks/UseScreenSize';
const listingDecoration = '/Assets/Images/listingDecoration.svg'

const getNumberOfVisibleItems = () => {
  if (window.innerWidth >= 1200) return 3.5;
  if (window.innerWidth >= 768) return 2;
  return 1;
};

const Carousel = () => {
  const currentUser = useSelector(state => state.user.currentUser)
  const [current, setCurrent] = useState(0);
  const [visibleItems, setVisibleItems] = useState(getNumberOfVisibleItems());
  const dispatch = useDispatch()

  const allColorsData = useSelector((state) => state.bookColor.colors);

  const history = useHistory()

  const featuredCollection = useSelector(state => state.featured.featuredCollection);
  const wishlist = useSelector((state) => state.wishlist.wishlistItems);

  const books = featuredCollection?.find((data) => data.category === "newlyCrafted")?.books || [];

  const isEmpty = (obj) => {
    return Object.keys(obj).length === 0;
  };

  const {isSmallScreen} = useScreenSize()

  useEffect(() => {
    const handleResize = () => {
      setVisibleItems(getNumberOfVisibleItems());
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const isWishListItem = (bookData) => {
    const bookOpt = wishlist?.find((item) => item?.book?.sku === bookData.sku && item?.book?.title === bookData?.title)
    if(bookOpt) {
      return {isPresent : true, data: bookOpt}
    } else {
      return {isPresent : false, data:null}
    }
  }

  useEffect(() => {
    const autoScroll = setInterval(() => {
      if(books.length > 0){
      setCurrent((prevCurrent) => (prevCurrent + 1) % books.length);
      }
    }, 3000);

    return () => clearInterval(autoScroll);
  }, []);

  const nextSlide = () => {
    setCurrent((prevCurrent) => (prevCurrent + 1) % books.length);
  };

  const prevSlide = () => {
    setCurrent((prevCurrent) => (prevCurrent - 1 + books.length) % books.length);
  };

  const handleAddCart = (bookData) => {
    history.push(`${urls.BESTSELLERPDP}/${bookData?.id}`);
  }

  const handleWishlistChange = (e,book) => {
      e.stopPropagation()
      if(isEmpty(currentUser)) {
        history.push(urls.LOGIN)
      }
      else{
        addWishlistItem(book,1,dispatch,true)
      }
  }

  const handleRemove = (e,itemId) => {
    e.stopPropagation()
    deleteWishlistItem(itemId, dispatch);
  };

  return (
    <div className={classes.container} >
      <div className={classes.carousel}>
        <div className={classes.header}>
          <div className={classes.newlyHeader}>
            <h2>Newly crafted books</h2>
            <img src={listingDecoration} alt="" />
          </div>
          {!isSmallScreen && <div className={classes.carouselBtn}>
            <PrevArrow
              className={`${classes.carouselControl} ${classes.prev}`}
              onClick={prevSlide}
            />
            
            <NextArrow
              className={`${classes.carouselControl} ${classes.next}`}
              onClick={nextSlide}
            />
          
          </div>}
        </div>
      </div>
      <div className={classes.mainCarousel}>
      {isSmallScreen && <div className={classes.carouselBtn}>
            <PrevArrow
              className={`${classes.carouselControl} ${classes.prev}`}
              onClick={prevSlide}
            />
            
            <NextArrow
              className={`${classes.carouselControl} ${classes.next}`}
              onClick={nextSlide}
            />
          
          </div>}
        <div
          className={classes.carouselInner}
          style={{
            transform: `translateX(-${current * (100 / visibleItems)}%)`,
          }}
        >
          {books.map((book, index) => (
            <div
              key={index}
              className={`${classes.carouselItem} ${
                current === index ? classes.active : ""
              }`}
            >
              <Card image={book?.imageUrl} onClick={() => handleAddCart(book)} altText={getRandomImageForBestSellerBook(book,allColorsData)?.altText} key={index} price={book?.priceGenuine} bookData={book} isWishListItem={isWishListItem(book).isPresent} wishlistItemId={isWishListItem(book)?.data?.id} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Carousel;
