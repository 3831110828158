import classes from "./Drawer.module.css";
const closeIcon = "/Assets/Icons/icon-x.svg";


const Drawer = ({ name, children, onClose }) => {
  return (
    <div className={classes.backdrop} onClick={onClose}>
      <div className={classes.main} onClick={(e) => e.stopPropagation()}>
        <div className={classes.container}>
          <div className={classes.titleContainer}>
            <div className={classes.title}>{name}</div>
            <img
              src={closeIcon}
              alt='Close'
              className={classes.close}
              onClick={onClose}
            />
          </div>
          {children}
        </div>
      </div>
    </div>
  );
};

export default Drawer;
