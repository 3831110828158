import React, { useState } from "react";
import PropTypes from "prop-types";
import classes from "./ReviewModal.module.css";

import Rating from "@mui/material/Rating";
import Stack from "@mui/material/Stack";
import Modal, { ModalBody, ModalFooter } from "../Modal/Modal";
import ButtonLight from "../Buttons/ButtonLight/ButtonLight";
import SecondaryButton from "../Buttons/SecondaryButton/SecondaryButton";
import { useDispatch, useSelector } from "react-redux";
import { addBookReview } from "../../Store/actions/BookReviewAction";
import { urls } from "../../Routes/Urls";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const ReviewModal = ({ bookData, onClose }) => {
  const currentUser = useSelector((state) => state.user.currentUser);
  const history = useHistory()
  const dispatch = useDispatch();
  const [rating, setRating] = useState(0);
  const [reviewText, setReviewText] = useState("");
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);

  const handleSubmit = () => {
    const bookReviewData = {
      bookId: bookData?.id,
      userId: currentUser?.id,
      comment: reviewText,
      rating: rating,
    };

    if (currentUser && Object.keys(currentUser).length > 0) {
      addBookReview(bookReviewData, dispatch).then(() => {
        setIsConfirmationOpen(true)
      });
    } else {
      history.push(urls.LOGIN);
    }
  };

  const isDisabled = () => {
    if (rating ) {
      return false;
    }
    return true;
  };

  const handleCloseConfirmation = () => {
    setIsConfirmationOpen(false);
    onClose();
  };

  return (
    <>
    <Modal title={"Write a review"} onClose={onClose}>
      <ModalBody>
        <div style={{ padding: "0.5rem", height: "65dvh" }}>
          <div className={classes.reviewComponent}>
            <div className={classes.bookDetails}>
              <div className={classes.bookCover}>
                <img
                  src={bookData?.imageUrl}
                  alt="Book cover"
                  className={classes.bookCover}
                />
              </div>
              <div className={classes.bookName}>
                <h3>{bookData?.title}</h3>
                <p>{bookData?.author}</p>
              </div>
            </div>

            <div className={classes.ratingSection}>
                  <h3>
                    Rate this book <span>(mandatory)</span>
                  </h3>
                  <div className={classes.stars}>
                    <Stack spacing={1}>
                      <Rating
                        name="rating"
                        value={rating}
                        precision={1}
                        onChange={(event, newValue) => {
                          setRating(newValue);
                        }}
                        className={classes.starRating}
                        classes={{
                          iconFilled: classes.iconFilled,
                          iconHover: classes.iconHover,
                          icon: classes.star,
                        }}
                      />
                    </Stack>
                  </div>
                </div>

            <div className={classes.reviewText}>
              <p>Add a written review</p>
              <textarea
                value={reviewText}
                onChange={(e) => {
                  if (e.target.value.length <= 150) {
                    setReviewText(e.target.value); // Only update if character count is <= 150
                  }
                }}
                placeholder="Write your review about the book"
              />
              <p>{reviewText.length}/150 characters</p>
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <div className={classes.actions}>
          <div className={classes.btnClass}>
            <SecondaryButton disabled={isDisabled()} onClick={handleSubmit}>
              Submit
            </SecondaryButton>
          </div>
          <div className={classes.btnClass}>
            <ButtonLight onClick={onClose}>Close</ButtonLight>
          </div>
        </div>
      </ModalFooter>
    </Modal>
    {isConfirmationOpen && (
        <Modal title={"Review Submitted"} onClose={handleCloseConfirmation}>
          <ModalBody>
            <div style={{ textAlign: "left", padding: "2rem" }}>
              <h2>Thank you for your feedback!</h2>
              <p>Your review has been submitted successfully.</p>
            </div>
          </ModalBody>
          <ModalFooter>
            <div className={classes.actions}>
              <div className={classes.btnClass}>
                <ButtonLight onClick={handleCloseConfirmation}>
                  Close
                </ButtonLight>
              </div>
            </div>
          </ModalFooter>
        </Modal>
      )}
    </>
  );
};

export default ReviewModal;
