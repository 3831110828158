import React, { useEffect } from "react";
import classes from "./BestAuthors.module.css";
import Slider from "react-slick";
import { getClasses } from "../../../Util";
import SecondaryButton from "../../../Components/Buttons/SecondaryButton/SecondaryButton";

import {
  fetchAllAuthors,
} from "../../../Store/actions/BestSellingAuthorAction";

import { useDispatch, useSelector } from "react-redux";
import { fetchBook,fetchBookCount } from "../../../Store/actions/BookAction";
import { urls } from "../../../Routes/Urls";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";

function NextArrow(props) {
  const { onClick } = props;
  return (
    <div
      className={classes.leftArrow}
      onClick={onClick}
    >
      <FontAwesomeIcon icon={faAngleRight} />
    </div>
  );
}
function PrevArrow(props) {
  const { onClick } = props;
  return (
    <div
      className={classes.RightArrow}
      onClick={onClick}
    >
      <FontAwesomeIcon icon={faAngleLeft} />
    </div>
  );
}

function BestAuthors() {
  const history = useHistory()
  const currentIndex = 0;
  const topFiveAuthors = useSelector(
    (state) => state.bestSellingAuthors?.authors
  );
  const dispatch = useDispatch();

  useEffect(() => {
    fetchAllAuthors(dispatch);
  }, []);

  const settings = {
    infinite: topFiveAuthors?.length > 3,
    autoplay: topFiveAuthors?.length > 3,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    // centerPadding: "10px",
    slidesToShow: topFiveAuthors?.length > 1 ?  3 : 1,
    speed: 500,
    responsive: [
      {
        breakpoint: 1028,
        settings: {
          slidesToShow: 2.5,
          dots: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const handleRedirect = async (authorName) => {
    const searchResults = await fetchBook("author", authorName, dispatch);
    const searchCount = await fetchBookCount("author", authorName);

    const searchCriteria = "author"
    const query = authorName

    // Redirect to LISTING page with search results as state
    history.push({
        pathname: urls.LISTING,
        state: { 
            searchResults, 
            searchCount,
            searchCriteria,
            query
        }
    });
  }
  
  return (
    <div className={classes.container}>
      <div className={classes.headerSection}>
        <div className={classes.header}>
          <h2>Best Selling Authors</h2>
        </div>
        <div className={classes.description}>
          <p>
            Discover our collection of renowned authors, their words preserved
            in leather-bound elegance for generations to come.
          </p>
        </div>
      </div>

      <div className={classes.carouselContainer}>
        <Slider {...settings}>
          {topFiveAuthors?.map((author, index) => (
            <div key={index} className={classes.cardContainer} >
              <div
                key={author.id}
                className={getClasses(
                  classes.slide,
                  index === currentIndex && classes.centerSlide
                )}
                onClick={() => handleRedirect(author.authorName)}
              >
                <img src={author?.url} alt={author?.altText} />
              </div>
              <div className={classes.authorName} title={author?.authorName}>{author?.authorName}</div>
            </div>
          ))}
        </Slider>

        {/* <BookCarousel /> */}
      </div>

      <div className={classes.buttonContainer}>
        <SecondaryButton onClick={() => history.push(urls.LISTING)}>Explore More Authors</SecondaryButton>
      </div>
    </div>
  );
}

export default BestAuthors;
