import classes from "./AboutCorporateGifts.module.css"

const image1 = "/Assets/Images/FeatureIcons/CorporateGiftF1.png"
const image2 = "/Assets/Images/FeatureIcons/CorporateGiftF2.png"
const image3 = "/Assets/Images/FeatureIcons/CorporateGiftF3.png"
const image4 = "/Assets/Images/FeatureIcons/CorporateGiftF4.png"
const image5 = "/Assets/Images/FeatureIcons/CorporateGiftF5.png"
const image6 = "/Assets/Images/FeatureIcons/CorporateGiftF6.png"
const image7 = "/Assets/Images/FeatureIcons/CorporateGiftF7.png"
const image8 = "/Assets/Images/FeatureIcons/CorporateGiftF8.png"

const cardData = [
    {
        title:"Knowledge and Morale Boost",
        desc:"Well-written books are investments in your staff members' personal and professional development, rather than just small presents.",
        image:image1
    },
    {
        title:"Flexible Ordering",
        desc:"We offer flexible ordering choices to meet your needs, regardless of the size of your employees. We can assist small teams as well as major businesses.",
        image:image2
    },
    {
        title:"High-Value Gift:",
        desc:"When choosing a gift for your team or clients, it’s essential to reflect both meaning and luxury. Leather-bound books are the perfect choice, as they symbolize knowledge while offering a touch of sophistication, elegance, and high value. \n\n This thoughtful gesture will leave a lasting impression and elevate the significance of the occasion. ",
        image:image2
    },
    {
        title:"Personalized to Perfection",
        desc:" Adding personalization to business gifts gives them a special touch. To make the gift even more personalized, add a personal note, the names of the employees, or your company logo to each book.",
        image:image3
    },
    {
        title:"Employee Recognition",
        desc:" Give individualized presents to recognize each employee's accomplishments. Personalized leather-bound volumes with the recipient's name, accomplishment, or a unique message can be used as rewards or tokens of appreciation.",
        image:image4
    },
    {
        title:"Classic Style",
        desc:"Books with leather bindings radiate elegance and sophistication, making them a perfect present to impress partners, clients, and staff. ",
        image:image3
    },
    {
        title:"Enduring Gift",
        desc:" Every leather-bound book is a timeless memento, whether it's a corporate history, a custom notebook, or a famous literary work.",
        image:image5
    },
    {
        title:"Holiday Seasons",
        desc:"Give your staff and customers a unique and luxurious gift that will make a lasting impression. A book with a leather cover is a festive, original, and considerate gift that anybody may appreciate.",
        image:image6
    },
    {
        title:"Events & Milestones for Corporate",
        desc:" A custom book might record the company's history, significant events, or group accomplishments.",
        image:image7
    },
    {
        title:"Client Appreciation",
        desc:"By expressing your value for their business and participation, a thoughtful, well-chosen present can fortify your bonds with important clients and partners.",
        image:image8
    },
]

const AboutCorporateGifts = () => {
    return(
        <div className={classes.mainContainer}>
            <div className={classes.headerSection}>
                <h2>
                Why Choose Premium Leather-Bound Books for Corporate Gifting?
                </h2>
                {/* <p>Our mission is to encapsulate your academic journey in the timeless beauty of leather, ensuring that your thesis remains a testament to your dedication and intellect.</p> */}
            </div>

            <div className={classes.cardSection}>
                {cardData.map((card,index) => (
                    <div key={index} className={classes.card}>
                        <div className={classes.image}>
                            <img src={card.image} alt="" />
                        </div>
                        <div className={classes.title}>
                            <h2>{card.title}</h2>
                            <p>{card.desc}</p>
                        </div>
                    </div>
                ))}
            </div>


        </div>
    )
}

export default AboutCorporateGifts; 