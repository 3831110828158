import { useDispatch, useSelector } from "react-redux"
import BannerHeader from "../../../Components/BannerHeader/BannerHeader"
import CustomerReview from "../../Body/CustomerReview/CutomerReview"
import Gallery from "../../Body/Gallery/Gallery"
import Footer from "../../Footer/Footer"
import PrimaryNav from "../../Header/PrimaryNav/PrimaryNav"
import { ContactUs } from "../Components/ContactUs/ContactUs"
import ServiceVideo from "../Components/ServiceVideo/ServiceVideo"
import FAQ from "../FAQ/FAQ"
import AboutForAuthors from "./AboutForAuthors/AboutForAuthors"
import classes from "./ForAuthors.module.css"
import { useEffect } from "react"
import { getAllFaq } from "../../../Store/actions/FaqAction"
import Timeline from "../Components/Timeline/Timeline"
import { urls } from "../../../Routes/Urls"
import useScrollToTop from "../../../Hooks/useScrollToTop";
const image = "/Assets/Images/CustomizationOverview.svg"
const bgImage = "Assets/Images/ServicePages/ForAuthors.png"

const steps = [
    {
      number: '01',
      title: 'Visibility',
      description:
        'Make your book available on our well-known platform to connect with a wide range of readers and collectors.',
      image: image, // replace with image path if needed
      align: 'left',
    },
    {
      number: '02',
      title: 'Promotion',
      description:
        " Increase the exposure and sales of your book by taking advantage of our focused marketing efforts.",
      image: image, // replace with image path if needed
      align: 'right',
    },
    {
      number: '03',
      title: 'Author Profile',
      description:
        "With a personalized author profile that highlights your accomplishments, works, and future endeavors, you may showcase your journey as an author. We are here to help you every step of the way, regardless of your level of experience as a writer or where you are in the publication process. With the help of our top-notch services, bring your book to life and allow us to assist you in telling your tale to the world.",
      image: image, // replace with image path if needed
      align: 'left',
    },
  ];

const ForAuthors = () => {
    const dispatch = useDispatch()
    const crumbs = [
        { title: "Home", path: ("/") },
        { title: "For Authors", path: urls.FORAUTHOR },
    ]

    const video = useSelector((state) => state.serviceVideo?.videos?.forAuthorvideo)
    const allFaq = useSelector(state => state.Faq.questions);

    useEffect(() => {
        getAllFaq("category","For Authors","",dispatch)
    },[])
    const scrollRef = useScrollToTop();
    return (
      <div ref={scrollRef}>
        <PrimaryNav />
        <BannerHeader
          bgImage={bgImage}
          crumbs={crumbs}
          title={"For Authors"}
        />

        <ServiceVideo
          title={"Writers take note!"}
          videoSrc={video}
          desc={
            "We are aware of the great deal of commitment and work you put into authoring your book. Your dedication to excellence should be reflected in the beauty and prestige with which your work is displayed. \n \n Your writing will become a timeless masterpiece with the perfect aesthetic appeal offered by our premium leather-bound editions."
          }
        />

        <AboutForAuthors />

        <div className={classes.contactUs}>
          <ContactUs title={"Authors, Let’s Create Together!"} />
        </div>

        <div className={classes.join}>
          <h2>Exclusive Listing on Our Site</h2>
        </div>

        <div className={classes.ThesisBindingSteps}>
          <h2 className={classes.title}></h2>
          {/* <p className={classes.subtitle}> the premier destination for bibliophiles and book lovers who appreciate the art of bookmaking. As a rare books store.
                    we specialize in following things:</p> */}
          <Timeline steps={steps} />
        </div>



        <div className={classes.join}>
          <h2>Find Out More and Begin Right Now!</h2>
          <p>
          To find out more about our offerings and partnership and how we can turn your book into a masterpiece, get in touch with us at info@rarebiblio.com.
          <br/>
          Together, let's create something extraordinary.

          </p>
        </div>


        <Gallery />

        <CustomerReview />

        <FAQ data={allFaq} />
       
        <Footer />
      </div>
    );
}

export default ForAuthors