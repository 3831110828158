import classes from "./Endpaper.module.css"
import { useState, useEffect, useContext } from "react";
import { useFabricCanvas, useFabricCanvasHandler, useFabricEditor } from "react-fabric-page";
import SectionController from "../Components/SectionController/SectionController";
import SelectEndpaper from "./SelectEndpaper/SelectEndpaper";
import DedicatedPage from "./DedicatedPage/DedicatedPage";
import useScreenSize from "../../../Hooks/UseScreenSize";
// import OptionCard from "../Components/OptionCard/OptionCard";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { CustomizationContext } from "../Customization";

const options = ["Endpaper", "Dedication Page"]

const Endpaper = ({ onNext, onPrev }) => {
    const {dedicationTextColor,
        dedicationFontFamily,bookLeather, bookVariant,
        dedicationFontStyle,selectedOption,
        dedicationTextAlign, setWantDedicationPage,wantDedicationPage,setDesignJSON,designSaveInitiated,selectedDesigns,dedicationFontSize,steps} = useContext(CustomizationContext);

    const [isCanvasReady,setIsCanvasReady] = useState(false);
    let canvas = useFabricCanvas(); 
    const editor = useFabricEditor(); 

    
    const [currentDropdownId, setCurrentDropdownId] = useState(0);
    const [currentDropdown, setCurrentDropdown] = useState(options[currentDropdownId]);
    const [dropdownOpen, setDropDownOpen] = useState(true);
    const [currentOption, setCurrentOption] = useState("")
    const { isMediumScreen } = useScreenSize()


    useEffect(() => {
        window.epEditor = editor
    },[canvas])

    useEffect(() => {
          const timeout = setTimeout(() => {
          if (canvas) {
              canvas.renderAll();
          }
      }, 300); 
      return () => clearTimeout(timeout);
  },[steps,canvas])

    useEffect(() => {
      if(selectedDesigns?.customization?.dedication?.hasDedication) {
        setWantDedicationPage(true)
      }
    },[selectedDesigns])
    
    useFabricCanvasHandler({
        "canvas:x-ready":() => {
            setIsCanvasReady(true);
        }
      });

    useEffect(() => {
      setIsCanvasReady(false)
    },[bookLeather,bookVariant])
    
      useEffect(() => {
        function applyStylesToTextAreas() {
          if (!canvas) return;
    
          const allObjects = canvas
            ?.getObjects()
            ?.find((obj) => obj.id === "contents");
    
          if (allObjects) {
            allObjects._objects.forEach((obj, index) => {
              if (obj.type === "textArea") {
                if(dedicationTextColor?.colorValue) {
                  obj.set("fill", dedicationTextColor?.colorValue);
                }
                if(dedicationFontFamily?.fontFamily) {
                  obj.set("fontFamily", dedicationFontFamily?.fontFamily)
                }
                if(dedicationFontStyle?.fontStyle){
                  obj.set("fontStyle",dedicationFontStyle?.fontStyle)
                } 
                if(dedicationFontStyle?.underline){
                  obj.set("underline",dedicationFontStyle?.underline)
                }
                if(dedicationTextAlign) {
                  obj.set('textAlign', dedicationTextAlign);
                }
                if(dedicationFontSize >= 6) {
                  obj.set("fontSize",dedicationFontSize)
                }
                obj.dirty = true;
              }
            });
            allObjects.dirty = true;
            canvas.renderAll();
          }
        }
         
        applyStylesToTextAreas()

          canvas?.on('object:added', applyStylesToTextAreas);
      
        // Clean up the event listener when the component unmounts
        return () => {
          canvas?.off('object:added', applyStylesToTextAreas);
        };
      }, [dedicationTextColor, dedicationFontFamily,dedicationFontStyle,dedicationTextAlign,dedicationFontSize,isCanvasReady,canvas]);


      useEffect(() => {
        if(currentDropdown === options[0] || !wantDedicationPage){
            const bgLayer = canvas?.getObjects()?.find((o) => o?.id === "background");
            if(bgLayer){
                const imageObject = bgLayer._objects?.find((o) => o.id === "endpaper");
                if(imageObject){
                  imageObject.visible = true;
                  imageObject.dirty = true
                }
                bgLayer.dirty = true;
            }
            const contentsLayer = canvas?.getObjects()?.find((o) => o?.id === "contents");
            const taggedImageLayer = canvas?.getObjects()?.find((o) => o?.id === "taggedImages");
            if ((contentsLayer && contentsLayer?._objects) || (taggedImageLayer && taggedImageLayer?._objects)) {
                contentsLayer._objects.forEach((object) => {
                    object.visible = false;
                    object.dirty = true;
                });
                contentsLayer.dirty = true;
                taggedImageLayer._objects.forEach((object) => {
                    object.visible = false;
                    object.dirty = true;
                });
                taggedImageLayer.dirty = true;
                }
        }
        else if(currentDropdown === options[1]){
            const bgLayer = canvas?.getObjects()?.find((o) => o?.id === "background");
            if(bgLayer){
                const imageObject = bgLayer._objects?.find((o) => o.id === "endpaper");
                if(imageObject){
                  imageObject.visible = false
                  imageObject.dirty = true
                }
                bgLayer.dirty = true;
                canvas?.renderAll()
            }

            const contentsLayer = canvas?.getObjects()?.find((o) => o?.id === "contents");
            const taggedImageLayer = canvas?.getObjects()?.find((o) => o?.id === "taggedImages");
            if ((contentsLayer && contentsLayer._objects) || (taggedImageLayer && taggedImageLayer?._objects)) {
                contentsLayer._objects.forEach((object) => {
                    object.visible = true;
                    object.dirty = true;
                });
                contentsLayer.dirty = true;
                taggedImageLayer._objects.forEach((object) => {
                    object.visible = true;
                    object.dirty = true;
                });
                taggedImageLayer.dirty = true;
                }
        }

        canvas?.renderAll();
      },[currentDropdown,canvas,isCanvasReady,wantDedicationPage])

    const toggleDropdown = () => {
        setDropDownOpen(!dropdownOpen);
    };

    const handleOptionClick = (id) => {
        if (id === currentDropdownId) {
            setDropDownOpen(!dropdownOpen);
        } else {
            setCurrentDropdownId(id);
            setCurrentDropdown(options[id]);
            setDropDownOpen(true);
        }

    };

    const nextSection = (id) => {
        if (currentDropdownId < 1) {
            setCurrentDropdownId(currentDropdownId + 1);
            setCurrentDropdown(options[id + 1]);
            setCurrentOption(options[id + 1])
            setDropDownOpen(true)
        }
        else {
            onNext()
        }
    }

    const prevSection = (id) => {
        if (currentDropdownId >= 1) {
            setCurrentDropdownId(currentDropdownId - 1);
            setCurrentDropdown(options[id - 1]);
            setCurrentOption(options[id - 1]);
            setDropDownOpen(true)
        }
        else {
            onPrev()
        }
    }

    useEffect(() => {
      if(isMediumScreen) {
        if(selectedOption === options[0]) {
          setCurrentDropdown(options[0])
        } else {
          setCurrentDropdown(options[1])
        }

      }
    },[isMediumScreen])

    useEffect(() => {
      if(designSaveInitiated && editor) {
        const endPaperJSONString = editor?.getJSON();
        const endPaperJSONBlob = new Blob([endPaperJSONString], { type: "application/json" });
        setDesignJSON(prevArray => {
          const updatedArray = [...prevArray];
          updatedArray[3] = endPaperJSONBlob;
          return updatedArray;
        });
      }
    },[designSaveInitiated])


    return (
      <div className={classes.mainContainer}>
        <div
          className={`${
            isMediumScreen && currentOption !== "" && classes.detailsSmall
          } ${classes.details}`}
        >
          {!isMediumScreen && <div className={classes.headerSection}>
              <h2>Let's customize Endpaper of the book (4/5) </h2>
              <p>
                Endpaper are the sheets of paper that are glued to the inside
                covers of the book, connecting the covers to the main text
                block.
              </p>
            </div>
          }

          {!isMediumScreen && (
            <div className={classes.customizationBody}>
              <div onClick={() => handleOptionClick(0)}>
                <SelectEndpaper
                  currentDropdown={currentDropdown}
                  dropdownOpen={dropdownOpen}
                  toggleDropdown={toggleDropdown}
                  onClick={() => handleOptionClick(0)}
                  isCanvasReady={isCanvasReady}
                />
              </div>
              <div onClick={() => handleOptionClick(1)}>
                <DedicatedPage
                  currentDropdown={currentDropdown}
                  dropdownOpen={dropdownOpen}
                  toggleDropdown={toggleDropdown}
                  onClick={() => handleOptionClick(1)}
                />
              </div>
              <div className={classes.sectionController}>
                <SectionController
                  prev={
                    currentDropdownId === 0
                      ? "Paper"
                      : options[currentDropdownId - 1]
                  }
                  next={
                    currentDropdownId === 1
                      ? "Additional Reuqest"
                      : options[currentDropdownId + 1]
                  }
                  onNext={() => nextSection(currentDropdownId)}
                  onPrev={() => prevSection(currentDropdownId)}
                />
              </div>
            </div>
          )}

          {isMediumScreen && (
            <div className={classes.optionSection}>
              <div className={classes.customizationBody}>
                <div
                  className={
                    selectedOption === options[0] ? "" : classes.inVisible
                  }
                  onClick={() => handleOptionClick(0)}
                >
                  <SelectEndpaper isCanvasReady={isCanvasReady} currentDropdown={currentDropdown} dropdownOpen={dropdownOpen} toggleDropdown={toggleDropdown} onClick={() => handleOptionClick(0)} />
                </div>
                <div
                  className={
                    selectedOption === options[1] ? "" : classes.inVisible
                  }
                  onClick={() => handleOptionClick(0)}
                >
                 <DedicatedPage currentDropdown={currentDropdown} dropdownOpen={dropdownOpen} toggleDropdown={toggleDropdown} onClick={() => handleOptionClick(1)} />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
}

export default Endpaper