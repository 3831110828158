import React, { useEffect, useState } from 'react';
import classes from './SearchBarSmall.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faFilter } from '@fortawesome/free-solid-svg-icons';
import { fetchBook,fetchBookCount, getSuggestionsForNormalBooks } from '../../../../Store/actions/BookAction';
import { fetchBook as fetchBestSellerBook } from '../../../../Store/actions/BestSellerBookAction';
import { useDispatch, useSelector } from 'react-redux';
import { urls } from '../../../../Routes/Urls';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { faSearchengin } from '@fortawesome/free-brands-svg-icons';

const SearchBarSmall = () => {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [query,setQuery] = useState("")
  const [searchCriteria,setSearchCriteria] = useState("all");
  const [isBestSellerRecommendation,setIsBestSellerRecommendation] = useState(false);



  const history = useHistory()
  const toggleDropdown = () => {
    setIsDropdownVisible(prevState => !prevState);
  };

  const handleInputChange = (e) => {
    setQuery(e.target.value);
};

  const dispatch = useDispatch();
  const handleSearch = async () => {
    if (query === "") return;

    const searchResults = await fetchBook(searchCriteria, query, dispatch);
    const searchCount = await fetchBookCount(searchCriteria,query);

    // Redirect to LISTING page with search results as state
    history.push({
      pathname: urls.LISTING,
      state: { 
          searchResults, 
          searchCount,
          searchCriteria,
          query
      }
  });
  };

  const handleKeyPress = (e) => {
    if(e.key === "Enter"){
        handleSearch()
    } else {
      const disallowedCharacters = ["(", "{", "[","]","}",")"];
      if (disallowedCharacters.includes(e.key)) {
        e.preventDefault();
      }
    }
} 

const handleInputPaste = (e) => {
  const pastedText = e.clipboardData.getData("text");
  const sanitizedText = pastedText.replace(/[\(\)\{\}\[\]]/g, ""); // Remove disallowed characters
  e.preventDefault(); // Prevent the default paste action
  setQuery(query + sanitizedText); // Append sanitized text to the current query
}; 

  const [allRecommendations,setAllRecommendations] = useState([])

  const [searchRecommendations,setSearchRecommedations] = useState([]);
  const [recommendationClicked,setRecommendationClicked] = useState(false);
  const [focus,setFocus] = useState(false);
  const [blurTimeout, setBlurTimeout] = useState(null);

  const handleRecommendationClick = (recommendation) => {
    const words = query.trim().split(" ");
    if (words.length > 1) {
      const sanitizedText = recommendation?.title?.replace(/[\(\)\{\}\[\]]/g, ""); // Remove disallowed characters
      words[words.length - 1] = sanitizedText; // Replace the last word with sanitized recommendation title
      setQuery(words.join(" "));
    } else {
      const sanitizedText = recommendation?.title?.replace(/[\(\)\{\}\[\]]/g, ""); // Remove disallowed characters
      setQuery(sanitizedText);
    }
    setIsBestSellerRecommendation(recommendation?.isBestSeller);
    setSearchRecommedations([]);
    setRecommendationClicked(true);
  };
  
      useEffect(() => {
        if(recommendationClicked) {
          if(!isBestSellerRecommendation) {
            handleSearch();
          } else {
              history.push({
                pathname: urls.BESTSELLER,
                state: {  
                    query
                }
            });
            setIsBestSellerRecommendation(false)
          }
          setRecommendationClicked(false);
        }
      },[recommendationClicked])
  
  useEffect(() => {
    if (query) {
      const timeout = setTimeout(() => {
        fetchPageData();
      },500);
    
       return () => clearTimeout(timeout);
    }
  }, [query,searchCriteria]);

  useEffect(() => {
      if (query) {
        const words = query.trim().split(/\s+/);
        setSearchRecommedations(
          allRecommendations
        );
      } else {
        setSearchRecommedations([]);
      }
  }, [query, allRecommendations]);

  const handleFocus = () => {
    if (blurTimeout) {
      clearTimeout(blurTimeout); 
    }
    setFocus(true);
  };
  
  const handleBlur = () => {
    const timeout = setTimeout(() => setFocus(false),1000);
    setBlurTimeout(timeout);
  };

  const getFilter = () => {
    let filter = {
      limit: 5,
      offset:0,
      where: {
        $and: [],
      },
    };

    if (query) {
        if(searchCriteria === "all") {
          filter.where.$and.push({
            $or: [
              { author: `*${query}*` },
              { title: `*${query}*` },
              { language: `*${query}*` },
              { sku: `*${query}*` },
              { isbnNo: `*${query}*` },
            ],
          });
        } else {
          filter.where.$and.push({
            $or: [
              { [searchCriteria]: `*${query}*` },
            ],
          });
        }
      }

    return filter;
  };

       const fetchPageData = async () => {
          if (query !== "") {
              const filter = getFilter()
              const dataBestSeller = await fetchBestSellerBook(
                       null,
                       filter
                      );
                      
              const data = await fetchBook(
                searchCriteria,
                query,
                null,
                5,
                1,
                null
              );
  
              const filteredBestSellerRecommendations = dataBestSeller?.data.map((book) => {
                if(searchCriteria !== "sku" && searchCriteria !== "isbnNo"){
                if (book?.author?.toLowerCase().includes(query.toLowerCase())) {
                  return {title : book.author, isBestSeller:true};
                } else if (book?.title?.toLowerCase().includes(query.toLowerCase())) {
                  return {title : book.title, isBestSeller:true};
                }}
                else if (book?.sku === query.toLowerCase()) {
                  return {title : book.sku, isBestSeller:true};
                } else if (book?.isbnNo === query.toLowerCase()) {
                  return {title : book.isbnNo, isBestSeller:true};
                }
            
                return null;
              }).filter((item) => item !== null); // Remove null values
  
              const filteredRecommendations = data.data.map((book) => {
                if(searchCriteria !== "sku" && searchCriteria !== "isbnNo"){
                if (book?.author?.toLowerCase().includes(query.toLowerCase())) {
                  return {title : book.author, isBestSeller:false};
                } else if (book?.title?.toLowerCase().includes(query.toLowerCase())) {
                  return {title : book.title, isBestSeller:false};
                }}
                else if (book?.sku === query.toLowerCase()) {
                  return {title : book.sku, isBestSeller:false};
                } else if (book?.isbnNo === query.toLowerCase()) {
                  return {title : book.isbnNo, isBestSeller:false};
                }
                return null;
              }).filter((item) => item !== null); // Remove null values
  
              const allFilteredRecommendations = filteredBestSellerRecommendations?.concat(filteredRecommendations)
  
              const uniqueRecommendations = Array.from(
                new Map(allFilteredRecommendations.map((item) => [item.title, item])).values()
              );

              setAllRecommendations(uniqueRecommendations);
      
          }
        };


  return (
    <div className="container">
      <div className={classes.mainContainer}>
        <div className={classes.searchIcon}>
          <FontAwesomeIcon icon={faMagnifyingGlass} onClick={handleSearch}/>
          <input 
            type="text" 
            placeholder="Search Books" 
            value={query} 
            onChange={handleInputChange} 
            onKeyDown={(e) => handleKeyPress(e)} 
            onFocus={handleFocus}
            onBlur={handleBlur}
            onPaste={handleInputPaste}
          />
        </div>
        <div className={classes.filter} onClick={toggleDropdown}>
          <FontAwesomeIcon icon={faFilter} />
          {isDropdownVisible && (
            <div className={classes.dropdownli}>
              <ul>
                  <li className={searchCriteria === "all" ? classes.activeCriteria : ""} value="all" onClick={() => setSearchCriteria("all")}>All</li>
                  <li className={searchCriteria === "title" ? classes.activeCriteria : ""} value="title" onClick={() => setSearchCriteria("title")}>Book Title</li>
                  <li className={searchCriteria === "author" ? classes.activeCriteria : ""} value="author" onClick={() => setSearchCriteria("author")}>Author</li>
                  <li className={searchCriteria === "isbnNo" ? classes.activeCriteria : ""} value="isbnNo" onClick={() => setSearchCriteria("isbnNo")}>ISBN Number</li>
                  <li className={searchCriteria === "sku" ? classes.activeCriteria : ""} value="sku" onClick={() => setSearchCriteria("sku")}>SKU</li>
              </ul>
            </div>
          )}
        </div>

      </div>
      {/* Dropdown with Recommendations */}
      {searchRecommendations.length > 0 && focus && (
        <div className={classes.dropdown}>
        {searchRecommendations.slice(0,10).map((recommendation, index) => (
          <div
            key={index}
            className={classes.dropdownItem}
            onClick={() => handleRecommendationClick(recommendation)}
          >
            <FontAwesomeIcon icon={faSearchengin}/>
            {recommendation?.title}
          </div>
        ))}
        </div>
      )}
    </div>
  );
};

export default SearchBarSmall;
