import React, { useEffect, useRef, useState } from "react";
import classes from "./MiniCart.module.css";
import ButtonLight from "../../../Components/Buttons/ButtonLight/ButtonLight";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faCartShopping } from "@fortawesome/free-solid-svg-icons";
import { getRandomImageForBestSellerBook, getRandomImageForNormalBook } from "../../../Util";
import { urls } from "../../../Routes/Urls";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { deleteCartItem, updateCartItem } from "../../../Store/actions/CartAction";
import { toggleIsOpen } from "../../../Store/Slices/ToggleSlice";
import { faCircleXmark } from "@fortawesome/free-regular-svg-icons";
import useClickOutSide from "../../../Hooks/useClickOutSide";
import { CartActions } from "../../../Store/Slices/CartSlice";
const MiniCart = () => {
    const history = useHistory()
    const cartItems = useSelector((state) => state.cart.cartItems)
    const allColorsData = useSelector(state => state.bookColor.colors);
    const currentUser = useSelector((state) => state.user.currentUser)
    const dispatch = useDispatch()
    const ref = useRef(null);
    const url = window.location.pathname
    const [toshow,setToshow] = useState(true)

    useEffect(()=>{
      if(url.includes("cart") || url.includes("customization")){
        setToshow(false);
      }
      else{
        setToshow(true);
      }
    },[])
    
    const subtotal = cartItems?.reduce(
      (acc, item) =>
        acc + ((item?.book?.isDeleted || item?.bestSellerBook?.isDeleted) 
          ? 0 
          : (item?.itemPrice + (item?.customizationCharges || 0)) * item.quantity),
      0
    );
    
    // const [isOpen,setIsOpen] = useState(false);
    const isOpen = useSelector((state) => state.toggle.isOpen);

    useClickOutSide({myRef:ref,isActive:isOpen,onClick:() => dispatch(toggleIsOpen())})

    const getBookImage = (cartItem) => {

        if(cartItem?.book){
            return getRandomImageForNormalBook(cartItem?.book,allColorsData,cartItem?.color,cartItem?.leatherType,cartItem?.variantType)?.image
        }
        else if(cartItem?.bestSellerBook){
            return getRandomImageForBestSellerBook(cartItem?.bestSellerBook,allColorsData,cartItem?.color,cartItem?.leatherType,cartItem?.variantType)?.image
        }
        return ""
    } 

    const handleQtyIncrement = (cartItemId, qty) => {
      if (currentUser?.id) {
        updateCartItem(cartItemId, qty + 1, null, dispatch);
      } else {
        const guestCart = JSON.parse(localStorage.getItem("guestCart")) || [];
        const updatedCart = guestCart.map((item) =>
          item.id === cartItemId
            ? { ...item, quantity: item.quantity + 1 } // Increase quantity for the matching item
            : item
        );
        localStorage.setItem("guestCart", JSON.stringify(updatedCart));
        dispatch(CartActions.addCartItemSuccess(JSON.parse(localStorage.getItem("guestCart"))))
      }
    };
    
    const handleQtyDecrement = (cartItemId, qty) => {
      if (currentUser?.id) {
        if (qty > 1) {
          updateCartItem(cartItemId, qty - 1, null, dispatch);
        }
      } else {
        const guestCart = JSON.parse(localStorage.getItem("guestCart")) || [];
        const updatedCart = guestCart.map((item) =>
          item.id === cartItemId && item.quantity > 1
            ? { ...item, quantity: item.quantity - 1 }
            : item
        );
        localStorage.setItem("guestCart", JSON.stringify(updatedCart));
        dispatch(CartActions.addCartItemSuccess(JSON.parse(localStorage.getItem("guestCart"))))
      }
    };
    

    const handleDeleteItem = (cartItemId) => {
      if (currentUser?.id) {
        deleteCartItem(cartItemId, dispatch);
      } else {
        // User is not logged in, remove the item from local storage
        const guestCart = JSON.parse(localStorage.getItem("guestCart")) || [];
        const updatedCart = guestCart.filter((item) => item.id !== cartItemId); // Use a unique identifier
        localStorage.setItem("guestCart", JSON.stringify(updatedCart));
        dispatch( CartActions.removeCartItem(cartItemId))
      }
    };

    
    const handleRedirect = (item) =>{
        item?.bestSellerBook ? 
            history.push({
              pathname: `${urls.BESTSELLERPDP}/${item.bestSellerBook.id}`,
          }) : 
            history.push({
              pathname: `${urls.PRODUCTDETAIL}/${item.book.id}`,
          });
    }
    

    const handleCartRedirect =() =>{
        history.push(urls.CART);
        handleToggle();
    }

    const handleToggle = () => {
        dispatch(toggleIsOpen())
    }
  return (
    <>
      <div className={`${classes.container} ${isOpen ? classes.open : ""}`} ref={ref} >
        <div className={classes.headerSection}>
          <div className={classes.headerContent}>
            <div className={classes.heading}>
                <h1>Cart</h1>
                <div className={classes.mainCross} onClick={handleToggle}>
                    <FontAwesomeIcon icon={faCircleXmark}/>
                </div>
            </div>
            <div className={classes.subtotal}>
                <p>Subtotal</p>
                <span>$ {subtotal?.toFixed(2) || 0}</span>
            </div>
            <div className={classes.cartCount}>{cartItems?.length} items added here</div>
          </div>
          <ButtonLight className={classes.cartBtn} onClick={handleCartRedirect}>Go to Cart</ButtonLight>
          <ButtonLight className={classes.cartBtn} onClick={handleToggle}>Continue Shopping</ButtonLight>

        </div>
        <div className={classes.cartItemsList}>

        {cartItems?.length > 0 && cartItems?.map((item,idx) => {
          return <div className={classes.cartItemCard} key={idx}>
            <div className={classes.bookImage} onClick={() => handleRedirect(item)}>
              <img src={getBookImage(item)} alt="bookImage" />
            </div>
            <div className={classes.bookDetails}>
              <div className={classes.header}>
                <div className={classes.title} onClick={!item?.book?.isDeleted || !item?.bestSellerBook?.isDeleted ? () => handleRedirect(item) : () => {}}>{item?.book? item.book.title : item.bestSellerBook.title}</div>
                <div className={classes.cross} onClick={() => handleDeleteItem(item.id)}>
                <FontAwesomeIcon icon={faCircleXmark} className={classes.xMark}/>
                </div>
              </div>
              <div className={classes.itemDetails}>
                <p>Edition: {item?.variantType} | {item.color}</p>
              </div>
              <div className={classes.selectQty}>
                <div className={classes.qtyButton} onClick={() => handleQtyDecrement(item.id,item.quantity)}><p>-</p></div>
                <div>{item.quantity}</div>
                <div className={classes.qtyButton} onClick={() => handleQtyIncrement(item.id,item.quantity)}><p>+</p></div>
              </div>
              <div className={classes.price}>$ {item.totalAmount?.toFixed(2) || item.itemPrice?.toFixed(2)}</div>
            </div>
          </div>
        })}

        {cartItems?.length === 0 && <div className={classes.cartItemCard}>
          Cart is empty
        </div>}
        </div>
      </div>

        {!isOpen && toshow && cartItems && cartItems.length !== 0 && 
            <div className={classes.drawerIcon} onClick={handleToggle} >
                <FontAwesomeIcon icon={faCartShopping} style={{color:`var(--app-primary)`}}/>
            </div>
        }
    </>
  );
};

export default MiniCart;
