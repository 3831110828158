import BannerHeader from "../../Components/BannerHeader/BannerHeader";
import PrimaryNav from "../Header/PrimaryNav/PrimaryNav";
import classes from "./Services.module.css";
import CustomizationSteps from "./CustomizationSteps/CustomizationSteps";
import WhyUs from "./WhyUs/WhyUs";
import BookCustomization from "../Body/BookCustomization/BookCustomization";
import Gallery from "../Body/Gallery/Gallery";
import CustomerReview from "../Body/CustomerReview/CutomerReview";
import FAQ from "./FAQ/FAQ";
import { ContactUs } from "./Components/ContactUs/ContactUs";
import Footer from "../Footer/Footer";

import { useEffect } from "react";
import ServiceVideo from "./Components/ServiceVideo/ServiceVideo";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllVideos } from "../../Store/actions/ServiceVideoAction";
import useScrollToTop from "../../Hooks/useScrollToTop";
import { getAllFaq } from "../../Store/actions/FaqAction";

const banner = "/Assets/Images/SericeOverlay.svg";
const Services = () => {
  const crumbs = [
    { title: "Home", path: "/" },
    { title: "Book Customization", path: "/services" },
  ];

  const video = useSelector((state) => state.serviceVideo.videos?.servicesVideo);
  const allFaq = useSelector((state) => state.Faq.questions);

  const dispatch = useDispatch();

  useEffect(() => {
    fetchAllVideos(dispatch);
  }, []);

  useEffect(() => {
    getAllFaq("category", "Customization", "", dispatch);
  }, []);


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const scrollRef = useScrollToTop();

  return (
    <div className={classes.mainContainer} ref={scrollRef}>
      <PrimaryNav />
      <BannerHeader
        title={`Your Imagination, Your Book, Your Customization`}
        crumbs={crumbs}
        desc={
          "Craft Your Perfect Book: Customizable Front Covers, Spines, Endpapers, and Gilding"
        }
        bgImage={banner}
      />

      <div className={classes.customisedPersonalised}>
        {video && <ServiceVideo
          title={"Customized and Personalized Books Of Your Style"}
          desc={
            "Rare Biblio understands that books are no less than any emotion, they are not just a text printed on paper rather they speak for you, and they represent you and your individuality. That’s why we have come up with this amazing offering for you where you can deep dive into book customization and design and frame your books and stories in a way you love and create something unique for yourself and your loved ones"
          }
          videoSrc={video}
        />}
        {/* <CustomisedBooks /> */}
      </div>
      <div className={classes.customizationSteps}>
        <CustomizationSteps />
      </div>
      <div className={classes.whyUs}>
        <WhyUs />
      </div>
      <div className={classes.bookCustomization}>
        <BookCustomization />
      </div>
      <div className={classes.gallery}>
        <Gallery />
      </div>
      <div className={classes.customerReview}>
        <CustomerReview />
      </div>

      <div className={classes.faqSection}>
        <FAQ data={allFaq}/>
      </div>
      <div className={classes.contactUs}>
        <ContactUs />
      </div>

      <div className={classes.footerSection}>
        <Footer />
      </div>
    </div>
  );
};

export default Services;
