import classes from "./CustomizationSteps.module.css"

const book = "/Assets/Images/book2.svg"
const book2 = "/Assets/Images/bookStep3.svg"
const book3 = "/Assets/Images/bookStep4.svg"

const stepData = [
    {
        steps:"01",
        image:book,
        title:"Choose Your Brilliant Variant",
        desc:"Start your customization journey by choosing the variant that uniquely defines you.Choose from the Deluxe or Super Deluxe variant. Both the variants and unique in their way but the Super deluxe variant gives you a sense of royalty and luxury with its exclusive vivid features."
    },
    {
        steps:"02",
        image:book,
        title:"Customized front cover, like no other!",
        desc:"Vibrant leather colors are waiting for you to embed them in your designed book. Not just this, but also select outer and inner borders from a mesmerizing array of options. Also, place your own custom text and logo design on the front cover and make it magically unique."
    },
    {
        steps:"03",
        image:book2,
        title:"Customize Spine, Make it Shine!",
        desc:"Make your book stand out by adding customized text a design a quote or a special date on the spine of your book. Personalized text will add a touch of significance to your literary treasure."
    },
    {
        steps:"04",
        image:book3,
        title:"Personalized and Gold-Edged with Pride!",
        desc:"Radiant mesmerizing golden gilded papers are going to make your book speak volumes. Leave no chance behind adding these shimmering gold-edged papers to your exclusively customized book that will leave people in awe."
    },
    {
        steps:"05",
        image:book,
        title:"Endpapers Customized, Elegance Realized!",
        desc:"Even the smallest details make the greatest impact and therefore, endpapers are something that cannot be missed at all. Select from various options of endpapers that would perfectly match your book’s theme and style."
    },
]

const CustomizationSteps = () => {
    return(
        <div className={classes.mainContainer}>
            <div className={classes.header}>
                <h2>How to start with customization?</h2>
                <p>It's time to design your custom books and unleash your creativity with colorful leather options, dazzling gilding, custom endpapers, and many more.</p>
        </div>

        <div className={classes.stepsSection}>
            {stepData.map((steps,index) => (
                <div key={index} className={classes.stepsCard}>
                    <div className={classes.stepDetails}>
                        <h3>STEP {steps.steps}</h3>
                        <h2>{steps.title}</h2>   
                        <p>{steps.desc}</p>                   
                    </div>
                    <div className={classes.stepImage}>
                        <img src={steps.image} alt="" />
                    </div>
                </div>
            ))}
        </div>

        
        </div>
    )
}

export default CustomizationSteps