import React, { useState } from "react";
import classes from "./FooterItems.module.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { urls } from "../../../Routes/Urls";

export default function FooterItems({ title, items }) {
    const history = useHistory()
    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleRedirect = (item) => {
        if (item === "About Us") {
            history.push(urls.OURCOMPANY)
        }
        if (item === "FAQs") {
            history.push(urls.FAQ)
        }
        if (item === "Blogs") {
            history.push(urls.BLOG)
        }
        if (item === "Best Seller") {
            history.push(urls.BESTSELLER)
        }
        if (item === "Customization") {
            history.push(urls.SERVICES)
        }
        if (item === "Email: info@rarebiblio.com") {
            window.location.href = `mailto:info@rarebiblio.com`;
        }
        if (item === "Connect with Us") {
            history.push(urls.CONTACTUS)
        }
        if (item === "Privacy Policy") {
            history.push(urls.PRIVACYPOLICY)
        }
        if (item === "Limited Edition") {
            history.push(urls.LIMITEDEDITION)
        }
        if (item === "Return/Refunds") {
            history.push(urls.RETURNREFUNDS)
        }
    }

    return (
        <div className={classes.container}>
            <div className={classes.title} onClick={toggleDropdown}>
                <h3>{title}</h3>
                <FontAwesomeIcon 
                    icon={isOpen ? faChevronUp : faChevronDown} 
                    className={classes.dropdownIcon} 
                />
            </div>
            <div className={`${classes.itemContainer} ${isOpen ? classes.show : ''}`}>
                {items.map((item, index) => (
                    <p key={item} onClick={() => handleRedirect(item)} style={{cursor:"pointer"}}>{item}</p>
                ))}
            </div>
        </div>
    );
}
