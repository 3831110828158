import React from "react";
import InfoNav from "./InfoNav/InfoNav";
import PrimaryNav from "./PrimaryNav/PrimaryNav";
import MiniCart from "../MyCart/MiniCart/MiniCart";
import { useSelector } from "react-redux";

function Header() {
  const isOpen = useSelector((state) => state.toggle.isOpen);
  return (
    <>
      <InfoNav />
      <PrimaryNav />
      {!isOpen && <MiniCart />}
      {/* {!isSmallScreen && <ItemsNav />} */}
    </>
  );
}

export default Header;
