import classes from "./ServiceVideo.module.css"
import VideoPlayer from "../../../../Components/VideoPlayer/VideoPlayer";


const ServiceVideo = ({title,desc,videoSrc}) => {
    return (
      <div className={classes.mainContainer}>
        <div className={classes.header}>
          <h2>{title}</h2>
          <p>
            {desc.split("\n").map((line, index) => (
              <span key={index}>
                {line}
                <br />
              </span>
            ))}
          </p>
        </div>

        <div className={classes.videoContainer}>
          {videoSrc && <VideoPlayer videoSrc={videoSrc} />}
        </div>
      </div>
    );
}

export default ServiceVideo;